import React from 'react';
import { useEffect, useState } from 'react';

import styled from 'styled-components/macro';
import { mobileBreakpoint } from 'utils/constants';

const ProgressBar = ({ progress, max }: { progress: number; max: number }) => {
  const [width, setWidth] = useState(0);

  const getPercentage = (p: number, m: number) => (p / m) * 100;

  const progressPorcentage = getPercentage(progress, max);

  useEffect(() => {
    setWidth(progressPorcentage);
  }, [progressPorcentage]);

  return (
    <Container>
      <ProgressPorcentage>
        {progressPorcentage > 0 ? progressPorcentage.toFixed(0) + '% fullført' : 0 + '% fullført'}
      </ProgressPorcentage>
      <ProgressBarContainer>
        <ProgressBarFill style={{ width: `${width}%` }} />
      </ProgressBarContainer>
    </Container>
  );
};

export default ProgressBar;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: var(--din-regular);
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 20px;
  background-color: #ddd;
  border-radius: 1rem;

  @media (max-width: ${mobileBreakpoint}) {
    height: 10px;
  }
`;

const ProgressBarFill = styled.div`
  height: 100%;
  background-color: var(--grønn-kontrast);
  transition-delay: 3s;
  transition: width 0.5s ease-in-out;
  border-radius: 1rem;
`;

const ProgressPorcentage = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 0.2rem;
  white-space: nowrap;

  @media (max-width: ${mobileBreakpoint}) {
    font-size: 0.8rem;
  }
`;
