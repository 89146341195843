import React, { ReactNode } from 'react';

import { addMyHistoryMapping } from 'myPage/myHistory/utils/myHistoryApi';
import { useLoggedInEmployee } from 'stateAndApi/employeeApi';
import styled, { css } from 'styled-components/macro';

import { trackEventToMixpanel } from '../../../stateAndApi/mixpanelApi';
import { MixpanelEvent } from '../../../types/mixpanelTypes';
import { InteractionType } from '../../../types/myHistoryTypes';
import { Post } from '../../../types/postTypes';
import { RoutePaths } from '../../../utils/urls';
import { InternalLink } from '../../InternalLink';

import { CardTitleSC } from './CardTitle';

export const LinkStyling = css`
  width: 100%;

  color: inherit;
  text-decoration: none;
  word-break: break-word;
  user-select: text;

  &:hover ${CardTitleSC} {
    text-decoration: underline;
  }
`;

const StyledInternalLink = styled(InternalLink)`
  ${LinkStyling};
`;

const StyledExternalLink = styled.a`
  ${LinkStyling};
`;

interface Props {
  post: Post;
  className?: string;
  children: ReactNode;
  isSpeedRead: boolean;
}

/**
 * Links to external page if post.mediaType is not VIDEO or PODCAST
 * */
export const CardContentLinkWrapper = ({ post, className, children, isSpeedRead }: Props) => {
  const loggedInEmployee = useLoggedInEmployee();

  const trackRedirectToExternalPost = () => {
    trackEventToMixpanel(MixpanelEvent.REDIRECT_TO_EXTERNAL_POST_FROM_FEED, loggedInEmployee);
    addMyHistoryMapping({
      contributors: [],
      interaction_type: InteractionType.VISITED,
      post_id: post.id,
      user_id: loggedInEmployee.id,
    });
  };

  const trackSpeedRead = () => {
    trackEventToMixpanel(MixpanelEvent.READ_SPEEDREAD, loggedInEmployee);
  };

  if (post.mediaType === 'video' || post.mediaType === 'podcast' || post.mediaType === 'file') {
    return (
      <StyledInternalLink to={`${RoutePaths.POST}/${post.id}`} className={className}>
        {children}
      </StyledInternalLink>
    );
  }

  return (
    <StyledExternalLink
      onClick={() => {
        trackRedirectToExternalPost();
        if (isSpeedRead) {
          trackSpeedRead();
        }
      }}
      href={post.url}
      target="_blank"
      rel="noopener noreferrer"
      draggable={false}
      className={className}
    >
      {children}
    </StyledExternalLink>
  );
};
