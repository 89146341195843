import React, { Dispatch, FormEvent, KeyboardEvent, SetStateAction } from 'react';

import Button from 'shared/button/Button';
import InputText from 'shared/input/InputText';
import { useNotification } from 'shared/notification/useNotification';
import styled from 'styled-components/macro';
import { MediaType } from 'types/mediaTypes';
import { ScreenedUrlData } from 'types/urlScreeningTypes';
import { getErrorMessage } from 'utils/utils';

import { getScreenedUrl } from '../../stateAndApi/urlScreeningApi';
import { CreatePost } from '../../types/postTypes';

type Props = {
  setValue: Dispatch<SetStateAction<CreatePost>>;
  state: CreatePost;
  isSubmitting: boolean;
  setSubmitting: (b: boolean) => void;
  setUrlScreened: (b: boolean) => void;
  urlScreened: boolean;
  setOriginalImage: (s: string) => void;
};

export const UrlScreening = ({
  setValue,
  state,
  isSubmitting,
  setSubmitting,
  setUrlScreened,
  urlScreened,
  setOriginalImage,
}: Props) => {
  const { notifyError, clearNotification, notifyWarning } = useNotification();

  const setFormValues = (screenedUrlData: ScreenedUrlData) => {
    const newPostState = {
      ...state,
      thumbnailUrl: screenedUrlData.thumbnailUrl,
      title: screenedUrlData.title,
      description: screenedUrlData.description,
      mediaType: screenedUrlData.mediaType,
      published: screenedUrlData.published ?? new Date(),
      url: screenedUrlData.url,
      consumptionTime: screenedUrlData.consumptionTime ?? state.consumptionTime,
    };
    setValue(newPostState);
  };

  const setUrl = (url: string) => {
    const newPostState = {
      ...state,
      url: url,
    };
    setValue(newPostState);
  };

  const giveWarningIfWeCouldNotFetchMetadata = (hasMetadataFromHtml: boolean, mediaType: MediaType) => {
    if (mediaType === 'video' && !hasMetadataFromHtml) {
      notifyWarning('Oisann!', errorMessageCouldNotFetchMetadataForVideo);
      return;
    }

    if (!hasMetadataFromHtml) {
      notifyWarning('Oisann!', errorMessageCouldNotFetchMetadata);
    }
  };

  const screenUrl = async (event: FormEvent) => {
    event.preventDefault();
    clearNotification();
    setSubmitting(true);
    try {
      const screenedUrlResponse = await getScreenedUrl(state.url);
      if (screenedUrlResponse.isDuplicate) {
        notifyWarning('Obs!', 'Dette må være et bra innlegg 🎉 Det ser ut som en annen bekker allerede har delt det');
        setUrlScreened(false);
        setSubmitting(false);
      } else {
        const { urlScreeningDataShape } = screenedUrlResponse;
        giveWarningIfWeCouldNotFetchMetadata(screenedUrlResponse.hasMetadataFromHtml, urlScreeningDataShape.mediaType);
        setSubmitting(false);
        setFormValues(urlScreeningDataShape);
        setUrlScreened(true);
        setOriginalImage(urlScreeningDataShape.thumbnailUrl);
      }
    } catch (e) {
      notifyError('Noe gikk galt', getErrorMessage(e));
      setUrlScreened(false);
      setSubmitting(false);
    }
  };

  const onEnter = (e: KeyboardEvent<HTMLDivElement>) => {
    if ((state.url.length >= 0 && e.code === 'Enter') || e.code === 'NumpadEnter') {
      screenUrl(e);
    }
  };

  return (
    <Container onKeyDown={onEnter}>
      <InputText label={urlScreened ? 'URL (obligatorisk)' : 'URL'} value={state.url} onChange={(url) => setUrl(url)} />
      <FetchButton type="submit" onClick={screenUrl} disabled={isSubmitting}>
        {urlScreened ? 'Hent info på nytt 🚀' : 'Hent info 🚀'}
      </FetchButton>
    </Container>
  );
};

const errorMessageCouldNotFetchMetadata: string =
  'Vi greide ikke å automagisk hente informasjon fra nettsiden, vi setter pris på om du fyller ut informasjonen selv 🤩';

const errorMessageCouldNotFetchMetadataForVideo: string =
  'Vi greide ikke å automagisk hente informasjon fra videoen, vi setter pris på om du fyller ut informasjonen selv 🤩';

const FetchButton = styled(Button)`
  align-self: center;
  align-self: flex-end;
  white-space: nowrap;
  padding: 8px 1rem;
`;

const Container = styled.div`
  display: flex;
  gap: var(--2);
`;
