import React, { ReactElement, ReactNode } from 'react';

import styled from 'styled-components/macro';

import { Divider } from './Divider';

type Props = {
  topComponent?: ReactElement<any>;
  numberOfPosts: number;
  children: ReactNode;
};

export const GenericPostsContainer = ({ topComponent, numberOfPosts, children }: Props) => {
  return (
    <PostsAndTopContainer>
      {numberOfPosts !== 0 && (
        <>
          {topComponent && (
            <>
              {topComponent}
              <Divider />
            </>
          )}
          <PostsContainer>{children}</PostsContainer>
        </>
      )}
      <Divider />
    </PostsAndTopContainer>
  );
};

const PostsAndTopContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const PostsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--3);
  width: 100%;
`;
