import { AccountInfo } from '@azure/msal-browser';
import { getScopes } from 'utils/config';

import { msalInstance } from '../index';

interface CustomIdTokenClaims {
  employeeId?: string | number;
  [key: string]: unknown;
}

interface CustomAccountInfo extends AccountInfo {
  idTokenClaims?: CustomIdTokenClaims;
}

export async function getAccessToken(): Promise<undefined | string> {
  try {
    const authRequest = { scopes: getScopes() };
    const tokenResponse = await msalInstance?.acquireTokenSilent(authRequest);
    return tokenResponse?.accessToken;
  } catch (error) {
    return undefined;
  }
}

async function getClaimsFromToken(): Promise<number | null> {
  const authRequest = { scopes: getScopes() };
  const tokenResponse = await msalInstance.acquireTokenSilent(authRequest);

  if (!tokenResponse?.account) return null;

  const account = tokenResponse.account as CustomAccountInfo;
  const employeeId = account.idTokenClaims?.employeeId;

  if (typeof employeeId === 'string') {
    return parseInt(employeeId, 10);
  } else if (typeof employeeId === 'number') {
    return employeeId;
  }
  return null;
}

export async function getEmployeeId(): Promise<number | null> {
  return getClaimsFromToken();
}

export async function getEmployeeIdEvenWithExpiredToken(): Promise<number | null> {
  return getClaimsFromToken();
}
