import React, { RefObject, useRef } from 'react';

import { useTooltipTrigger } from '@react-aria/tooltip';
import { TooltipTriggerState, useTooltipTriggerState } from '@react-stately/tooltip';
import { TooltipTriggerProps } from '@react-types/tooltip';
import styled from 'styled-components/macro';

import Icon from '../../../../shared/Icon';
import { Tooltip } from '../../../../shared/Tooltip';
import { ButtonWithHover } from '../../../../shared/button/ButtonWithHover';
import { tabletBreakpoint } from '../../../../utils/constants';

const Container = styled.span`
  position: relative;
`;

const Button = styled(ButtonWithHover)`
  width: var(--6);
  height: var(--6);

  @media (max-width: ${tabletBreakpoint}) {
    width: var(--5);
    height: var(--5);
  }
`;

const AddIcon = styled(Icon).attrs({ name: 'plus' })`
  width: 100%;
  height: 100%;
  stroke: var(--sort);
  transition: stroke 0.1s;

  &:hover {
    stroke: var(--sort);
  }
`;

interface Props {
  onClick?: () => void;
  alt?: string;
  tooltip: string;
}

export const ScrollButton = ({ onClick, tooltip }: Props) => {
  const props: TooltipTriggerProps = {
    isDisabled: false,
    delay: 400,
  };
  const state: TooltipTriggerState = useTooltipTriggerState(props);
  const ref: RefObject<HTMLButtonElement> = useRef<HTMLButtonElement>(null);
  const { triggerProps, tooltipProps } = useTooltipTrigger(props, state, ref);

  return (
    <Container>
      <Button
        hoverColor={'var(--primary)'}
        backgroundColor={'var(--hvit)'}
        onClick={onClick}
        buttonRef={ref}
        tooltipTriggerProps={triggerProps}
      >
        <AddIcon />
      </Button>
      {state.isOpen && (
        <Tooltip state={state} props={tooltipProps} buttonWidth={48}>
          {tooltip}
        </Tooltip>
      )}
    </Container>
  );
};
