import React from 'react';

import { LineClamp } from 'shared/LineClamp';
import styled from 'styled-components/macro';
import { tabletBreakpoint } from 'utils/constants';

import { Employee } from '../../types/employeeTypes';
import { EmployeeImage } from '../EmployeeImage';

export const ContributorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const EmployeeName = styled.span`
  margin-left: var(--1-05);
  ${LineClamp(1)};

  @media (max-width: ${tabletBreakpoint}) {
    font-size: var(--12px-rem);
  }
`;

interface ContributorProps {
  employee: Employee;
  textAddon?: string;
  className?: string;
}

export const Contributor = ({ employee, textAddon, className }: ContributorProps) => (
  <ContributorContainer className={className}>
    <EmployeeImage employee={employee} />
    <EmployeeName>
      {employee.name}
      {textAddon}
    </EmployeeName>
  </ContributorContainer>
);
