import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useRecoilValue } from 'recoil';
import { useNotification } from 'shared/notification/useNotification';
import { getErrorMessage } from 'utils/utils';

import { employeeRecordState } from '../stateAndApi/employeeState';
import { CollectionBackend } from '../types/collectionTypes';

import DetailCollection from './detailCollection/DetailCollection';
import { getCollection, removePostFromCollection } from './utils/collectionApi';

import { EditCollection } from './EditCollection';

type Props = { editing: boolean };

export const Collection = ({ editing }: Props) => {
  const { notifyError, notifyInfo } = useNotification();

  const params = useParams<{ collectionId: string }>();
  const collectionId = parseInt(params.collectionId as string);
  const employeeRecord = useRecoilValue(employeeRecordState);
  const [collection, setCollection] = useState<CollectionBackend>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const deletePostFromCollection = async (postToBeDeletedId: number) => {
    if (!collection) return;

    try {
      await removePostFromCollection(collectionId, postToBeDeletedId);
      setCollection({
        metadata: {
          author_id: collection.metadata.author_id,
          description: collection.metadata.description,
          id: collection.metadata.id,
          thumbnail_url: collection.metadata.thumbnail_url,
          title: collection.metadata.title,
          number_of_posts: collection.metadata.number_of_posts,
        },
        posts: collection.posts.filter((post) => post.post.id !== postToBeDeletedId),
      });
      notifyInfo('Info', 'Innlegget fjernet fra samlingen');
    } catch (e) {
      notifyError('Error', getErrorMessage(e));
    }
  };

  useEffect(() => {
    getCollection(collectionId).then((collection) => {
      setCollection(collection);
      setIsLoading(false);
    });
  }, [collectionId, employeeRecord]);

  if (isLoading || !collection) return null;

  return editing ? (
    <EditCollection
      collectionMetadata={collection.metadata}
      setCollection={(collectionMetadata) => setCollection({ ...collection, metadata: collectionMetadata })}
    />
  ) : (
    <DetailCollection
      collection={collection}
      deletePostFromCollection={deletePostFromCollection}
      setCollection={setCollection}
    />
  );
};
