import React, { useState } from 'react';

import styled from 'styled-components/macro';

import fallback from './fallback.svg';

interface Props {
  className?: string;
  src?: string;
  alt: string;
  onError?: () => void;
}

export const Image = styled.img`
  display: block;
  width: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
  border: 1px solid;
`;

export const ImageComponent = ({ className, src, alt, onError }: Props) => {
  const [thumbnail, setThumbnail] = useState(src);
  return (
    <Image
      className={className}
      loading="lazy"
      src={thumbnail}
      alt={alt}
      onError={() => {
        if (onError) {
          onError();
        } else {
          setThumbnail(fallback);
        }
      }}
    />
  );
};
