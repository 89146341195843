import React from 'react';

import { EmployeeImage } from 'shared/EmployeeImage';
import { useLoggedInEmployee } from 'stateAndApi/employeeApi';
import { useGoToRoute } from 'stateAndApi/history';
import styled from 'styled-components/macro';
import { RoutePaths } from 'utils/urls';

import Icon from '../../shared/Icon';

const sizeButton = 40;

const Image = styled(EmployeeImage)`
  width: 100%;
  height: 100%;
  border-radius: 0;
`;

const SmileIcon = styled(Icon).attrs({ name: 'emoji-smile' })`
  width: 100%;
  height: 100%;
  position: absolute;
  transition: top 0.1s;
  left: 0px;
  top: 100%;
  background: var(--contrast);
`;

const Button = styled.button`
  border: 1px solid var(--sort);
  width: ${sizeButton}px;
  height: ${sizeButton}px;
  background: inherit;
  justify-content: center;
  padding: 0;
  position: relative;
  overflow: hidden;
  :hover ${SmileIcon} {
    top: 0;
    cursor: pointer;
  }
`;

export const MyPageButton = () => {
  const goToRoute = useGoToRoute();
  const employee = useLoggedInEmployee();

  return (
    <Button title="Min side" onClick={() => goToRoute(RoutePaths.MY_PAGE_READING_LIST)}>
      <Image employee={employee} />
      <SmileIcon />
    </Button>
  );
};
