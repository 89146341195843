import React, { ReactNode } from 'react';

import styled from 'styled-components/macro';

import { Divider } from './Divider';

type Props = {
  children: ReactNode;
};
/**
 * A metadata section for a detail page that displays child components in a full-width container.
 * Each child component will be separated by a `<Divider />` component.
 *
 * @param children The child components to display in the metadata section.
 * @returns The metadata section component.
 */
export const GenericMetaData = ({ children }: Props) => {
  return (
    <FullWidthDiv>
      {React.Children.map(children, (child, index) => (
        <>
          {index !== 0 && <Divider />}
          {child}
        </>
      ))}
    </FullWidthDiv>
  );
};

const FullWidthDiv = styled.div`
  width: 100%;
`;
