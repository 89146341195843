import React, { useEffect } from 'react';

import _ from 'lodash';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components/macro';

import { getPostsByTag, mapToFrontendPosts } from '../../../post/utils/postApi';
import Button from '../../../shared/button/Button';
import { employeeRecordState } from '../../../stateAndApi/employeeState';
import { useSetFilters } from '../../../stateAndApi/searchStates';
import { EmployeeRecord } from '../../../types/employeeTypes';
import { Post, PostBackend } from '../../../types/postTypes';

import { BlockContainer } from './BlockContainer';
import { BlockFeed } from './BlockFeed';

const SeeMoreButton = styled(Button)`
  align-self: center;
`;

const LIMIT = 5;

interface Props {
  tag: string;
}

const postState = atom<Post[]>({
  key: 'TagBlock/posts',
  default: [],
});

/** Rendered only if more than 3 posts are tagged with your department. */
export const TagBlock = ({ tag }: Props) => {
  const setFilters = useSetFilters();
  const [posts, setPosts] = useRecoilState(postState);
  const employeeRecord = useRecoilValue<EmployeeRecord>(employeeRecordState);

  useEffect(() => {
    getPostsByTag(tag, LIMIT).then((posts: PostBackend[]) => {
      setPosts(mapToFrontendPosts(posts, employeeRecord));
    });
  }, [employeeRecord, tag, setPosts]);
  const backgroundColor = 'var(--previous-primary)';

  if (posts.length < 3) return null;

  const onClick = () => {
    window.scrollTo(0, 0);
    setFilters({ action: 'ActivateFilter', filter: { key: 'Tag', value: tag } });
  };

  const randomPostSelection = _.sampleSize(posts, 6);

  return (
    <BlockContainer title={tag} background={backgroundColor}>
      <BlockFeed mainPost={randomPostSelection[0]} posts={randomPostSelection.slice(1, LIMIT)} />
      <SeeMoreButton onClick={onClick}>{`Se mer fra ${tag}`}</SeeMoreButton>
    </BlockContainer>
  );
};
