import React, { ReactNode, useContext } from 'react';

import { TabContext } from 'landing/Landing';
import Icon, { IconName } from 'shared/Icon';
import styled from 'styled-components/macro';
import { contentWidth, mobileBreakpoint, tabletBreakpoint } from 'utils/constants';

import { Tab as TabType } from '../Landing';

export const Tabs = () => {
  const tabs: TabType[] = [TabType.Feed, TabType.Kompetanseplan];
  const { activeTab, setActiveTab } = useContext(TabContext);

  const mapToIcon = (tabName: string): IconName => {
    switch (tabName) {
      case 'Feed':
        return 'coffee';
      case 'Kompetanseplan':
        return 'lightbulb';
      default:
        return 'help';
    }
  };

  // Tror det trengs en global state som sier hvilken fane vi er i
  // Venter med dette til vi jobber med søket.
  function setTab(s: string): void {
    switch (s.toLowerCase()) {
      case 'feed':
        setActiveTab(TabType.Feed);
        break;
      case 'kompetanseplan':
        setActiveTab(TabType.Kompetanseplan);
        break;

      default:
        throw new Error(`Unknown tab ${s.toLowerCase()}`);
    }
  }

  return (
    <Container>
      <InnerContainer>
        {tabs.map((tabText) => (
          <Tab onClick={setTab} title={tabText} key={tabText}>
            <OptionContainer>
              <StyledIcon name={mapToIcon(tabText)} />
              <Option
                alt={tabText}
                key={tabText}
                isActive={activeTab === tabText}
                borderColor={activeTab === TabType.Feed ? 'var(--primary)' : 'var(--previous-primary)'}
              >
                {tabText}
              </Option>
            </OptionContainer>
          </Tab>
        ))}
      </InnerContainer>
    </Container>
  );
};

interface TabProps {
  children: ReactNode;
  title: string;
  onClick: (s: string) => void;
}

const Tab = ({ children, title, onClick }: TabProps) => {
  return (
    <StyledDiv title={title} onClick={() => onClick(title)}>
      {children}
    </StyledDiv>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: var(--primary);
  width: 100%;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: ${contentWidth};
`;

const StyledDiv = styled.div<{ title: string }>`
  display: flex;
  background-color: ${({ title }) =>
    title === TabType.Feed ? 'var(--lighter-primary)' : 'var(--previous-ligther-primary)'};
  align-self: flex-end;
  padding: 0.7rem 1rem 0.7rem 0.5rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-right: 5px;
  height: 60px;

  :hover {
    cursor: pointer;
  }

  @media (max-width: ${mobileBreakpoint}) {
    padding: 0.7rem 1rem;
  }
`;
const OptionContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;
interface OptionProps extends React.HTMLProps<HTMLButtonElement> {
  isActive: boolean;
  borderColor: string;
}

const Option = styled.span<OptionProps>`
  background: inherit;
  color: var(--sort);
  font-family: var(--din-light);
  font-size: 1.2rem;
  font-weight: ${({ isActive }) => (isActive ? 600 : 100)};
  padding-top: 0.25rem;

  border-bottom: ${({ isActive, borderColor }) =>
    isActive ? 'var(--05) solid ' + borderColor : 'var(--1px) solid var(--sort)'};

  @media (max-width: ${tabletBreakpoint}) {
    margin-left: var(--2px);
    padding: 0;
  }
`;

const StyledIcon = styled(Icon)`
  width: 2.5rem;
  height: 2.5rem;

  @media (max-width: ${mobileBreakpoint}) {
    display: none;
  }
`;
