import React from 'react';

import styled from 'styled-components/macro';

const Span = styled.span`
  margin: 0 var(--1);
  color: var(--morgendis);
`;

export const Separator = () => {
  return <Span>·</Span>;
};
