import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import {
  addPostToCompetencePlan,
  getCompetencePlan,
  getCompetencePlanPersonal,
  removeCompetencePostFromCompetencePlan,
  signUpToCompetencePlan,
  subscribeToNudge,
  unsubscribeToNudge,
} from 'competencePlan/utils/competenceApi';
import SlackReminderToggle from 'createNewPost/SlackReminderToggle';
import { Divider } from 'genericDetail/Divider';
import { GenericDetailHeader } from 'genericDetail/GenericDetailHeader';
import { GenericDetailPage } from 'genericDetail/GenericDetailPage';
import { GenericMetaData } from 'genericDetail/GenericMetaData';
import { GenericPostsContainer } from 'genericDetail/GenericPostsContainer';
import { GenericTopTexts } from 'genericDetail/GenericTopTexts';
import { ScrollButton } from 'post/detailPages/content/postHeaderButtons/ScrollButton';
import { mapFromMetadataToPost } from 'post/utils/postUtils';
import { useRecoilValue } from 'recoil';
import { EditButton } from 'shared/button/EditButton';
import { PostCardKompetanseplan } from 'shared/card/PostCard';
import { ContributorWithDepartment } from 'shared/contributor/ContributorWithExtraText';
import { useNotification } from 'shared/notification/useNotification';
import ProgressBar from 'shared/progressbar/Progressbar';
import { useLoggedInEmployee, useSingleEmployee } from 'stateAndApi/employeeApi';
import { employeeRecordState } from 'stateAndApi/employeeState';
import { useGoToRoute } from 'stateAndApi/history';
import styled from 'styled-components/macro';
import { CompetencePlanBackend, CompetencePlanPersonalBackend, CompetencePost } from 'types/competencePlanTypes';
import { Employee } from 'types/employeeTypes';
import { Post } from 'types/postTypes';
import { mobileBreakpointValue } from 'utils/constants';
import { RoutePaths } from 'utils/urls';
import { useWindowSize } from 'utils/useWindowSize';
import { Warning, getErrorMessage } from 'utils/utils';

import AddPostsToSelections from '../../shared/addPostsToSelections/AddPostsToSelections';

export const DetailCompetencePlan = ({
  competencePlan,
  setCompetencePlan,
}: {
  competencePlan: CompetencePlanBackend;
  setCompetencePlan: (k: CompetencePlanBackend) => void;
}) => {
  const employee = useSingleEmployee(competencePlan.metadata.author_id);
  const employeeRecord = useRecoilValue(employeeRecordState);
  const loggedInEmployee = useLoggedInEmployee();
  const [progress, setProgress] = useState(0);
  const { notifyInfo, notifyWarning } = useNotification();
  const addToCompetencePlanRef = useRef<HTMLDivElement>(null);
  const [personal, setPersonal] = useState<CompetencePlanPersonalBackend>();
  const [shouldUpdatePersonal, setShouldUpdatePersonal] = useState(false);

  const isAuthor = loggedInEmployee.id === competencePlan.metadata.author_id;

  useEffect(() => {
    getCompetencePlanPersonal(competencePlan.metadata.id!.toString()).then((personal) => setPersonal(personal));
  }, [competencePlan, shouldUpdatePersonal]);

  useEffect(() => {
    if (!personal) return;
    setProgress(personal.checked_posts.length);
  }, [personal]);

  const handleAddPostToCompetencePlan = async (post: Post) => {
    try {
      await addPostToCompetencePlan(competencePlan.metadata.id!, post.id);
      const updatedCompetencePlan = await getCompetencePlan(competencePlan.metadata.id!.toString());
      setCompetencePlan(updatedCompetencePlan);
      notifyInfo('Hurra!', 'Innlegget ble lagt til i kompetanseplanen');
    } catch (error) {
      notifyWarning('OBS', getErrorMessage(error));
    }
  };

  return (
    <GenericDetailPage
      Helmet={
        <Helmet>
          <title>Atlas - Kompetanseplan</title>
        </Helmet>
      }
    >
      <CompetenceDetailHeader
        addToCompetencePlanRef={addToCompetencePlanRef}
        competencePlanId={competencePlan.metadata.id!}
        isAuthor={isAuthor}
      />
      <CompetenceDetailTopText
        title={competencePlan.metadata.title}
        description={competencePlan.metadata.description}
      />
      {employee && personal && (
        <CompetenceDetailMetadata
          max={competencePlan.posts.length}
          progress={progress}
          employee={employee}
          competencePlanId={competencePlan.metadata.id!}
          signedUp={personal.signed_up}
          setSignedUp={setPersonal}
          receiveNudge={personal.receive_nudge}
        />
      )}
      {personal && (
        <CompetenceDetailPostsList
          setCompetencePlan={setCompetencePlan}
          competencePlan={competencePlan}
          competencePlanPersonal={personal}
          setCompetencePlanPersonal={setPersonal}
          setShouldUpdatePersonal={setShouldUpdatePersonal}
        />
      )}
      {isAuthor && (
        <AddPostsToSelections
          handleAddPost={handleAddPostToCompetencePlan}
          existingPosts={competencePlan.posts.map((competencePost) =>
            mapFromMetadataToPost(competencePost.post, employeeRecord)
          )}
          type={'kompetanseplan'}
          ref={addToCompetencePlanRef}
        />
      )}
    </GenericDetailPage>
  );
};

const CompetenceDetailHeader = ({
  competencePlanId,
  isAuthor,
  addToCompetencePlanRef,
}: {
  competencePlanId: number;
  isAuthor: boolean;
  addToCompetencePlanRef: React.RefObject<HTMLDivElement>;
}) => {
  const goToRoute = useGoToRoute();

  return (
    <GenericDetailHeader fallbackRoute={RoutePaths.MY_PAGE_COMPETENCE_PLAN}>
      {isAuthor && (
        <>
          <ScrollButton
            tooltip="Legg til innlegg"
            onClick={() => addToCompetencePlanRef.current?.scrollIntoView({ behavior: 'smooth' })}
          />
          <EditButton
            tooltip="Rediger kompetanseplan"
            onClick={() => goToRoute(`${RoutePaths.COMPETENCE_PLAN}/${competencePlanId}/rediger`)}
          />
        </>
      )}
    </GenericDetailHeader>
  );
};
const CompetenceDetailTopText = ({ title, description }: { title: string; description: string }) => {
  return <GenericTopTexts typeOfDetailPage={'Kompetanseplan'} title={title} description={description} />;
};

const CompetenceDetailMetadata = ({
  employee,
  max,
  progress,
  competencePlanId,
  signedUp,
  setSignedUp,
  receiveNudge,
}: {
  employee: Employee;
  max: number;
  progress: number;
  competencePlanId: number;
  signedUp: boolean;
  setSignedUp: React.Dispatch<React.SetStateAction<CompetencePlanPersonalBackend | undefined>>;
  receiveNudge: boolean;
}) => {
  const [slackReminder, setSlackReminder] = useState<boolean>(receiveNudge);
  const { notifyError, notifyInfo } = useNotification();

  const sendNudgeSubscription = (newToggleVal: boolean) => {
    if (!newToggleVal) {
      unsubscribeToNudge(competencePlanId);
    } else {
      subscribeToNudge(competencePlanId);
    }
  };

  const onClickStartButton = async () => {
    if (signedUp) {
      notifyInfo('Info', 'Det er foreløpig ikke mulig å stoppe en kompetanseplan (MVP 🤪)');
      return;
    }
    try {
      if (!signedUp) {
        await signUpToCompetencePlan(competencePlanId);
        setSignedUp((personal) => (personal ? { ...personal, signed_up: true } : undefined));
      }
    } catch (e) {
      if (e instanceof Warning) {
        notifyError('Oisann', e.message);
      } else {
        notifyError('Error', e instanceof Error ? e.message : 'Klarte ikke å starte kompetanseplanen');
      }
    }
  };

  return (
    <GenericMetaData>
      <ContributorWithDepartment employee={employee} />
      <div>
        <SignUpContainer>
          <SignUpButton
            onClick={onClickStartButton}
            title={signedUp ? 'Stopp kompetanseplan' : 'Start kompetanseplan'}
            type="button"
            active={signedUp}
          >
            {signedUp ? 'Startet' : 'Start'}
          </SignUpButton>
          <SlackReminderToggle
            isCompetencePlanActive={signedUp}
            slackReminder={slackReminder}
            setSlackReminder={setSlackReminder}
            onClick={(newVal) => sendNudgeSubscription(newVal)}
          />
        </SignUpContainer>
        <ProgressBar progress={progress} max={max} />
      </div>
    </GenericMetaData>
  );
};

const SignUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 2rem;
`;

const SignUpButton = styled.button<{ active: boolean }>`
  width: fit-content;
  padding: 0.3rem 2rem;
  border-radius: 2rem;
  font-family: var(--din-regular);
  font-size: 1rem;
  border: none;
  background: ${({ active }) => (active ? 'var(--grønn-kontrast)' : 'var(--hvit)')};
  box-shadow: ${({ active }) => active && '0 1px 2px 0 var(--overskyet-kontrast)'};
  border: ${({ active }) => (active ? '1px solid transparent' : '1px solid var(--grønn-kontrast)')};
  cursor: pointer;

  &:hover {
    background: ${({ active }) => !active && 'var(--grønn-kontrast)'};
    color: var(--sort);
  }
`;

const CompetenceDetailPostsList = ({
  competencePlan,
  competencePlanPersonal,
  setCompetencePlan,
  setCompetencePlanPersonal,
  setShouldUpdatePersonal,
}: {
  competencePlan: CompetencePlanBackend;
  competencePlanPersonal: CompetencePlanPersonalBackend;
  setCompetencePlan: (k: CompetencePlanBackend) => void;
  setCompetencePlanPersonal: (k: CompetencePlanPersonalBackend) => void;
  setShouldUpdatePersonal: (k: boolean) => void;
}) => {
  const [vertical] = useWindowSize();
  const { notifyError, notifyInfo } = useNotification();
  const employeeRecord = useRecoilValue(employeeRecordState);

  const removeCompetencePost = async (postToBeRemoved: number) => {
    try {
      await removeCompetencePostFromCompetencePlan(competencePlan.metadata.id!, postToBeRemoved);
      setCompetencePlan({
        ...competencePlan,
        posts: competencePlan.posts.filter((competencePost) => competencePost.post.id !== postToBeRemoved),
      });
      notifyInfo('Info', 'Innlegget fjernet fra kompetanseplanen');
    } catch (error) {
      notifyError('Error', getErrorMessage(error));
    }
  };

  const sortPosts = (posts: CompetencePost[]): CompetencePost[] => {
    const copyPosts = [...posts];
    return copyPosts.sort((a, b) => {
      const aAdded = !!a.added_date ? a.added_date : '';
      const bAdded = !!b.added_date ? b.added_date : '';
      return aAdded.localeCompare(bAdded);
    });
  };

  return (
    <>
      <Divider />
      <GenericPostsContainer numberOfPosts={competencePlan.posts.length}>
        {competencePlan.posts &&
          sortPosts(competencePlan.posts).map((competencePost) => (
            <PostCardKompetanseplan
              competencePlanProps={{
                competencePlanPersonal: competencePlanPersonal,
                setCompetencePlanPersonal: setCompetencePlanPersonal,
                competencePlanId: competencePlan.metadata.id!,
                removePostFromCompetencePlan: removeCompetencePost,
                setShouldUpdatePersonal: setShouldUpdatePersonal,
              }}
              key={competencePost.post.id}
              post={mapFromMetadataToPost(competencePost.post, employeeRecord)}
              vertical={vertical < mobileBreakpointValue}
            />
          ))}
      </GenericPostsContainer>
    </>
  );
};
