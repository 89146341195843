import { mapToEmployee } from 'post/utils/postApi';
import { FilterType } from 'stateAndApi/searchStates';
import { EmployeeRecord } from 'types/employeeTypes';
import { norwegianFilterMediaTypes } from 'types/mediaTypes';

export function getFilterLabel(filter: FilterType, employees: EmployeeRecord) {
  switch (filter.key) {
    case 'External':
      return filter.value === 'external' ? 'Delt av bekkere' : 'Produsert av bekkere';
    case 'Employee':
      return mapToEmployee(parseInt(filter.value), employees)?.name;
    case 'Media':
      return norwegianFilterMediaTypes[filter.value];
    case 'Tag':
      return filter.value;
  }
}
