import React from 'react';

import styled from 'styled-components/macro';
import { blockContentWidth, tabletBreakpoint } from 'utils/constants';

const Container = styled.div<{ background: string; isVisible?: boolean }>`
  width: 100%;
  background-color: ${(props) => props.background};
  margin-top: var(--4);
  margin-bottom: var(--8);

  @media (max-width: ${tabletBreakpoint}) {
    margin-bottom: var(--10);
    margin-top: 0;
  }
`;

export const SubContainer = styled.div`
  padding: var(--8) var(--4) var(--10) var(--4);
  margin: 0 auto;
  max-width: ${blockContentWidth};
  display: flex;
  flex-direction: column;

  @media (max-width: ${tabletBreakpoint}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: var(--5) var(--2);
  }
`;

const Title = styled.h2<{ color?: string }>`
  text-align: left;
  font-size: var(--36px-rem);

  @media (max-width: ${tabletBreakpoint}) {
    font-size: var(--24px-rem);
    font-family: var(--din-medium);
    margin-top: 0;
  }
`;

interface Props {
  title?: string;
  background?: string;
  className?: string;
  // eslint-disable-next-line no-undef
  children?: JSX.Element[];
}

export const BlockContainer = ({ background = 'var(--regn-kontrast)', title, className, children }: Props) => (
  <Container background={background} className={className}>
    <SubContainer>
      {title && <Title>{title}</Title>}
      {children}
    </SubContainer>
  </Container>
);
