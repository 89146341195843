import React from 'react';

import Icon from 'shared/Icon';
import styled from 'styled-components/macro';
import { mobileBreakpoint } from 'utils/constants';

interface SlackReminderToggleProps {
  isCompetencePlanActive: boolean;
  slackReminder: boolean;
  setSlackReminder: (shareToSlack: boolean) => void;
  onClick: (shareToSlack: boolean) => void;
}

const SlackReminderToggle = ({
  isCompetencePlanActive,
  slackReminder,
  setSlackReminder,
  onClick,
}: SlackReminderToggleProps) => {
  return (
    <Container active={isCompetencePlanActive}>
      <InnerContainer>
        <Header>Vil du motta påminnelse på Slack?</Header>
        <StyledIcon name="slack_icon" />
      </InnerContainer>
      <InnerContainer>
        <Text tabIndex={1}>Seff, send meg påminnelse én gang i uka</Text>
        <ToggleContainer
          tabIndex={1}
          active={isCompetencePlanActive && slackReminder}
          onClick={() => {
            setSlackReminder(!slackReminder);
            onClick(!slackReminder);
          }}
        >
          <Circle active={slackReminder} />
          <OnOffText active={slackReminder}>{slackReminder ? 'På' : 'Av'}</OnOffText>
        </ToggleContainer>
      </InnerContainer>
    </Container>
  );
};

export default SlackReminderToggle;

const Container = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  opacity: ${({ active }) => (active ? '1' : '0.3')};
`;

const InnerContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  width: 1.5rem;
  height: 1.5rem;
  margin-bottom: 0.5rem;
`;

const Text = styled.span`
  font-size: 0.8rem;

  @media (min-width: ${mobileBreakpoint}) {
    font-size: 1rem;
  }
`;

const Header = styled.span`
  font-weight: bold;
`;

const ToggleContainer = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  width: 5rem;
  max-width: 4rem;
  height: 1.5rem;
  background-color: ${({ active }) => (active ? 'var(--grønn-kontrast)' : 'var(--overskyet-kontrast)')};
  border-radius: 100vmax;
  padding: 0.3rem 0.1rem 0.3rem 0.2rem;
  margin-bottom: 2px;
  gap: 1rem;
`;

const Circle = styled.div<{ active: boolean }>`
  order: ${({ active }) => (active ? '1' : '0')};
  align-items: center;
  width: 1.1rem;
  height: 1.1rem;
  background: white;
  border-radius: 100vmax;
  border: none;

  &:hover {
    cursor: ${({ active }) => active && 'pointer'};
  }
`;

const OnOffText = styled.div<{ active: boolean }>`
  align-self: flex-start;
  font-size: 0.7rem;
  order: ${({ active }) => (active ? '0' : '1')};
  margin-left: ${({ active }) => (active ? '0.5rem' : '0')};
`;
