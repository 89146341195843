import React from 'react';

import styled from 'styled-components/macro';

import { Employee } from '../../types/employeeTypes';
import { Post } from '../../types/postTypes';

import { ContributorImages } from './ContributorImages';
import { ContributorNames } from './ContributorNames';

const Container = styled.div`
  display: flex;
  align-items: center;
  min-width: 0; // This allows container to shrink, forcing ellipsis on text in child ContributorNames
`;

interface Props {
  post: Post;
  /** The number of contributors to show. Smallest valid value: 2. */
  maxNumberOfContributorsToShow?: number;
}

export const Contributors = ({ post, maxNumberOfContributorsToShow = 2 }: Props) => {
  if (maxNumberOfContributorsToShow < 2) {
    throw new Error(
      'This component displays at least two contributors. The <Contributor />-component could be used to show a single contributor.'
    );
  }

  const contributors: Employee[] = selectContributorToShow(post);
  if (!contributors.length) {
    return null;
  }

  return (
    <Container>
      <ContributorImages employees={post.contributorEmployees} maxNumberOfBubbles={maxNumberOfContributorsToShow} />
      <ContributorNames
        employees={contributors}
        maxNumberOfNames={maxNumberOfContributorsToShow}
        descriptionText={getCorrectAddonText(post)}
      />
    </Container>
  );
};

const selectContributorToShow = (post: Post): Employee[] => {
  if (post.contributorEmployees.length > 0) {
    return post.contributorEmployees;
  } else if (post.isExternal && post.publisherEmployee) {
    return [post.publisherEmployee];
  }
  return [];
};

const getCorrectAddonText = (post: Post): string | undefined => {
  if (post.contributorEmployees.length > 0) {
    return undefined;
  } else if (post.isExternal && post.publisherEmployee) {
    if (isSlackThread(post.url)) {
      return 'Tråd startet av';
    }
    return 'Delt av';
  }
};

const isSlackThread = (url: string): boolean => {
  return url.includes('bekk.slack');
};
