import React, { RefObject, useRef } from 'react';

import { useTooltipTrigger } from '@react-aria/tooltip';
import { TooltipTriggerState, useTooltipTriggerState } from '@react-stately/tooltip';
import { TooltipTriggerProps } from '@react-types/tooltip';
import { Tooltip } from 'shared/Tooltip';
import { ButtonWithHover } from 'shared/button/ButtonWithHover';
import styled from 'styled-components/macro';
import { mobileBreakpoint, tabletBreakpoint } from 'utils/constants';

import Icon from '../Icon';

const Container = styled.span`
  position: relative;
`;

const Button = styled(ButtonWithHover)`
  width: var(--6);
  height: var(--6);

  @media (max-width: ${tabletBreakpoint}) {
    width: var(--5);
    height: var(--5);
  }
`;

const EditIcon = styled(Icon).attrs({ name: 'edit' })`
  width: 100%;
  height: 100%;
  stroke: var(--hvit);
  transition: stroke 0.1s;

  &:hover {
    stroke: var(--sort);
  }
`;

interface EditProps {
  onClick?: () => void;
  alt?: string;
  tooltip?: string;
}

export const EditButton = ({ onClick, tooltip = 'Rediger' }: EditProps) => {
  const props: TooltipTriggerProps = {
    isDisabled: false,
    delay: 400,
  };
  const state: TooltipTriggerState = useTooltipTriggerState(props);
  const ref: RefObject<HTMLButtonElement> = useRef<HTMLButtonElement>(null);
  const { triggerProps, tooltipProps } = useTooltipTrigger(props, state, ref);

  return (
    <Container>
      <Button
        hoverColor="var(--primary)"
        backgroundColor="var(--sort)"
        onClick={onClick}
        buttonRef={ref}
        tooltipTriggerProps={triggerProps}
      >
        <EditIcon />
      </Button>
      {state.isOpen && (
        <Tooltip state={state} props={tooltipProps} buttonWidth={48}>
          {tooltip}
        </Tooltip>
      )}
    </Container>
  );
};

export const EditButtonWithBlackHoverNoBorder = ({ onClick, tooltip = 'Rediger' }: EditProps) => {
  const props: TooltipTriggerProps = {
    isDisabled: false,
    delay: 400,
  };
  const state: TooltipTriggerState = useTooltipTriggerState(props);
  const ref: RefObject<HTMLButtonElement> = useRef<HTMLButtonElement>(null);
  const { triggerProps, tooltipProps } = useTooltipTrigger(props, state, ref);

  return (
    <Container>
      <StyledButtonWithHover
        strokeColor="var(--hvit)"
        hoverColor="var(--sort)"
        onClick={onClick}
        buttonRef={ref}
        tooltipTriggerProps={triggerProps}
      >
        <StyledEditIcon />
      </StyledButtonWithHover>
      {state.isOpen && (
        <Tooltip state={state} props={tooltipProps} buttonWidth={48}>
          {tooltip}
        </Tooltip>
      )}
    </Container>
  );
};

const StyledButtonWithHover = styled(ButtonWithHover)`
  border: none;
  @media (max-width: ${mobileBreakpoint}) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const StyledEditIcon = styled(EditIcon)`
  stroke: var(--sort);

  &:hover {
    stroke: var(--hvit);
  }
`;
