import React, { ChangeEvent, DragEvent, useRef } from 'react';

import { Label } from 'shared/input/inputField';
import { useNotification } from 'shared/notification/useNotification';
import styled from 'styled-components/macro';
import { maxFileSize } from 'utils/constants';

const FileUploader = ({
  selectedFile,
  onFileChange,
}: {
  selectedFile: File | null;
  onFileChange: (file: File) => void;
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { notifyWarning } = useNotification();

  const checkFileSize = (fileSize: number): boolean => {
    let validSize = true;
    if (fileSize > maxFileSize) {
      notifyWarning('Ooops!', 'Filen kan ikke være større enn 15 MB.');
      return (validSize = false);
    }
    return validSize;
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      let validSize = checkFileSize(file.size);
      if (validSize) onFileChange(file);
    }
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    let validSize = checkFileSize(droppedFile.size);
    if (validSize) {
      onFileChange(droppedFile);
    }
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleBoxClick = () => {
    fileInputRef.current && fileInputRef.current.click();
  };

  return (
    <div>
      <Label>Fil</Label>
      <DragAndDrop onDrop={handleDrop} onDragOver={handleDragOver} onClick={handleBoxClick}>
        <InvisibleInput type="file" onChange={handleFileChange} ref={fileInputRef} accept=".pdf" />
        {!selectedFile && <p>Klikk eller dra og slipp en pdf-fil her 📝</p>}
        {selectedFile && <p>Valgt fil: {selectedFile.name} 📝</p>}
      </DragAndDrop>
    </div>
  );
};

export default FileUploader;

const DragAndDrop = styled.div`
  padding: 20px;
  background-color: var(--overskyet);
  cursor: pointer;
`;

const InvisibleInput = styled.input`
  display: none;
`;
