import React, { RefObject } from 'react';

import { AddNewPostToCollectionModal } from 'collection/detailCollection/AddNewPostToCollectionModal';
import { addPostToCollection } from 'collection/utils/collectionApi';
import { useModal } from 'collection/utils/useModal';
import { mapFromMetadataToPost } from 'post/utils/postUtils';
import { useRecoilValue } from 'recoil';
import Icon from 'shared/Icon';
import AddPostsToSelections from 'shared/addPostsToSelections/AddPostsToSelections';
import { ButtonWithHover } from 'shared/button/ButtonWithHover';
import { useNotification } from 'shared/notification/useNotification';
import { useLoggedInEmployee } from 'stateAndApi/employeeApi';
import { employeeRecordState } from 'stateAndApi/employeeState';
import { trackEventToMixpanel } from 'stateAndApi/mixpanelApi';
import styled from 'styled-components/macro';
import { CollectionBackend, CollectionPost } from 'types/collectionTypes';
import { MixpanelEvent } from 'types/mixpanelTypes';
import { Post } from 'types/postTypes';
import { tabletBreakpoint } from 'utils/constants';
import { getErrorMessage } from 'utils/utils';

interface Props {
  collection: CollectionBackend;
  setCollection: (collection: CollectionBackend) => void;
  ref: RefObject<HTMLDivElement>;
}

export const AddContentToCollection = React.forwardRef(
  ({ collection, setCollection }: Props, ref: React.Ref<HTMLDivElement>) => {
    const { notifyInfo, notifyWarning } = useNotification();
    const loggedInEmployee = useLoggedInEmployee();
    const { isModalOpen, openModal, closeModal } = useModal();
    const employeeRecord = useRecoilValue(employeeRecordState);

    const handleAddPostToCollection = async (post: Post) => {
      try {
        await addPostToCollection(collection.metadata.id!!, post.id);

        const collectionPosts = collection.posts;

        const collectionPost: CollectionPost = {
          addedBy: loggedInEmployee.id,
          addedDate: new Date().toISOString(),
          post: {
            ...post,
            contributors: post.contributorEmployees.map((employee) => employee.id),
            collection_ids: [],
            consumption_time: post.consumptionTime,
            is_external: post.isExternal,
            publisher_id: post.publisherEmployee ? post.publisherEmployee.id : 0,
            media_type: post.mediaType,
            thumbnail_url: post.thumbnailUrl,
            total_views: post.totalViews,
            is_share_to_bekk_no: post.isShareToBekkNo,
          },
        };
        collectionPosts.push(collectionPost);

        setCollection({ ...collection, posts: collectionPosts });

        await trackEventToMixpanel(MixpanelEvent.ADD_POST_TO_COLLECTION_FROM_COLLECTION, loggedInEmployee);
        notifyInfo('Hurra!', 'Innlegget ble lagt til i samlingen');
      } catch (error) {
        notifyWarning('OBS', getErrorMessage(error));
      }
    };

    return (
      <>
        <ButtonContainer>
          <FetchButton hoverColor="var(--primary)" type="submit" onClick={openModal}>
            <StyledIcon name="plus" /> {'Lag nytt innlegg'}
          </FetchButton>
        </ButtonContainer>
        <AddNewPostToCollectionModal
          collection={collection}
          setCollection={setCollection}
          isModalOpen={isModalOpen}
          closeModal={closeModal}
        />
        <AddPostsToSelections
          handleAddPost={handleAddPostToCollection}
          existingPosts={collection.posts.map((p) => mapFromMetadataToPost(p.post, employeeRecord))}
          type={'samling'}
          ref={ref}
        />
      </>
    );
  }
);

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const FetchButton = styled(ButtonWithHover)`
  width: fit-content;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 1.5rem 0.5rem;
  font-family: var(--din-regular);
  @media (max-width: ${tabletBreakpoint}) {
    font-size: var(--14px-rem);
  }
`;

const StyledIcon = styled(Icon)`
  width: 2rem;
  height: 2rem;
`;
