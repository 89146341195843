import React from 'react';

import Icon from 'shared/Icon';
import { ButtonWithHover } from 'shared/button/ButtonWithHover';
import styled from 'styled-components/macro';

type Props = {
  checked: boolean;
  callback: () => void;
};

export const CardFooterKompetanseButton = ({ checked, callback }: Props) => {
  return (
    <HugeButton hoverColor="var(--primary)" onClick={() => callback()} checked={checked}>
      {checked ? 'Lest' : 'Ikke lest'} {checked && <StyledIcon name="checkmark" />}
    </HugeButton>
  );
};

const HugeButton = styled(ButtonWithHover)<{ checked: boolean }>`
  width: fit-content;
  display: flex;
  gap: 0.5rem;
  margin-right: 0.5rem;
  align-items: center;
  padding: 0 0.5rem;
  font-family: var(--din-regular);
  font-weight: bold;
  border: ${({ checked }) => !checked && 'none'};
  background-color: ${({ checked }) => (checked ? '' : 'var(--solnedgang)')};
  height: 2rem;
  :hover {
    cursor: pointer;
    box-shadow: 1px 2px 2px 1px var(--overskyet);
    border: none;
  }
`;

const StyledIcon = styled(Icon)`
  width: 1.5rem;
  height: 1.5rem;
`;
