import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel, { EmblaCarouselType, EmblaOptionsType } from 'embla-carousel-react';
import styled from 'styled-components/macro';
import { mobileBreakpoint, tabletBreakpoint } from 'utils/constants';

import { CarouselButton } from './CarouselButton';

const Embla = styled.div`
  overflow: hidden;
  position: relative;
`;

const CarouselContainer = styled.div`
  display: flex;
`;

const CarouselCard = styled.div`
  flex: 1 0 50%;
  padding: 0 2rem 0 0;
  width: 50%;

  @media (max-width: ${mobileBreakpoint}) {
    flex: 0 0 100%;
    padding-right: 1rem;
    width: 100%;
  }
`;

const CarouselDots = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 7.5rem 0 5rem;
  margin-top: 1rem;

  @media (max-width: ${mobileBreakpoint}) {
    padding: 0 1rem 0 1rem;
  }

  @media (min-width: ${tabletBreakpoint}) {
    justify-content: center;
  }
`;

type Props = {
  options?: EmblaOptionsType;
  slides: ReactNode[];
};

const Carousel = ({ options, slides }: Props) => {
  const autoplayOptions = {
    delay: 8000,
  };

  const [emblaRef, emblaApi] = useEmblaCarousel(options, [Autoplay(autoplayOptions)]);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const scrollTo = useCallback((index: number) => emblaApi && emblaApi.scrollTo(index), [emblaApi]);

  const onInit = useCallback((emblaApi: EmblaCarouselType) => {
    setScrollSnaps(emblaApi.scrollSnapList());
  }, []);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on('select', onSelect);
  }, [emblaApi, onInit, onSelect]);

  return (
    <Embla ref={emblaRef}>
      <CarouselContainer>
        {slides.map((slide, index) => (
          <CarouselCard key={index}>{slide}</CarouselCard>
        ))}
      </CarouselContainer>
      <CarouselDots>
        {scrollSnaps.map((_, index) => (
          <CarouselButton key={index} selected={index === selectedIndex} onClick={() => scrollTo(index)} />
        ))}
      </CarouselDots>
    </Embla>
  );
};

export default Carousel;
