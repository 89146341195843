import React from 'react';
import AnimateHeight from 'react-animate-height';

import styled from 'styled-components/macro';
import { tabletBreakpoint } from 'utils/constants';

import { FilterMenu } from './FilterMenu';

const OuterContainer = styled.div`
  width: 100%;

  @media (max-width: ${tabletBreakpoint}) {
    max-width: 30rem;
  }
`;

interface FilterPostsProps {
  showFilterMenu: boolean;
}

export const FilterPosts = ({ showFilterMenu }: FilterPostsProps) => {
  return (
    <>
      {showFilterMenu && (
        <OuterContainer>
          <AnimateHeight height={showFilterMenu ? 'auto' : '0%'}>
            <FilterMenu />
          </AnimateHeight>
        </OuterContainer>
      )}
    </>
  );
};
