import React from 'react';

import { Container, Input, InputFieldProps, Label } from '../inputField';

interface Props extends InputFieldProps {
  value: number | null;
  onChange(value: number): void;
}

const InputNumber = ({ className, label, value, onChange, onBlur, disabled }: Props) => (
  <Container className={className}>
    {label && <Label>{label}</Label>}
    <Input
      type="number"
      value={value ? value : ''}
      onChange={(e) => onChange(Number(e.target.value))}
      onBlur={onBlur}
      disabled={disabled}
    />
  </Container>
);

export default InputNumber;
