import React, { FormEvent, useEffect, useState } from 'react';

import { CheckBox, CheckBoxDescription, Checkmark, Label } from 'createNewPost/CreateNewPost';
import { ContentPicker } from 'createNewPost/form/ContentPicker';
import FileUploader from 'createNewPost/form/FileUploader';
import { patchMyHistoryMapping } from 'myPage/myHistory/utils/myHistoryApi';
import { EditButtons } from 'post/editPost/EditButtons';
import { UrlInput } from 'post/editPost/UrlInput';
import { deleteFile, getFile, uploadFile } from 'post/utils/fileApi';
import { useUpdatePost } from 'post/utils/postApi';
import { useRecoilValue } from 'recoil';
import InputText from 'shared/input/InputText';
import { SummaryInput } from 'shared/input/SummaryInput';
import { ContributorsInput } from 'shared/input/postInput/ContributorsInput';
import { ImageSelect } from 'shared/input/postInput/ImageSelect';
import InputNumber from 'shared/input/postInput/InputNumber';
import { TagsFormField } from 'shared/input/postInput/TagsFormField';
import { useNotification } from 'shared/notification/useNotification';
import { employeeRecordState } from 'stateAndApi/employeeState';
import { useGoToRoute } from 'stateAndApi/history';
import styled from 'styled-components/macro';
import { MediaType } from 'types/mediaTypes';
import { CreateMyHistoryMappingBackend, InteractionType } from 'types/myHistoryTypes';
import { Post } from 'types/postTypes';
import { fadeAnimation } from 'utils/commonAnimations';
import { preventDefaultBehaviourEnter } from 'utils/eventListeners';
import { RoutePaths } from 'utils/urls';
import { Warning, isNotUndefined } from 'utils/utils';

const DescriptionForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  text-align: left;
  animation: ${fadeAnimation};
  gap: var(--3);

  .textFieldLabel {
    margin-top: var(--1);
    font-size: var(--14px-rem);
    margin-bottom: var(--05);
  }
  .textFieldInput {
    font-size: var(--18px-rem);
    padding: var(--05) var(--1-05);
  }
`;

export const NarrowInput = styled.div`
  margin-top: 20px;
  width: 18rem;
`;

interface Props {
  editedPost: Post;
  setEditedPost: (v: Post) => void;
}

export const EditPost = ({ setEditedPost, editedPost }: Props) => {
  const goToRoute = useGoToRoute();
  const [submitting, setSubmitting] = useState(false);
  const employeeRecord = useRecoilValue(employeeRecordState);
  const [editedThumbnail, setEditedThumbnail] = useState(editedPost.thumbnailUrl);
  const [showThumbnail, setShowThumbnail] = useState(true);
  const [urlIsValidated, setUrlIsValidated] = useState<undefined | boolean>(undefined);
  const [originalPost] = useState(editedPost);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileChanged, setFileChanged] = useState(false);

  const updatePost = useUpdatePost();
  const { notifyWarning, notifyError, clearNotification } = useNotification();

  useEffect(() => {
    if (editedPost.mediaType === 'file') {
      getFile(editedPost.url).then((fileBlob) => {
        setSelectedFile(new File([fileBlob], editedPost.url, { type: 'pdf' }));
      });
    }
  }, [editedPost.url, editedPost.mediaType]);

  const onChangePostTitle = (title: string) => {
    setEditedPost({ ...editedPost, title: title });
  };

  const onConsumptionTimeChange = (consumptionTime: number) => {
    setEditedPost({ ...editedPost, consumptionTime: consumptionTime > 0 ? consumptionTime * 60 : null });
  };

  const onChangePostDescription = (description: string) => {
    setEditedPost({ ...editedPost, description: description });
  };

  const onMediaTypeChange = (mediaType: MediaType) => {
    setEditedPost({
      ...editedPost,
      mediaType: mediaType,
    });
  };

  const onChangeContributors = (contributors: string[]) => {
    setEditedPost({
      ...editedPost,
      contributorEmployees: contributors.map((e) => employeeRecord.getEmployeeByName(e)).filter(isNotUndefined),
    });
  };

  const onChangeTags = (tags: string[]) => {
    setEditedPost({ ...editedPost, tags: tags });
  };

  const onChangeUrl = (url: string) => {
    if (!urlIsValidated) {
      clearNotification();
    }
    setUrlIsValidated(undefined);
    setEditedPost({ ...editedPost, url: url });
  };

  const onFileChange = (file: File) => {
    setSelectedFile(file);
    setFileChanged(true);
  };

  const onChangeIsShareToBekkNo = (isShareToBekkNo: boolean) => {
    setEditedPost({ ...editedPost, isShareToBekkNo: isShareToBekkNo });
  };

  const postValidation = (editedPost: Post) => {
    if (editedPost.title === '' && editedPost.url === '') throw new Warning('Innlegget mangler både tittel og url.');
    if (editedPost.title === '') throw new Warning('Innlegget må ha en tittel.');
    if (editedPost.url === '') throw new Warning('Innlegget må ha en url.');
  };

  const handleLogInteraction = () => {
    const oldContributors = originalPost.contributorEmployees.map((employee) => employee.id);
    const newContributors = editedPost.contributorEmployees.map((employee) => employee.id);

    const oldContributorsMapped: CreateMyHistoryMappingBackend[] = oldContributors.map((user_id) => ({
      post_id: originalPost.id,
      user_id: user_id,
      interaction_type: InteractionType.CONTRIBUTED,
      contributors: [],
    }));
    const newContributorsMapped: CreateMyHistoryMappingBackend[] = newContributors.map((user_id) => ({
      post_id: editedPost.id,
      user_id: user_id,
      interaction_type: InteractionType.CONTRIBUTED,
      contributors: [],
    }));
    patchMyHistoryMapping(oldContributorsMapped, newContributorsMapped, originalPost.id);
  };

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setSubmitting(true);
    clearNotification();
    const thumbnail = editedPost.thumbnailUrl !== editedThumbnail ? editedThumbnail : editedPost.thumbnailUrl;
    const isExternal = editedPost.contributorEmployees.length === 0;

    try {
      postValidation(editedPost);
      if (selectedFile && fileChanged) {
        await deleteFile(editedPost.url);
        const { name } = await uploadFile(selectedFile);
        await updatePost({
          ...editedPost,
          thumbnailUrl: thumbnail,
          isExternal,
          url: name,
        });
        onChangeUrl(name);
      } else {
        await updatePost({
          ...editedPost,
          thumbnailUrl: thumbnail,
          isExternal,
        });
      }

      handleLogInteraction();

      goToRoute(RoutePaths.POST + '/' + originalPost.id);
    } catch (e: any) {
      const errorUrl: string | undefined = e.url;
      if (!!errorUrl) {
        notifyError('Her var det visst noe feil med URLen!', 'Har du limt inn hele lenken? :)');
        setUrlIsValidated(false);
      } else if (e instanceof Warning) {
        notifyWarning('OBS! 🚨', e.message);
      } else if (e instanceof Error) {
        notifyError('Noe gikk galt', e.message);
      } else {
        notifyError('Ukjent feil', 'Noe gikk galt :(');
      }
    } finally {
      setSubmitting(false);
    }
  };

  const resetThumbnail = async (event: FormEvent) => {
    event.preventDefault();
    setEditedThumbnail(editedPost.thumbnailUrl);
  };

  const handleEditCancel = () => {
    setEditedPost(originalPost);
    goToRoute(RoutePaths.POST + '/' + originalPost.id);
  };

  const toMinutes = (seconds: number | null) => (seconds ? Math.ceil(seconds / 60) : null);

  let consumptionTimeLabel: string | null = null;
  if (['video', 'podcast'].includes(editedPost.mediaType)) {
    consumptionTimeLabel = 'Avspillingstid i minutter';
  } else if (editedPost.mediaType === 'article') {
    consumptionTimeLabel = 'Lesetid i minutter';
  }

  return (
    <DescriptionForm onSubmit={onSubmit} onKeyDown={preventDefaultBehaviourEnter}>
      {(editedPost.mediaType === 'file' ||
        editedPost.mediaType === 'article' ||
        editedPost.mediaType === 'collection' ||
        editedPost.mediaType === 'other' ||
        editedPost.mediaType === 'podcast' ||
        editedPost.mediaType === 'slack_thread' ||
        editedPost.mediaType === 'workshop') && (
        <ImageSelect
          resetThumbnail={resetThumbnail}
          editedThumbnail={editedThumbnail}
          setEditedThumbnail={setEditedThumbnail}
          showThumbnail={showThumbnail}
          setShowThumbnail={setShowThumbnail}
        />
      )}
      <ContentPicker value={editedPost.mediaType} onChange={(mediaType) => onMediaTypeChange(mediaType)} />

      {originalPost.mediaType === 'article' ||
      originalPost.mediaType === 'video' ||
      originalPost.mediaType === 'collection' ||
      originalPost.mediaType === 'other' ||
      originalPost.mediaType === 'podcast' ||
      originalPost.mediaType === 'slack_thread' ||
      originalPost.mediaType === 'workshop' ? (
        <UrlInput
          label="URL (obligatorisk)"
          value={editedPost.url}
          onChange={onChangeUrl}
          urlIsValidated={urlIsValidated}
        />
      ) : (
        <FileUploader selectedFile={selectedFile} onFileChange={onFileChange} />
      )}
      <InputText label="Tittel (obligatorisk)" value={editedPost.title} onChange={onChangePostTitle} />
      <SummaryInput label="Beskrivelse" value={editedPost.description} onChange={onChangePostDescription} />
      {consumptionTimeLabel && (
        <NarrowInput>
          <InputNumber
            label={consumptionTimeLabel}
            value={toMinutes(editedPost.consumptionTime)}
            onChange={onConsumptionTimeChange}
          />
        </NarrowInput>
      )}
      <ContributorsInput value={editedPost.contributorEmployees.map((e) => e.name)} onChange={onChangeContributors} />
      <div>
        <Label>Videredeling</Label>
        <CheckBoxDescription disabled={submitting}>
          <CheckBox
            type="checkbox"
            color="var(--contrast)"
            disabled={false}
            checked={editedPost.isShareToBekkNo}
            onChange={() => onChangeIsShareToBekkNo(!editedPost.isShareToBekkNo)}
          />
          <Checkmark visible={editedPost.isShareToBekkNo} />
          Er det greit at innlegget deles til Bekk.no?
        </CheckBoxDescription>
      </div>
      <TagsFormField
        onChange={onChangeTags}
        existingTags={editedPost.tags}
        description={editedPost.description}
        title={editedPost.title}
      />
      <EditButtons post={editedPost} handleEditCancel={handleEditCancel} submitting={submitting} />
    </DescriptionForm>
  );
};
