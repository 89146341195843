import React, { createRef } from 'react';

import { ImageComponent } from 'shared/ImageComponent';
import { InternalLink } from 'shared/InternalLink';
import { CardContainer } from 'shared/card/CardContainer';
import { CardContentFlexbox } from 'shared/card/body/CardContentFlexbox';
import { LinkStyling } from 'shared/card/body/CardContentLinkWrapper';
import { CardDescription } from 'shared/card/body/CardDescription';
import { CardTextContainer } from 'shared/card/body/CardTextContainer';
import { CardTitle } from 'shared/card/body/CardTitle';
import { CardSize } from 'shared/card/cardSizes';
import { CardFooter } from 'shared/card/footer/CardFooter';
import { CardHeader } from 'shared/card/header/CardHeader';
import { useElementOnScreen } from 'shared/hooks/useElementOnScreen';
import styled from 'styled-components/macro';
import { CollectionDisplay } from 'types/collectionTypes';
import { CompetencePlanPreview } from 'types/competencePlanTypes';
import { mobileBreakpoint, tabletBreakpoint } from 'utils/constants';

const StyledInternalLink = styled(InternalLink)`
  ${LinkStyling};
`;

const NumberOfPosts = styled.span`
  font-family: var(--din-light);
  font-size: var(--14px-rem);
  justify-self: end;
  margin-left: auto;
  color: var(--morgendis);
`;

const StackContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  @media (max-width: ${mobileBreakpoint}) {
    width: 90%;
  }
  @media (max-width: ${tabletBreakpoint}) {
    width: 90%;
    margin-top: 1.5rem;
  }
`;

const Stack = styled.div`
  position: absolute;
  display: block;
  width: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
`;

const Stack1 = styled(Stack)`
  background: #ffb88d;
  top: -8px;
  left: 8px;
  z-index: -1;
`;

const Stack2 = styled(Stack)`
  background: #ffd9c2;
  top: -16px;
  left: 16px;
  z-index: -2;
`;

interface Props {
  route: string;
  collection: CollectionDisplay | CompetencePlanPreview;
}

export const CollectionCard = ({ route, collection }: Props) => {
  const cardSize = CardSize.SMALL;
  const cardRef = createRef<HTMLDivElement>();
  const [containerRef, isVisible] = useElementOnScreen(
    {
      root: null,
      rootMargin: '0px',
      threshold: 0.2,
    },
    cardRef
  );

  // Disse funksjonene er en midlertidig løsning frem til alle typene holder seg til "backend" formatet i.e. thumbnail_url
  const isCollectionDisplay = (collection: any): collection is CollectionDisplay => {
    return 'thumbnailUrl' in collection && 'numberOfPosts' in collection;
  };

  const getThumbnailUrl = (collection: Props['collection']): string => {
    const thumbnailUrl = isCollectionDisplay(collection) ? collection.thumbnailUrl : collection.thumbnail_url;
    return thumbnailUrl ? thumbnailUrl : '';
  };

  const getNumberOfPosts = (collection: Props['collection']): number => {
    return isCollectionDisplay(collection) ? collection.numberOfPosts : collection.number_of_posts;
  };

  return (
    <CardContainer ref={containerRef} visible={isVisible} cardSize={cardSize}>
      <CardHeader>
        <div />
      </CardHeader>
      <StackContainer>
        <StyledInternalLink to={route} draggable={false}>
          <CardContentFlexbox>
            <Stack1 />
            <Stack2 />
            <ImageComponent
              src={getThumbnailUrl(collection)}
              alt={`Bildet for samlingen med tittel: ${collection.title}}`}
            />
            <CardTextContainer cardSize={cardSize}>
              <CardTitle cardSize={cardSize}>{collection.title}</CardTitle>
              <CardDescription cardSize={cardSize}>{collection.description}</CardDescription>
            </CardTextContainer>
          </CardContentFlexbox>
        </StyledInternalLink>
      </StackContainer>
      <CardFooter cardSize={cardSize}>
        <NumberOfPosts>Antall innlegg: {getNumberOfPosts(collection)}</NumberOfPosts>
      </CardFooter>
    </CardContainer>
  );
};
