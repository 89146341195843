import React from 'react';
import Select, { OnChangeValue } from 'react-select';

export type CollectionSortMode = 'newlyAdded' | 'uploadDate' | 'title' | 'consumptionTime';
type CollectionPostSortOption = {
  [key in CollectionSortMode]: 'Lagt til i samling' | 'Opplastingsdato' | 'Tittel' | 'Lengde';
};

type SortOption = {
  value: string;
  label: string;
};

const options: CollectionPostSortOption = {
  newlyAdded: 'Lagt til i samling',
  uploadDate: 'Opplastingsdato',
  title: 'Tittel',
  consumptionTime: 'Lengde',
};

const customStyleReactSelect = {
  control: (style: any) => ({
    ...style,
    fontFamily: 'var(--din-light)',
    borderRadius: '0',
  }),
  menu: (style: any) => ({
    ...style,
    borderRadius: '0',
  }),
  option: (style: any, state: any) => ({
    ...style,
    color: 'var(--sort)',
    backgroundColor: state.isFocused ? 'var(--primary)' : 'transparent',
  }),
  container: (style: any) => ({
    ...style,
    width: '12rem',
  }),
};

type CollectionPostSortProps = {
  sortWord: string;
  setSortWord: (sortWord: CollectionSortMode) => void;
};

export const CollectionPostSortDialog = ({ sortWord, setSortWord }: CollectionPostSortProps) => {
  const handleOnClick = (sortMode: OnChangeValue<SortOption, false>) => {
    setSortWord(sortMode?.value as CollectionSortMode);
  };

  return (
    <Select
      placeholder="Sorter etter..."
      defaultValue={{ value: sortWord, label: options[sortWord as CollectionSortMode] }}
      options={Object.entries(options).map(([value, label]) => ({ value, label }))}
      styles={customStyleReactSelect}
      onChange={handleOnClick}
    />
  );
};
