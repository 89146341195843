import React from 'react';

import { FloatingSelectorContainer, FloatingSelectorProps, TouchableOpacity } from 'landing/FloatingTagSelector';
import { useRecoilValue } from 'recoil';
import { ContributorsInput } from 'shared/input/postInput/ContributorsInput';
import { employeeRecordState } from 'stateAndApi/employeeState';
import { isNotUndefined } from 'utils/utils';

export const FloatingContributorSelector = ({ editedPost, setEditedPost, closeAndUpdate }: FloatingSelectorProps) => {
  const employeeRecord = useRecoilValue(employeeRecordState);

  const onChangeContributors = (contributors: string[]) => {
    setEditedPost({
      ...editedPost,
      contributorEmployees: contributors.map((e) => employeeRecord.getEmployeeByName(e)).filter(isNotUndefined),
    });
  };

  return (
    <>
      <TouchableOpacity onClick={() => closeAndUpdate(editedPost)} />
      <FloatingSelectorContainer>
        <ContributorsInput
          focus={true}
          value={editedPost.contributorEmployees.map((e) => e.name)}
          onChange={onChangeContributors}
        />
      </FloatingSelectorContainer>
    </>
  );
};
