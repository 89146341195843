import React, { useEffect, useState } from 'react';

import { getPostsBySearch } from 'post/utils/postApi';
import { useRecoilValue } from 'recoil';
import { CardSize } from 'shared/card/cardSizes';
import { CardFeedGrid } from 'shared/feed/CardFeedGrid';
import { employeeRecordState } from 'stateAndApi/employeeState';
import { mapToFrontendSearchResults } from 'stateAndApi/searchStates';
import styled from 'styled-components/macro';
import { Post } from 'types/postTypes';
import { SearchResult } from 'types/searchTypes';

import SearchResultCard from './SearchResultCard';

interface Props {
  searchState: string;
  clearSearchState: () => void;
  handleAddPost: (post: Post) => void;
  existingPosts: Post[];
}

const SearchFeedDetailPage = ({ searchState, clearSearchState, handleAddPost, existingPosts }: Props) => {
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [postsForScroll, setPostsForScroll] = useState<Post[]>([]);
  const [searchResultAmount, setSearchResultAmount] = useState<number>(0);
  const employeeRecord = useRecoilValue(employeeRecordState);

  useEffect(() => {
    const searchFeedRequestBody = {
      limit: 10000,
      offset: 0,
    };

    searchState.length &&
      getPostsBySearch(searchState, searchFeedRequestBody).then((res) => {
        setSearchResults(mapToFrontendSearchResults(res.searchResultBackend, employeeRecord));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState, employeeRecord]);

  useEffect(() => {
    if (searchResults) {
      setPostsForScroll(searchResults.filter((p) => p.type === 'post') as Post[]);
      setSearchResultAmount(searchResults.filter((p) => p.type === 'post').length);
    }
  }, [searchResults]);

  return (
    <>
      {searchState.length > 0 && (
        <SearchCountContainer>
          {`Viser ${searchResultAmount} resultater for `}
          {searchState.trim() && <StyledButton onClick={clearSearchState}>{searchState}</StyledButton>}
        </SearchCountContainer>
      )}

      <CardFeedGrid>
        {postsForScroll.map((post) => (
          <SearchResultCard
            handleAddPost={handleAddPost}
            isPostInSelection={existingPosts.some((p) => p.id === post.id)}
            key={post.id}
            post={post}
            cardSize={CardSize.SMALL}
          />
        ))}
      </CardFeedGrid>
    </>
  );
};

export default SearchFeedDetailPage;

const StyledButton = styled.button`
  border: var(--1px) solid var(--overskyet-kontrast);
  background: inherit;
  font-family: var(--din-regular);
  font-size: var(--16px-rem);
  height: fit-content;
  width: fit-content;
  padding: var(--075);
  text-decoration: line-through;
  text-decoration-color: transparent;
  transition: text-decoration-color 0.2s ease-in-out;

  &:hover,
  &:focus-visible {
    cursor: pointer;
    text-decoration-color: var(--sort);
  }
`;

const SearchCountContainer = styled.div`
  padding-bottom: var(--3);
`;
