import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useRecoilValue } from 'recoil';
import { employeeRecordState } from 'stateAndApi/employeeState';
import { CompetencePlanBackend } from 'types/competencePlanTypes';

import { DetailCompetencePlan } from './detailCompetencePlan/DetailCompetencePlan';
import { getCompetencePlan } from './utils/competenceApi';

import EditCompetencePlan from './EditCompetencePlan';

interface Props {
  editing: boolean;
}

export const CompetencePlan = ({ editing }: Props) => {
  const { competenceId } = useParams<{ competenceId: string }>();
  const employeeRecord = useRecoilValue(employeeRecordState);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [competencePlan, setCompetencePlan] = useState<CompetencePlanBackend>();

  useEffect(() => {
    if (!competenceId) return;
    getCompetencePlan(competenceId).then((kompetanseplan) => {
      setCompetencePlan(kompetanseplan);
      setIsLoading(false);
    });
  }, [employeeRecord, competenceId]);

  if (isLoading || !competencePlan) return null;

  return editing ? (
    <EditCompetencePlan
      competencePlanMetadata={competencePlan.metadata}
      setCompetencePlanMetadata={(competencePlanMetadata) =>
        setCompetencePlan({ ...competencePlan, metadata: competencePlanMetadata })
      }
    />
  ) : (
    <DetailCompetencePlan competencePlan={competencePlan} setCompetencePlan={setCompetencePlan} />
  );
};
