import React, { CSSProperties, ReactNode } from 'react';

import styled from 'styled-components/macro';

import { mobileBreakpoint } from '../../../utils/constants';
import { CardSize, CardSizeStyling } from '../cardSizes';

const SIZES: CardSizeStyling = {
  small: {
    // Small cards uses different font
    '--font-family-cardtitle': 'var(--din-medium)',
    '--font-size-cardtitle': 'var(--20px-rem)',
  },
  medium: {
    '--font-family-cardtitle': 'var(--newzald-book)',
    '--font-size-cardtitle': 'var(--48px-rem)',
  },
  large: {
    '--font-family-cardtitle': 'var(--newzald-book)',
    '--font-size-cardtitle': 'var(--48px-rem)',
  },
};

/* Styled component exported to make it selectable in ancestor styling.
 * E.g. used to make title underlined when card is hovered. */
export const CardTitleSC = styled.h2`
  margin: 0;
  font-family: var(--font-family-cardtitle);
  font-size: var(--font-size-cardtitle);
  width: 100%;
  text-align: left;

  @media (max-width: ${mobileBreakpoint}) {
    font-family: var(--din-medium);
    font-size: var(--24px-rem);
  }
`;

interface Props {
  cardSize: CardSize;
  className?: string;
  children: ReactNode;
}

export const CardTitle = ({ cardSize, className, children }: Props) => {
  const styles = SIZES[cardSize] as CSSProperties;

  return (
    <CardTitleSC style={styles} className={className}>
      {children}
    </CardTitleSC>
  );
};
