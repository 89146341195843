import React, { useEffect } from 'react';

import { useGoToRoute } from 'stateAndApi/history';
import { RoutePaths } from 'utils/urls';

const FeaturedPost = () => {
  const goToRoute = useGoToRoute();
  const baseDate = new Date('03/20/2023');

  const postIdsFagdag = ['4233', '4234', '4235', '4236', '4237', '4237', '4237'];

  const getPostIndex = (listLength: number) => {
    return Math.floor((new Date().getTime() - baseDate.getTime()) / (1000 * 3600 * 24)) % listLength;
  };

  useEffect(() => {
    goToRoute(RoutePaths.POST.concat('/', postIdsFagdag[getPostIndex(postIdsFagdag.length)]));
  });

  return <></>;
};

export default FeaturedPost;
