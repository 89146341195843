import React from 'react';

import styled from 'styled-components/macro';
import { mobileBreakpoint, tabletBreakpoint } from 'utils/constants';

const ScrollButton = styled.button<{ selected: boolean }>`
  width: ${({ selected }) => (selected ? '3rem' : '2.5rem')};
  height: 0.3rem;
  background: ${({ selected }) => (selected ? 'var(--contrast)' : 'var(--lighter-primary)')};
  border-radius: 0.2rem;
  border: None;
  cursor: pointer;
  margin: 0 1rem 0 1rem;

  @media (max-width: ${tabletBreakpoint}) {
    width: 3rem;
  }

  @media (max-width: ${mobileBreakpoint}) {
    width: 1.5rem;
    margin: 0;
  }
`;

type DotButtonPropType = {
  selected: boolean;
  onClick: () => void;
};

export const CarouselButton = ({ selected, onClick }: DotButtonPropType) => {
  return <ScrollButton onClick={onClick} selected={selected} />;
};
