import React, { useRef, useState } from 'react';

import Badge from 'shared/Badge';
import useOnClickOutside from 'shared/hooks/useOnClickOutside';
import styled from 'styled-components/macro';
import { Post } from 'types/postTypes';

const BadgeContainerExpanded = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  position: absolute;
  top: 2.5rem;
  right: 0;
  background: white;
  border-radius: var(--05);
  box-shadow: 0 1px 8px 0 var(--overskyet-kontrast);
  padding: var(--2);
  z-index: 2;
`;

const BadgeContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  gap: 0.5rem;

  &:empty {
    display: none;
  }
`;

const Plus = styled(Badge)<{ isExpanded: boolean }>`
  background: ${({ isExpanded }) => isExpanded && 'var(--primary)'};
  width: 2.8rem;
`;

type Props = {
  post: Post;
  className?: string;
};

const MAX_NUMBER_OF_TAGS = 3;

export const CardBadges = ({ post, className }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const outerContainerRef = useRef<HTMLDivElement>(null);

  const expandBadges = () => {
    if (isExpanded) setIsExpanded(false);
    else setIsExpanded(true);
  };

  useOnClickOutside(outerContainerRef, () => {
    setIsExpanded(false);
  });

  return (
    <>
      {post.tags.length > MAX_NUMBER_OF_TAGS ? (
        <BadgeContainer className={className} ref={outerContainerRef}>
          <>
            {post.tags.slice(0, MAX_NUMBER_OF_TAGS).map((tag) => {
              return <Badge value={tag} key={post.id + tag} />;
            })}
            <Plus
              isExpanded={isExpanded}
              className={className}
              value={`+${post.tags.length - MAX_NUMBER_OF_TAGS}`}
              customOnclick={expandBadges}
            />
            {isExpanded && (
              <BadgeContainerExpanded className={className}>
                {post.tags.slice(MAX_NUMBER_OF_TAGS, post.tags.length).map((t, i) => (
                  <Badge key={i} value={t} />
                ))}
              </BadgeContainerExpanded>
            )}
          </>
        </BadgeContainer>
      ) : (
        <BadgeContainer className={className}>
          <>
            {post.tags.map((tag, i) => {
              return <Badge value={tag} key={i} />;
            })}
          </>
        </BadgeContainer>
      )}
    </>
  );
};
