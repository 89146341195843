import React from 'react';

import { useRecoilValue } from 'recoil';
import { useGoToRoute } from 'stateAndApi/history';
import { filterState, useSetFilters } from 'stateAndApi/searchStates';
import styled from 'styled-components/macro';
import { MediaType, mediaTypes, norwegianMediaTypes } from 'types/mediaTypes';
import { RoutePaths } from 'utils/urls';

const BadgeButton = styled.button`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  max-width: 10rem;
  font-size: 0.75rem;
  padding: 0.4rem 0.6rem 0.3rem;
  background: var(--overskyet);
  border-radius: 100vmax;
  border: none;
  font-family: inherit;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &:empty {
    display: none;
  }
`;

type Props = {
  value: string | MediaType;
  className?: string;
  customOnclick?: () => void;
};

const Badge = ({ value, className, customOnclick }: Props) => {
  const activeFilters = useRecoilValue(filterState).map((af) => af.value);
  const setFilters = useSetFilters();
  const goToRoute = useGoToRoute();

  const isMediaType = mediaTypes.includes(value as MediaType);

  const defaultOnClick = (value: string) => {
    if (!activeFilters.includes(value)) {
      if (isMediaType) {
        setFilters({
          action: 'ActivateMedia',
          filter: { key: 'Media', value: value as MediaType },
        });
      } else {
        setFilters({ action: 'ActivateFilter', filter: { key: 'Tag', value } });
      }
      goToRoute(RoutePaths.LANDING);
      window.scrollTo(0, 0);
    }
  };

  if (isMediaType && value === 'other') {
    return null;
  }

  const onClick = customOnclick ? customOnclick : defaultOnClick;

  return (
    <BadgeButton className={className} onClick={() => onClick(value)}>
      {isMediaType ? norwegianMediaTypes[value as MediaType] : value}
    </BadgeButton>
  );
};

export default Badge;
