import React from 'react';

import { addPostToCollection } from 'collection/utils/collectionApi';
import { useNotification } from 'shared/notification/useNotification';
import { useLoggedInEmployee } from 'stateAndApi/employeeApi';
import styled from 'styled-components/macro';
import { CollectionMetadataLight } from 'types/collectionTypes';
import { Post } from 'types/postTypes';
import { getErrorMessage } from 'utils/utils';

import { trackEventToMixpanel } from '../../stateAndApi/mixpanelApi';
import { MixpanelEvent } from '../../types/mixpanelTypes';
import CollectionPickerItems from '../CollectionPickerItems';

import { AddToCollectionMenuItem } from './MoreButton';

const CollectionPicker = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  background: var(--hvit);
  position: absolute;
  text-align: left;
  border-radius: var(--05);
  box-shadow: 0 1px 8px 0 var(--overskyet-kontrast);
  max-height: var(--30);
  top: var(--25);
  left: 0;
  overflow-y: scroll;
  width: var(--25);
`;

interface Props {
  post: Post;
  isOpen: boolean;
  setOpenMenu: (b: boolean) => void;
  children?: React.ReactNode;
}

export const CollectionMenuItem = ({ post, isOpen, setOpenMenu, children }: Props) => {
  const loggedInEmployee = useLoggedInEmployee();
  const { notifyInfo, notifyWarning } = useNotification();

  const addToCollection = async (collection: CollectionMetadataLight) => {
    try {
      await addPostToCollection(collection.id!!, post.id);

      await trackEventToMixpanel(MixpanelEvent.ADD_POST_TO_COLLECTION_FROM_CARD, loggedInEmployee);

      notifyInfo('Hurra!', 'Innlegget ble lagt til i samlingen');
    } catch (error) {
      notifyWarning('OBS', getErrorMessage(error));
    }
  };

  const toggleCollectionPicker = () => {
    setOpenMenu(!isOpen);
  };

  const handleBlur = (event: any) => {
    if (!event.currentTarget.contains(event.relatedTarget as Node)) {
      setOpenMenu(false);
    }
  };

  return (
    <>
      <AddToCollectionMenuItem onClick={toggleCollectionPicker} isOpen={isOpen}>
        {children}
      </AddToCollectionMenuItem>
      <CollectionPicker isOpen={isOpen} onBlur={handleBlur}>
        <CollectionPickerItems myId={loggedInEmployee.id} onClickCollectionItem={addToCollection} isOpen={isOpen} />
      </CollectionPicker>
    </>
  );
};
