import React from 'react';

import styled from 'styled-components/macro';

const Center = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

class DataLoadingErrorBoundary extends React.Component<{ children: React.ReactNode }, { hasError: boolean }> {
  state: { hasError: boolean } = {
    hasError: false,
  };

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <Center>
          <h2>Noe gikk galt mens vi prøvde å laste inn innholdet for deg.</h2>
          <h3>Vennligst prøv igjen senere.</h3>
        </Center>
      );
    }

    return this.props.children;
  }
}

export default DataLoadingErrorBoundary;
