import React from 'react';

import Icon from 'shared/Icon';
import { ButtonWithHover } from 'shared/button/ButtonWithHover';
import { useGoToRoute } from 'stateAndApi/history';
import styled from 'styled-components/macro';
import { RoutePaths } from 'utils/urls';

export const ViewMyCompetencePlansButton = () => {
  const goToRoute = useGoToRoute();

  return (
    <ButtonWithHover
      title="Mine kompetanseplaner"
      hoverColor="var(--contrast)"
      onClick={() => goToRoute(RoutePaths.MY_PAGE_COMPETENCE_PLAN)}
    >
      <ViewMyCompetencePlansIcon />
    </ButtonWithHover>
  );
};

const ViewMyCompetencePlansIcon = styled(Icon).attrs({ name: 'lightbulb' })`
  width: 100%;
  height: 100%;
`;
