import React from 'react';

import { PostCardWithHotkeys } from 'landing/PostCardWithHotkeys';
import { CollectionCard } from 'landing/collectionFeed/CollectionCard';
import { CardSize } from 'shared/card/cardSizes';
import styled from 'styled-components/macro';
import { CollectionDisplay } from 'types/collectionTypes';
import { CompetencePlanPreview } from 'types/competencePlanTypes';
import { Post } from 'types/postTypes';
import { RoutePaths } from 'utils/urls';

const StaticCard = styled(PostCardWithHotkeys)`
  transition: unset;
`;

export type RenderTypes = 'post' | 'competencePlanPreview' | 'collectionDisplay';

export const getFeedComponentToRender = (element: { type: RenderTypes; id: number }, i?: number) => {
  if (element.type === 'post') {
    return (
      <StaticCard key={`${element.id}_post`} cardSize={i ? calculateCardSize(i) : undefined} post={element as Post} />
    );
  } else if (element.type === 'competencePlanPreview') {
    return (
      <CollectionCard
        key={`${element.id}_competencePlan`}
        route={`${RoutePaths.COMPETENCE_PLAN}/${element.id}`}
        collection={element as unknown as CompetencePlanPreview}
      />
    );
  } else if (element.type === 'collectionDisplay') {
    return (
      <CollectionCard
        key={`${element.id}_collection`}
        route={`${RoutePaths.COLLECTION}/${element.id}`}
        collection={element as unknown as CollectionDisplay}
      />
    );
  } else {
    throw Error('What is this element? 😵‍💫');
  }
};

const calculateCardSize = (i: number) => (i === 0 ? CardSize.LARGE : CardSize.SMALL);
