import { atom, selector, useRecoilState } from 'recoil';
import { MediaType, mediaTypes } from 'types/mediaTypes';

import { mapToFrontendCollectionDisplay } from '../collection/utils/collectionApi';
import { isCollectionDisplayBackend } from '../collection/utils/validate';
import { mapToFrontendPost } from '../post/utils/postApi';
import { isCompetencePlanBackend, isPostBackend } from '../post/utils/postUtils';
import { cleanPost } from '../post/utils/postUtils';
import { Employee, EmployeeRecord } from '../types/employeeTypes';
import { Post } from '../types/postTypes';
import { SearchResult, SearchResultBackend, SearchSuggestion, SearchSuggestionBackend } from '../types/searchTypes';
import { reportError } from '../utils/errorReporting';

interface SearchFilter<K extends string, V extends string> {
  key: K;
  value: V;
}

export type FilterTypeMedia = SearchFilter<'Media', MediaType>;

export type FilterTypeExternal = SearchFilter<'External', 'external' | 'internal'>;

export type FilterTypeTag = SearchFilter<'Tag', string>;

export type FilterTypeEmployee = SearchFilter<'Employee', string>;

export type FilterType = FilterTypeMedia | FilterTypeExternal | FilterTypeTag | FilterTypeEmployee;

export type FilterKey = FilterType['key'];

type Action =
  | { action: 'ActivateMedia'; filter: FilterTypeMedia }
  | { action: 'ActivateFilter'; filter: FilterType }
  | { action: 'DeactivateFilter'; filter: FilterType };

export const useSetFilters = () => {
  const [filters, setFilters] = useRecoilState(filterState);

  return (msg: Action) => {
    switch (msg.action) {
      case 'ActivateMedia':
        setFilters([...filters.filter((f) => f.key !== 'Media'), msg.filter]);
        break;

      case 'ActivateFilter':
        const newFilterListActivate = [...filters, msg.filter];
        setFilters(newFilterListActivate);
        break;

      case 'DeactivateFilter':
        const { filter } = msg;
        const newFilterListDeactivate = filters.filter((f) => {
          return f.value !== filter.value;
        });
        setFilters(newFilterListDeactivate);
        break;
    }
  };
};

const filterKeyToQueryParam: Record<FilterKey, string> = {
  Media: 'f1',
  External: 'f2',
  Tag: 'f3',
  Employee: 'f4',
};

const queryParamToFilterKey = Object.entries(filterKeyToQueryParam).reduce((acc, [key, value]) => {
  acc[value] = key as FilterKey;
  return acc;
}, {} as Record<string, FilterKey>);

const filterKeyQueryParamValidation: Record<FilterKey, (v: string) => boolean> = {
  Media: (v) => mediaTypes.includes(v as MediaType),
  External: (v) => ['internal', 'external'].includes(v),
  Tag: (_) => true,
  Employee: (_) => true,
};

export const filterState = atom<FilterType[]>({
  key: 'FilterState',
  default: [],
  effects: [
    ({ onSet, setSelf }) => {
      onSet((newValue) => {
        // Synchronize state to url
        const url = new URL(window.location.href);
        // Clear url filter query params
        Object.values(filterKeyToQueryParam).forEach((f) => url.searchParams.delete(f));
        newValue.forEach((v) => url.searchParams.append(filterKeyToQueryParam[v.key], v.value));
        window.history.pushState('', '', url.toString());
      });

      // Synchronize url to state
      const searchParams = new URLSearchParams(window.location.search);
      setSelf(
        Object.keys(queryParamToFilterKey)
          .map((param) =>
            searchParams
              .getAll(param)
              .filter((value) => filterKeyQueryParamValidation[queryParamToFilterKey[param]](value))
              .map((value) => ({ key: queryParamToFilterKey[param], value } as FilterType))
          )
          .flat()
      );
    },
  ],
});

export const searchState = atom<string>({
  key: 'searchState',
  default: '',
  effects: [
    ({ onSet, setSelf }) => {
      onSet((newValue) => {
        // synchronize state to url
        const url = new URL(window.location.href);
        url.searchParams.delete('q');
        if (newValue.length) {
          url.searchParams.set('q', newValue);
        }
        window.history.pushState('', '', url.toString());
      });

      // synchronize url to state
      const searchParams = new URLSearchParams(window.location.search);
      const value = searchParams.get('q');
      setSelf(value || '');
    },
  ],
});

export const showFilterMenu = atom<boolean>({
  key: 'ShowFilterMenu',
  default: false,
});

export const anyFiltersIsActive = selector<boolean>({
  key: 'AnyFilterIsActive',
  get: ({ get }) => {
    const searchWord = get(searchState);
    const filters = get(filterState);
    if (searchWord.trim() !== '') {
      return true;
    }
    return filters.length > 0;
  },
});

type activeFiltersAndSearch = {
  search: string;
  activeFilters: FilterType[];
};

export const getActiveFiltersAndSearchWord = selector<activeFiltersAndSearch>({
  key: 'getActiveFiltersAndSearchWord',
  get: ({ get }) => {
    const searchWord = get(searchState);
    const filters = get(filterState);

    return { search: searchWord, activeFilters: filters };
  },
});

const mapToFrontendSearchResult = (
  backendResult: SearchResultBackend,
  employeeRecord: EmployeeRecord
): SearchResult => {
  if (isPostBackend(backendResult)) {
    return mapToFrontendPost(cleanPost(backendResult), employeeRecord);
  } else if (isCollectionDisplayBackend(backendResult)) {
    return mapToFrontendCollectionDisplay(backendResult, employeeRecord);
  } else if (isCompetencePlanBackend(backendResult)) {
    return backendResult;
  } else {
    const errorMessage: DetailedError = {
      name: 'mapToFrontendSearchResult error',
      message: 'Could not map the media to a frontend representation.',
      code: 'NA',
      error: 'Could not determine if the media is a post or a collection.',
      statusCode: 69,
    };
    reportError(errorMessage, ' ').then(() => {
      throw errorMessage.error;
    });
    return {} as Post;
  }
};

interface DetailedError extends Error {
  code: string;
  error: string;
  statusCode: number;
}

export const mapToFrontendSearchResults = (
  backendResults: SearchResultBackend[],
  employeeRecord: EmployeeRecord
): SearchResult[] => {
  return backendResults.map((result) => mapToFrontendSearchResult(result, employeeRecord));
};

export const findSearchSuggestions = (
  searchSuggestion: SearchSuggestionBackend,
  searchTerm: string,
  employeeRecord: EmployeeRecord
): SearchSuggestion => {
  const tagSuggestions = findTagSuggestions(searchSuggestion.tags);
  const employeeSuggestions = findEmployeeSuggestions(searchTerm, employeeRecord);
  return {
    content: searchSuggestion.content,
    tags: tagSuggestions,
    employees: employeeSuggestions,
  };
};

const findTagSuggestions = (tags: string[]): FilterTypeTag[] => {
  return tags.map((tag) => ({
    key: 'Tag',
    value: tag,
  }));
};

export const findEmployeeSuggestions = (searchTerm: string, employeeRecord: EmployeeRecord): Employee[] => {
  const lowercaseSearchTerm = searchTerm.toLowerCase();
  const listOfSearchTerms = findListOfSearchTerms(lowercaseSearchTerm);
  const employees = findMatchesBetweenSearchTermsAndName(lowercaseSearchTerm, listOfSearchTerms, employeeRecord);
  return employees;
};

const findListOfSearchTerms = (term: string): string[] => {
  return term.split(' ').filter((it) => it !== '');
};

const findMatchBetweenEmployeeNameAndSearchTerm = (employee: string, searchTerm: string) => {
  return employee.substring(0, searchTerm.length) === searchTerm;
};

const findMatchBetweenListOfEmployeeNameAndListOfSearchTerm = (
  listOfEmployeeName: string[],
  listOfSearchTerms: string[]
) => {
  return listOfEmployeeName.some((currentName) =>
    listOfSearchTerms.some((listOfSearchTerms) => currentName.includes(listOfSearchTerms))
  );
};

const findMatchesBetweenSearchTermsAndName = (
  searchTerm: string,
  listOfSearchTerms: string[],
  employeeRecord: EmployeeRecord
) => {
  return employeeRecord.employees
    .filter((employee) => {
      const lowercaseEmployeeName = employee.name.toLowerCase();
      const listOfEmployeeName = lowercaseEmployeeName.split(' ');
      return listOfSearchTerms.length > 1
        ? findMatchBetweenEmployeeNameAndSearchTerm(lowercaseEmployeeName, searchTerm)
        : findMatchBetweenListOfEmployeeNameAndListOfSearchTerm(listOfEmployeeName, listOfSearchTerms);
    })
    .slice(0, 5);
};
