import React from 'react';

import { restoreCollection } from 'collection/utils/collectionApi';
import { useNotification } from 'shared/notification/useNotification';
import styled from 'styled-components/macro';

type Props = { text: string; collectionId: number };

const Button = styled.button`
  background: none;
  border: 1px solid var(--sort);
  cursor: pointer;
  stroke: var(--sort);
  transition: 0.2s;

  &:hover,
  &:focus-visible {
    background: var(--grønn-kontrast);
  }
`;

export const UndoButton = ({ text, collectionId }: Props) => {
  const { notifyError, clearNotification, notifyInfo } = useNotification();
  const undoDelete = async () => {
    try {
      await restoreCollection(collectionId);
      clearNotification();
      notifyInfo('Info', 'Angret sletting av samling');
    } catch (e) {
      notifyError('Error', e instanceof Error ? e.message : 'Klarte ikke å angre sletting');
    }
  };

  return <Button onClick={undoDelete}>{text}</Button>;
};
