import React, { ReactElement, lazy, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Modal from 'react-modal';
import { Route, Routes, useLocation } from 'react-router-dom';

import { OverlayProvider } from '@react-aria/overlays';
import { Collection } from 'collection/Collection';
import { CompetencePlan } from 'competencePlan/CompetencePlan';
import { CreateNewCompetencePlan } from 'competencePlan/createCompetencePlan/CreateNewCompetencePlan';
import { Landing } from 'landing/Landing';
import { Feedback } from 'shared/feedback/Feedback';
import { useLoggedInEmployee } from 'stateAndApi/employeeApi';
import { trackPageNavigationToMixpanel } from 'stateAndApi/mixpanelApi';
import { title } from 'utils/constants';
import { RoutePaths } from 'utils/urls';

import { CreateNewCollection } from './createNewCollection/CreateNewCollection';
import FeaturedPost from './featuredPost/FeaturedPost';

const DetailsPost = lazy(() => import('post/DetailPost'));
const CreateNewPost = lazy(() => import('createNewPost/CreateNewPost'));
const MyPage = lazy(() => import('myPage/MyPage'));

function ScrollToTopOnRouteChange() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname !== RoutePaths.LANDING) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
}

export const App = (): ReactElement => {
  return <MainRouter />;
};

export const MainRouter = (): ReactElement => {
  const loggedInEmployee = useLoggedInEmployee();
  const location = useLocation();

  useEffect(() => {
    Promise.resolve().then((r) => {
      if (!loggedInEmployee) return;
      setTimeout(async () => trackPageNavigationToMixpanel(location.pathname + location.hash, loggedInEmployee), 500);
    });
  }, [location, loggedInEmployee]);

  useEffect(() => {
    Modal.setAppElement('#app');
  }, []);

  return (
    <main id="app">
      <Helmet>
        <title>{title} - Bekk</title>
      </Helmet>
      <OverlayProvider>
        <ScrollToTopOnRouteChange />
        <Feedback />
        {/*<AchievementSocket />*/}
        <Routes>
          <Route path={RoutePaths.LANDING} element={<Landing />} />
          <Route path={RoutePaths.MY_PAGE} element={<MyPage />} />
          <Route path={RoutePaths.NEW} element={<CreateNewPost />} />
          <Route path={RoutePaths.NEW_COLLECTION} element={<CreateNewCollection />} />
          <Route path={RoutePaths.NEW_COMPETENCE_PLAN} element={<CreateNewCompetencePlan />} />
          <Route path={RoutePaths.QR} element={<FeaturedPost />} />
          <Route path={RoutePaths.FEATURED_POST} element={<FeaturedPost />} />
          <Route path={`${RoutePaths.POST}/:postId/*`} element={<DetailsPost />} />
          <Route path={`${RoutePaths.POST}/:postId/rediger`} element={<DetailsPost editing />} />
          <Route path={`${RoutePaths.COLLECTION}/:collectionId/*`} element={<Collection editing={false} />} />
          <Route
            path={`${RoutePaths.COLLECTION}/:collectionId/:title/rediger`}
            element={<Collection editing={true} />}
          />
          <Route path={`${RoutePaths.COMPETENCE_PLAN}/:competenceId`} element={<CompetencePlan editing={false} />} />
          <Route
            path={`${RoutePaths.COMPETENCE_PLAN}/:competenceId/rediger`}
            element={<CompetencePlan editing={true} />}
          />
          <Route path={RoutePaths.UNAUTHORIZED} element={<h2>Du har ikke tilgang til denne siden</h2>} />
        </Routes>
      </OverlayProvider>
    </main>
  );
};
