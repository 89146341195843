import React from 'react';

import { TagsFormField } from 'shared/input/postInput/TagsFormField';
import styled from 'styled-components/macro';
import { Post } from 'types/postTypes';

export const FloatingSelectorContainer = styled.form`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 30rem;
  transform: translate(-50%, -50%);
  background: var(--hvit);
  text-align: left;
  border-radius: var(--05);
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 8px 0 var(--overskyet-kontrast);
  z-index: 2;
  padding: var(--3) var(--3);
  gap: var(--3);
`;

export const TouchableOpacity = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  opacity: 0;
  z-index: 2;
`;

export type FloatingSelectorProps = {
  editedPost: Post;
  setEditedPost: (v: Post) => void;
  closeAndUpdate: (editedPost: Post) => void;
};

export const FloatingTagSelector = ({ editedPost, setEditedPost, closeAndUpdate }: FloatingSelectorProps) => {
  const onChangeTags = (tags: string[]) => {
    setEditedPost({ ...editedPost, tags: tags });
  };

  return (
    <>
      <TouchableOpacity onClick={() => closeAndUpdate(editedPost)} />
      <FloatingSelectorContainer>
        <TagsFormField
          focus
          onChange={onChangeTags}
          existingTags={editedPost.tags}
          description={editedPost.description}
          title={editedPost.title}
        />
      </FloatingSelectorContainer>
    </>
  );
};
