import React, { ChangeEvent } from 'react';

import styled from 'styled-components/macro';

import { TextFieldContainer, TextFieldLabel } from '../../post/editPost/UrlInput';

const TextFieldSummary = styled.textarea`
  background: var(--overskyet);
  color: var(--sort);
  height: var(--25);
  width: 100%;
  box-sizing: border-box;
  font-size: var(--18px-rem);
  font-family: var(--din-regular);
  resize: none;

  padding: var(--05) var(--1-05);
  border: none;
  background-position: 0 0, calc(100%) 0, 0 calc(100%), 0 0, 0 calc(100%);
  background-repeat: no-repeat;
  background-size: 0 var(--1px), var(--1px) 0, 0 var(--1px), var(--1px) 0, 0 var(--1px);
  background-image: linear-gradient(0deg, var(--solnedgang-kontrast), var(--solnedgang-kontrast)),
    linear-gradient(0deg, var(--solnedgang-kontrast), var(--solnedgang-kontrast)),
    linear-gradient(0deg, var(--solnedgang-kontrast), var(--solnedgang-kontrast)),
    linear-gradient(0deg, var(--solnedgang-kontrast), var(--solnedgang-kontrast)),
    linear-gradient(0deg, var(--morgendis), var(--morgendis));
  transition: 0.1s linear;

  :focus {
    background-size: 0 var(--1px), var(--1px) 0, 0 var(--1px), var(--1px) 0, 100% var(--1px);
    outline: 0;
  }
`;

interface SummaryProps {
  onChange: (s: string) => void;
  value: string | null;
  label: string;
}

export const SummaryInput = ({ onChange, value, label }: SummaryProps) => (
  <TextFieldContainer>
    <TextFieldLabel>{label}</TextFieldLabel>
    <TextFieldSummary
      onChange={(e: ChangeEvent<HTMLTextAreaElement>) => onChange(e.target.value)}
      value={value || ''}
    />
  </TextFieldContainer>
);
