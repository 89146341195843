import React from 'react';

import Icon from 'shared/Icon';
import { ButtonWithHover } from 'shared/button/ButtonWithHover';
import { useGoToRoute } from 'stateAndApi/history';
import styled from 'styled-components/macro';
import { RoutePaths } from 'utils/urls';

export const PlusIcon = styled(Icon).attrs({ name: 'plus' })`
  width: 100%;
  height: 100%;
`;

export const AddButton = () => {
  const goToRoute = useGoToRoute();

  return (
    <ButtonWithHover
      title="Legg til nytt innlegg"
      hoverColor="var(--contrast)"
      onClick={() => goToRoute(RoutePaths.NEW)}
    >
      <PlusIcon />
    </ButtonWithHover>
  );
};
