import React from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import {
  AuthenticationResult,
  BrowserCacheLocation,
  EventType,
  InteractionType,
  PublicClientApplication,
} from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { App } from 'App';
import moment from 'moment';
import 'moment/locale/nb';
import { RecoilRoot } from 'recoil';
import DataLoadingErrorBoundary from 'shared/DataLoadingErrorBoundary';
import { Loader } from 'shared/Loader';
import { NotificationProvider } from 'shared/notification/NotificationProvider';
import { createGlobalStyle, css } from 'styled-components/macro';
import { Theme, bekkColors, themes } from 'utils/colors';

import { getScopes } from './utils/config';

import './index.css';

const GlobalStyle = createGlobalStyle<{ currentTheme: Theme }>`
    :root {
        ${bekkColors}
        ${(props) =>
          props.currentTheme &&
          css`
            --primary: ${props.currentTheme.primary};
            --contrast: ${props.currentTheme.contrast};
            --lighter-primary: ${props.currentTheme.lighterPrimary};
            --previous-primary: ${props.currentTheme.previousPrimary};
            --previous-contrast: ${props.currentTheme.previousContrast};
            --previous-ligther-primary: ${props.currentTheme.previousLighterPrimary};
          `};
    }
`;

// Access environment variables from window._env_
const AZURE_AD_CLIENT_ID = process.env.REACT_APP_AZURE_AD_CLIENT_ID || '';
const AZURE_AD_TENANT_ID = process.env.REACT_APP_AZURE_AD_TENANT_ID || '';

// Konfigurer og opprett MSAL-instans
export const msalInstance = new PublicClientApplication({
  auth: {
    clientId: AZURE_AD_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${AZURE_AD_TENANT_ID}`,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
});

async function init() {
  // Initialiser instansen (dette bør gjøres så tidlig som mulig)
  await msalInstance.initialize();
  moment.locale('nb');

  const currentTheme = themes.find((theme) => {
    const now = moment().format('HH:mm');
    return now >= theme.from && now <= theme.to;
  });

  // Sett aktiv konto dersom den finnes, men ikke enda er satt
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Sett aktiv konto umiddelbart etter suksessfull autentisering
  msalInstance.addEventCallback((event) => {
    const account = (event.payload as AuthenticationResult)?.account;
    if (event.eventType === EventType.LOGIN_SUCCESS && account) {
      msalInstance.setActiveAccount(account);
    }
  });

  // Scope er kun nødvendig dersom appen prater med Bekk-apier
  const authRequest = {
    scopes: getScopes(),
  };

  const container = document.getElementById('root');
  const root = createRoot(container!); // createRoot(container!) if you use TypeScript

  root.render(
    <MsalProvider instance={msalInstance}>
      {/* Denne håndterer automatisk redirect til innlogging
      dersom brukeren ikke er innlogget/må reautentisere seg */}
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest}>
        <React.StrictMode>
          <BrowserRouter>
            <HelmetProvider>
              <RecoilRoot>
                <DataLoadingErrorBoundary>
                  <React.Suspense fallback={<Loader />}>
                    <GlobalStyle currentTheme={currentTheme ?? themes[1]} />
                    <NotificationProvider>
                      <App />
                    </NotificationProvider>
                  </React.Suspense>
                </DataLoadingErrorBoundary>
              </RecoilRoot>
            </HelmetProvider>
          </BrowserRouter>
        </React.StrictMode>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
}

init().catch((error) => console.error('Failed to initialize app', error));
