import styled from 'styled-components/macro';

export type InputFieldProps = {
  className?: string;
  label?: string;
  onBlur?: any;
  disabled?: boolean;
};

export const Container = styled.div`
  width: 100%;
`;

export const Label = styled.label`
  color: var(--morgendis);
  font-size: 14px;
  display: block;

  font-family: var(--din-light), sans-serif;
`;

export const Input = styled.input`
  background: var(--overskyet);
  color: var(--sort);
  height: 44px;
  width: 100%;
  box-sizing: border-box;
  font-size: ${18 / 16}rem;
  font-family: var(--din-regular);
  padding: 5px;
  padding-left: 10px;
  border: none;
  background-position: 0 0, calc(100%) 0, 0 calc(100%), 0 0, 0 calc(100%);
  background-repeat: no-repeat;
  background-size: 0 1px, 1px 0, 0 1px, 1px 0, 0 1px;
  background-image: linear-gradient(0deg, var(--solnedgang-kontrast), var(--solnedgang-kontrast)),
    linear-gradient(0deg, var(--solnedgang-kontrast), var(--solnedgang-kontrast)),
    linear-gradient(0deg, var(--solnedgang-kontrast), var(--solnedgang-kontrast)),
    linear-gradient(0deg, var(--solnedgang-kontrast), var(--solnedgang-kontrast)),
    linear-gradient(0deg, var(--morgendis), var(--morgendis));
  transition: all 0s linear;

  &:focus {
    background-size: 0 1px, 1px 0, 0 1px, 1px 0, 100% 1px;
    transition: all 0.1s linear;
    outline: 0;
  }

  &:disabled {
    color: var(--morgendis);
  }
`;
