import React, { ReactNode } from 'react';

import { useTooltip } from '@react-aria/tooltip';
import { mergeProps } from '@react-aria/utils';
import { TooltipTriggerState } from '@react-stately/tooltip';
import { AriaTooltipProps } from '@react-types/tooltip';
import styled from 'styled-components/macro';

const TooltipContainer = styled.span<{ left?: string }>`
  position: absolute;
  left: ${({ left }) => (left ? left : '-50%')};
  top: 120%;
  width: 100px;
  text-align: center;
  background-color: black;
  font-family: var(--din-regular);
  color: white;
  padding: 5px;
  border: 1px solid black;
  z-index: 1;
`;

const Triangle = styled.div<{ left?: string }>`
  position: absolute;
  left: ${({ left }) => (left ? left : '0')};
  right: 4px;
  top: 107%;

  width: 0;
  height: 0;
  border-left: var(--1) solid transparent;
  border-right: var(--1) solid transparent;

  border-bottom: var(--1) solid black;
`;

interface TooltipProps {
  props: AriaTooltipProps;
  state?: TooltipTriggerState;
  children: ReactNode;
  buttonWidth: number;
  left?: string;
}

export const Tooltip = ({ props, state, children, buttonWidth, left }: TooltipProps) => {
  let { tooltipProps } = useTooltip(props, state);

  const triangleLeft = () => {
    if (buttonWidth) {
      return buttonWidth / 2 - 8 + 'px';
    }
    return '';
  };

  return (
    <div>
      <Triangle left={triangleLeft()} />
      <TooltipContainer left={left} {...mergeProps({ ...props }, tooltipProps)}>
        {children}
      </TooltipContainer>
    </div>
  );
};
