import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';

import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import styled, { css } from 'styled-components/macro';
import { mobileBreakpoint, tabletBreakpoint } from 'utils/constants';

import Icon from '../Icon';
import { SingleLineClamp } from '../LineClamp';

import { FeedbackModal } from './FeedbackModal';

const Container = styled.div<{ hideFeedback: boolean; hideFeaturePrompt: boolean }>`
  position: fixed;
  right: var(--2);
  bottom: var(--2);
  z-index: 99;

  display: ${(props) => props.hideFeedback && 'none'};
  width: calc(100vw - 2rem);
  background: var(--sort);
  color: var(--hvit);
  stroke: var(--hvit);
  border-radius: 1rem;
  font-size: var(--16px-rem);
  transition: border-radius 0.2s 0.4s, background 0.2s, width 0.25s 0.2s;

  @media (min-width: ${mobileBreakpoint}) {
    width: 20rem; // Hardkodet for å kunne animere bredden
  }

  ${(props) =>
    props.hideFeaturePrompt &&
    css`
      width: 11.25rem; // Hardkodet for å kunne animere bredden
      border-radius: 2rem;

      @media (min-width: ${mobileBreakpoint}) {
        // To override the 'width: 20rem' for screens between mobileBreakpoint and tabletBreakpoint
        width: 11.25rem; // Hardkodet for å kunne animere bredden
      }

      @media (min-width: ${tabletBreakpoint}) {
        width: 10rem; // Hardkodet for å kunne animere bredden
      }

      &:hover {
        color: var(--sort);
        background: var(--primary);
        stroke: var(--sort);
      }
    `};
`;

const FeatureTitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
`;

const FeatureTitle = styled.h4`
  font-size: 18px;
  font-family: var(--din-medium);
  margin: 0;
`;

const FeatureDescription = styled.p`
  font-family: var(--din-regular);
  padding: 0 1rem 0.5rem;
  margin: 0;
`;

const CloseButton = styled.button`
  width: 2rem;
  height: 2rem;
  padding: 0.25rem;
  border: none;
  background: none;
  transition: background 0.2s;
  cursor: pointer;
  border-radius: 50%;

  &:hover {
    color: var(--sort);
    background: var(--primary);
  }
`;

const CrossIcon = styled(Icon).attrs({ name: 'cross' })`
  width: 100%;
  height: 100%;
  stroke: inherit;
  transition: transform 0.2s, stroke 0.2s;

  ${CloseButton}:hover & {
    stroke: var(--sort);
    transform: rotate(90deg);
  }
`;

const FeedbackButton = styled.div<{ hideFeaturePrompt: boolean; hideFeedback: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;

  padding: 0.5rem 1rem 0.75rem;
  border-radius: 0 0 14px 14px; // Litt mindre enn 1rem for å fikse avrundingsfeil
  font-family: var(--din-regular);
  transition: color 0.2s, background 0.2s, padding 0.2s;
  cursor: pointer;
  text-decoration: underline;

  ${(props) =>
    props.hideFeaturePrompt &&
    css`
      border-radius: 2rem;
      padding: 0.5rem 1rem;
      text-decoration: none;
    `};

  &:hover {
    background: var(--primary);
    color: var(--sort);
  }
`;

const Label = styled.span`
  display: inline-block;
  margin-bottom: 2px;

  ${SingleLineClamp}// Prevents line wrap when switching from desktop width to tablet width
`;

const HideButton = styled(CloseButton)<{ hideFeaturePrompt: boolean }>`
  width: 1rem;
  height: 1rem;
  padding: 0;

  @media (min-width: ${tabletBreakpoint}) {
    display: none;
  }

  ${(props) =>
    !props.hideFeaturePrompt &&
    css`
      display: none;
    `};
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AtlasSlack = styled.span`
  background: #baedff;
  border-radius: 2px;
  padding: -0px 2px;
  color: #003c69;
`;

const Link = styled.a`
  color: aqua;
`;

export const Feedback = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [hideFeedback, setHideFeedback] = useState(false);
  const [hideFeaturePrompt, setHideFeaturePrompt] = useLocalStorage('hideFeaturePrompt-kompetanseplan', false);

  function openModal() {
    setModalIsOpen(true);
  }

  return (
    <>
      <FeedbackModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />

      <Container hideFeedback={hideFeedback || modalIsOpen} hideFeaturePrompt={hideFeaturePrompt}>
        <AnimateHeight height={hideFeaturePrompt ? '0%' : 'auto'}>
          <FeatureTitleRow>
            <FeatureTitle>Ny feature: kompetanseplaner!</FeatureTitle>
            <CloseButton onClick={() => setHideFeaturePrompt(true)}>
              <CrossIcon />
            </CloseButton>
          </FeatureTitleRow>

          <FeatureDescription>
            Dra til <Link href="min-side#kompetanseplaner">min-side</Link> for å se dine kompetanseplaner eller for å
            lage en til resten av oss i Bekk!
          </FeatureDescription>
        </AnimateHeight>

        <FeedbackButton onClick={openModal} hideFeedback={hideFeedback} hideFeaturePrompt={hideFeaturePrompt}>
          <Label>Gi tilbakemelding</Label>
          <HideButton
            onClick={(e) => {
              e.stopPropagation();
              setHideFeedback(true);
            }}
            hideFeaturePrompt={hideFeaturePrompt}
          >
            <CrossIcon />
          </HideButton>
        </FeedbackButton>
      </Container>
    </>
  );
};
