import React, { FormEvent, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { Form, FormInnerOuterContainer, FormOuterContainer } from 'shared/FormComponents';
import { HeaderContainer } from 'shared/HeaderContainer';
import Button from 'shared/button/Button';
import { DeleteButtonWithWarning } from 'shared/button/DeleteButtonWithWarning';
import { ImageInput } from 'shared/input/ImageInput';
import InputText from 'shared/input/InputText';
import { SummaryInput } from 'shared/input/SummaryInput';
import { useNotification } from 'shared/notification/useNotification';
import styled from 'styled-components/macro';
import { CollectionMetadata } from 'types/collectionTypes';
import { mobileBreakpoint } from 'utils/constants';
import { preventDefaultBehaviourEnter } from 'utils/eventListeners';
import { RoutePaths } from 'utils/urls';

import { BackButton } from '../shared/button/BackButton';
import { possibleRoutesToGoToFromEditCollection, useGoToRoute } from '../stateAndApi/history';
import { Warning } from '../utils/utils';

import { deleteCollection, editCollection } from './utils/collectionApi';
import { sanitizeTitleForUrlUsage } from './utils/utils';
import { validateCollection } from './utils/validate';

const InputFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--4);
`;

const BottomButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-height: var(--48px-rem);
  margin-top: var(--3);
`;

const SaveButtonText = styled.span`
  font-size: var(--18px-rem);
  display: block;
  @media (max-width: ${mobileBreakpoint}) {
    span {
      display: none;
    }
  }
`;

const EditAndSubmitButtonContainer = styled.div`
  display: flex;
  gap: var(--2);
  height: 100%;
  max-height: var(--48px-rem);
`;

interface Props {
  collectionMetadata: CollectionMetadata;
  setCollection: (collectionMetadata: CollectionMetadata) => void;
}

export const EditCollection = ({ collectionMetadata, setCollection }: Props) => {
  const [imgHasError, setImgHasError] = useState(false);

  const { notifyInfo, notifyError, clearNotification } = useNotification();
  const goToRoute = useGoToRoute();

  const handleCancelCollection = () => {
    clearNotification();
    goToRoute(
      `${RoutePaths.COLLECTION}/${collectionMetadata.id}/${sanitizeTitleForUrlUsage(collectionMetadata.title)}`
    );
  };

  const handleSuccessfulEdit = () => {
    clearNotification();
    goToRoute(
      `${RoutePaths.COLLECTION}/${collectionMetadata.id}/${sanitizeTitleForUrlUsage(collectionMetadata.title)}`
    );
  };

  const onClickSaveChanges = async (event: FormEvent) => {
    event.preventDefault();
    try {
      validateCollection(collectionMetadata, imgHasError);
      await editCollection(collectionMetadata);
      handleSuccessfulEdit();
    } catch (e) {
      if (e instanceof Warning) {
        notifyError('Oisann', e.message);
      } else {
        notifyError('Error', e instanceof Error ? e.message : 'Klarte ikke endre samling');
      }
    }
  };

  const deleteThisCollection = async () => {
    try {
      await deleteCollection(collectionMetadata.id!!);
      goToRoute(RoutePaths.MY_PAGE_COLLECTIONS);
      notifyInfo('Info', 'Samlingen ble slettet', 7000, 'Angre?', collectionMetadata.id);
    } catch (e) {
      notifyError('Error', e instanceof Error ? e.message : 'Klarte ikke å slette samling');
    }
  };

  return (
    <FormOuterContainer>
      <Helmet>
        <title>Atlas - {collectionMetadata.title} </title>
      </Helmet>
      <FormInnerOuterContainer>
        <BackButton
          fallbackRoute={`${RoutePaths.COLLECTION}/${collectionMetadata.id}`}
          possibleRoutesToGoBackTo={possibleRoutesToGoToFromEditCollection}
        />
        <HeaderContainer>
          <h1>Rediger samling</h1>
        </HeaderContainer>
        <Form onKeyDown={preventDefaultBehaviourEnter}>
          <InputFieldsContainer>
            <InputText
              label="Tittel (obligatorisk)"
              value={collectionMetadata.title}
              onChange={(title) => setCollection({ ...collectionMetadata, title: title })}
            />
            <SummaryInput
              label="Beskrivelse"
              value={collectionMetadata.description}
              onChange={(summary) => setCollection({ ...collectionMetadata, description: summary })}
            />
            <ImageInput
              value={collectionMetadata.thumbnail_url}
              onChange={(thumbnailUrl) => setCollection({ ...collectionMetadata, thumbnail_url: thumbnailUrl })}
              setImgHasError={setImgHasError}
              showPreviewDefault
            />
          </InputFieldsContainer>
          <BottomButtonsContainer>
            <DeleteButtonWithWarning
              warningText="Er du sikker på at du vil slette denne samlingen?"
              deleteFunction={deleteThisCollection}
            />
            <EditAndSubmitButtonContainer>
              <Button onClick={handleCancelCollection} variant="white">
                Avbryt
              </Button>
              <Button onClick={onClickSaveChanges} variant="primary">
                <SaveButtonText>
                  Lagre<span> endringer</span>
                </SaveButtonText>
              </Button>
            </EditAndSubmitButtonContainer>
          </BottomButtonsContainer>
        </Form>
      </FormInnerOuterContainer>
    </FormOuterContainer>
  );
};
