import { CollectionDisplay, CollectionDisplayBackend, CollectionMetadata } from '../../types/collectionTypes';
import { Warning } from '../../utils/utils';

export const validateCollection = (collectionState: CollectionMetadata, imgHasError: boolean) => {
  if (collectionState.title.length === 0) {
    throw new Warning('Tittel kan ikke være tom!');
  }
  if (imgHasError) {
    throw new Warning('Vi finner ikke bildet! Du kan prøve en ny lenke eller la den være tom 🤷‍♂️');
  }
};

export const isCollectionDisplayBackend = (tbdValue: any): tbdValue is CollectionDisplayBackend => {
  return (tbdValue as CollectionDisplayBackend).type === 'collectionPreview';
};

export const isCollectionDisplay = (tbdValue: any): tbdValue is CollectionDisplay => {
  return (tbdValue as CollectionDisplay).type === 'collectionDisplay';
};
