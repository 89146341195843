import React from 'react';

import { FilterTypeTag } from 'stateAndApi/searchStates';
import { SearchSuggestion } from 'types/searchTypes';

import FilterDropDown from '../filter/FilterDropDown';

import { DropDownSectionHeader, SectionContainer, SuggestionContainer } from './SearchDropDown';

type Props = {
  searchSuggestions: SearchSuggestion;
};

export const SearchDropDownTagSection = ({ searchSuggestions }: Props) => {
  return (
    <>
      {searchSuggestions.tags && searchSuggestions.tags.length !== 0 && (
        <SectionContainer>
          <DropDownSectionHeader> Tags </DropDownSectionHeader>
          <SuggestionContainer>
            {searchSuggestions.tags.map((tag: FilterTypeTag, index) => {
              return <FilterDropDown key={tag + index.toString()} filterType={tag} />;
            })}
          </SuggestionContainer>
        </SectionContainer>
      )}
    </>
  );
};
