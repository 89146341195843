import React, { useEffect, useState } from 'react';

type IntersectionObserverOptions = {
  root: Element | null;
  rootMargin: string;
  threshold: number | number[] | undefined;
};

export function useElementOnScreen<T extends Element>(
  options: IntersectionObserverOptions,
  containerRef: React.RefObject<T>
): [React.RefObject<T>, boolean] {
  const [isVisible, setIsVisible] = useState(false);

  // eslint-disable-next-line no-undef
  const callbackFunction = (entries: IntersectionObserverEntryInit[]) => {
    const [entry] = entries;
    const entryTop = entry.boundingClientRect.y;
    if (entry.isIntersecting) {
      if (entryTop && entryTop > 0) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    } else {
      if (entryTop && entryTop > 0) {
        setIsVisible(false);
      }
    }
    if (entryTop && entryTop < 0) {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);
    const current = containerRef.current;
    if (current) {
      observer.observe(current);
    }

    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, [containerRef, options]);

  return [containerRef, isVisible];
}
