import React, { ReactNode } from 'react';

import { BackButton } from 'shared/button/BackButton';
import styled from 'styled-components/macro';

type Props = {
  fallbackRoute: string;
  possibleRoutesToGoBackTo?: string[];
  children?: ReactNode;
};

export const GenericDetailHeader = ({ fallbackRoute, possibleRoutesToGoBackTo, children }: Props) => {
  return (
    <TopContainer>
      <BackButton fallbackRoute={fallbackRoute} possibleRoutesToGoBackTo={possibleRoutesToGoBackTo} />
      <TopRightButtons>{children}</TopRightButtons>
    </TopContainer>
  );
};

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const TopRightButtons = styled.div`
  display: flex;
  gap: 0.5rem;
`;
