import styled from 'styled-components/macro';

import { fadeAnimation } from '../utils/commonAnimations';
import { contentWidth, tabletBreakpoint } from '../utils/constants';

export const FormOuterContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  animation: ${fadeAnimation};
`;

export const FormInnerOuterContainer = styled.div`
  width: 100%;
  padding: var(--4) var(--2) 0;
  max-width: 720px;
  margin: var(--2) 0;
  gap: var(--1);
  @media (max-width: ${contentWidth}) {
    margin: var(--2) var(--8);
  }
  @media (max-width: ${tabletBreakpoint}) {
    margin: 0;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding-bottom: var(--4);
  text-align: left;
  gap: var(--2);

  .textFieldLabel {
    font-size: var(--14px-rem);
    margin-bottom: var(--05);
  }
  .textFieldInput {
    font-size: var(--18px-rem);
    padding: var(--05) var(--1-05);
  }
`;
