import React from 'react';

import { useRecoilValue } from 'recoil';
import Icon from 'shared/Icon';
import { employeeRecordState } from 'stateAndApi/employeeState';
import { FilterType } from 'stateAndApi/searchStates';
import styled from 'styled-components/macro';
import { EmployeeRecord } from 'types/employeeTypes';
import { tabletBreakpoint } from 'utils/constants';

import { getFilterLabel } from './filterLabels';

const Label = styled.label<{ disabled: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  font-family: var(--din-light);
  font-size: var(--16px-rem);
  opacity: ${({ disabled }) => (disabled ? '0.6' : '1.0')};
  user-select: ${({ disabled }) => (disabled ? 'none' : 'initial')};

  &:hover {
    font-weight: ${({ disabled }) => (disabled ? '400' : '600')};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }

  input {
    margin-left: var(--1px);
  }

  @media (max-width: ${tabletBreakpoint}) {
    font-size: var(--12px-rem);
    input {
      margin-right: var(--1);
    }
  }
`;

const CheckBox = styled.input<{ color: string; disabled: boolean }>`
  position: relative;
  border: var(--1px) solid var(--sort);
  width: var(--16px-rem);
  height: var(--16px-rem);
  appearance: none;
  background: inherit;
  margin-right: var(--1-05);
  margin-left: 0;
  overflow: hidden;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }

  &:after {
    content: '';
    position: absolute;
    top: calc(-1 * var(--1px));
    left: calc(-1 * var(--1px));
    width: var(--16px-rem);
    height: var(--16px-rem);
    background: ${({ color }) => color};
    transition: transform 0.2s;
    transform: scale(0);
  }

  &:checked:after {
    transform: scale(1);
  }

  @media (max-width: ${tabletBreakpoint}) {
    width: var(--12px-rem);
    height: var(--12px-rem);
  }
`;

const Checkmark = styled(Icon).attrs({ name: 'checkmark' })<{ visible: boolean }>`
  position: absolute;
  width: var(--16px-rem);
  height: var(--16px-rem);
  left: 1px;
  transition: stroke 0.2s;
  stroke: ${(props) => (props.visible ? 'var(--sort)' : 'transparent')};

  @media (max-width: ${tabletBreakpoint}) {
    width: var(--12px-rem);
    height: var(--12px-rem);
  }
`;

const Span = styled.span`
  padding-top: var(--05);
`;

export type FilterAction = 'activate' | 'deactivate';

interface FilterOptionProps {
  option: FilterType;
  isActive: boolean;
  onChange: (options: FilterType, action: FilterAction) => void;
}

export const FilterOption = ({ option, isActive, onChange }: FilterOptionProps) => {
  const employeeRecord = useRecoilValue<EmployeeRecord>(employeeRecordState);
  return (
    <Label disabled={false}>
      <CheckBox
        color="var(--contrast)"
        disabled={false}
        type="checkbox"
        checked={isActive}
        onChange={() => onChange(option, isActive ? 'deactivate' : 'activate')}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            onChange(option, isActive ? 'deactivate' : 'activate');
          }
        }}
      />
      <Checkmark visible={isActive} />
      <Span>{getFilterLabel(option, employeeRecord)}</Span>
    </Label>
  );
};
