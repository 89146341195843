import React from 'react';

import styled from 'styled-components/macro';

const LoaderContainer = styled.div<{ height?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  height: ${(props) => (props.height ? props.height : '100vh')};
`;

const Circle = styled.div<{ delay: number }>`
  width: var(--2);
  height: var(--2);
  border-radius: 50%;
  transform-origin: top center;
  animation: rotateAndScale 1s linear infinite;
  animation-delay: ${(props) => props.delay}s;

  @keyframes rotateAndScale {
    0% {
      background: var(--contrast);
      transform: rotate(0) scale(1.2);
    }
    50% {
      background: var(--primary);
      transform: rotate(180deg) scale(0.3);
    }
    100% {
      background: var(--contrast);
      transform: rotate(360deg) scale(1.2);
    }
  }
`;

type Props = {
  className?: string;
  height?: string;
};

export const Loader = ({ className, height }: Props) => (
  <LoaderContainer className={className} height={height}>
    {[...Array(5).keys()].map((n) => (
      <Circle key={n} delay={n / 10} />
    ))}
  </LoaderContainer>
);
