import styled from 'styled-components/macro';

import { tabletBreakpoint } from '../../../utils/constants';

export const CardButtonContainer = styled.div`
  display: flex;
  gap: var(--1-05);
  align-items: center;
  justify-content: right;
  transition: ease 0.2s;

  &:focus-within {
    opacity: 1;
  }

  @media (max-width: ${tabletBreakpoint}) {
    opacity: 1;
  }
`;
