import React, { createRef } from 'react';

import { CardContainer } from 'shared/card/CardContainer';
import { PostCardSearchResults } from 'shared/card/PostCard';
import { CardSize } from 'shared/card/cardSizes';
import { useElementOnScreen } from 'shared/hooks/useElementOnScreen';
import { Post } from 'types/postTypes';

export interface AddPostToSelectionFooter {
  isPostInSelection: boolean;
  handleAddPost: (post: Post) => void;
}
interface CardProps {
  post: Post;
  cardSize: CardSize;
  isPostInSelection: boolean;
  handleAddPost: (post: Post) => void;
}

const SearchResultCard = ({ post, cardSize, isPostInSelection, handleAddPost }: CardProps) => {
  const cardRef = createRef<HTMLDivElement>();
  const [containerRef, isVisible] = useElementOnScreen(
    {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    },
    cardRef
  );

  return (
    <CardContainer ref={containerRef} visible={isVisible} cardSize={cardSize}>
      <PostCardSearchResults
        post={post}
        cardSize={cardSize}
        footerForAddingPost={{ isPostInSelection: isPostInSelection, handleAddPost: handleAddPost }}
      />
    </CardContainer>
  );
};

export default SearchResultCard;
