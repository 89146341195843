import React from 'react';

import { useRecoilValue } from 'recoil';
import { useNotification } from 'shared/notification/useNotification';
import { HistoryPath, historyState, useGoToRoute } from 'stateAndApi/history';
import styled from 'styled-components/macro';

import Icon from '../Icon';

const ClickableIcon = styled.button`
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0 0 0 8px;
  margin-left: -8px;
`;

const ArrowIcon = styled(Icon).attrs({ name: 'arrow-long' })`
  width: 100%;
  height: 100%;
  transition: transform 0.2s;

  ${ClickableIcon}:hover &,
  ${ClickableIcon}:focus-visible & {
    transform: translateX(-8px);
  }
`;

type Props = {
  fallbackRoute: string;
  possibleRoutesToGoBackTo?: string[];
};

export const BackButton = ({ fallbackRoute, possibleRoutesToGoBackTo = [] }: Props) => {
  const goToRoute = useGoToRoute();
  const { clearNotification } = useNotification();
  const ourOwnHistory = useRecoilValue(historyState);
  const handleOnClick = () => {
    clearNotification();
    goToRoute(findCorrectRoute(ourOwnHistory, possibleRoutesToGoBackTo, fallbackRoute));
  };

  return (
    <ClickableIcon onClick={handleOnClick}>
      <ArrowIcon />
    </ClickableIcon>
  );
};

const findCorrectRoute = (
  history: HistoryPath[],
  possibleRoutesToGoBackTo: string[],
  fallbackRoute: string
): string => {
  if (possibleRoutesToGoBackTo.length === 0) {
    return fallbackRoute;
  }

  if (history.length === 0) {
    return fallbackRoute;
  }

  const possibleRouteToGoToFromHistory = history.filter((h) => possibleRoutesToGoBackTo.find((r) => r === h.paths[0]));

  if (possibleRouteToGoToFromHistory.length === 0) {
    return fallbackRoute;
  }

  return possibleRouteToGoToFromHistory[possibleRouteToGoToFromHistory.length - 1].fullPath;
};
