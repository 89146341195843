import {
  ScreenedUrlData,
  ScreenedUrlDataBackend,
  ScreenedUrlResponse,
  ScreenedUrlResponseBackend,
} from '../types/urlScreeningTypes';
import { authenticatedGet } from '../utils/apiUtils';
import { ApiUrls } from '../utils/urls';

export const getScreenedUrl = async (url: string): Promise<ScreenedUrlResponse> => {
  const screenedResponse = await authenticatedGet<ScreenedUrlResponseBackend>(
    ApiUrls.SCREEN_URL + '?url=' + encodeURIComponent(url)
  );
  return mapToScreenedUrlResponse(screenedResponse);
};

const mapToScreenedUrlData = (urlScreeningDataShapeBackend: ScreenedUrlDataBackend): ScreenedUrlData => ({
  thumbnailUrl: urlScreeningDataShapeBackend.thumbnail_url,
  authorName: urlScreeningDataShapeBackend.author_name,
  title: urlScreeningDataShapeBackend.title,
  description: urlScreeningDataShapeBackend.description,
  mediaType: urlScreeningDataShapeBackend.media_type,
  published: urlScreeningDataShapeBackend.published,
  url: urlScreeningDataShapeBackend.url,
  consumptionTime: urlScreeningDataShapeBackend.consumption_time,
});

const mapToScreenedUrlResponse = (screenedUrlResponseBackend: ScreenedUrlResponseBackend): ScreenedUrlResponse =>
  !screenedUrlResponseBackend.is_duplicate
    ? {
        isDuplicate: screenedUrlResponseBackend.is_duplicate,
        id: screenedUrlResponseBackend.id,
        hasMetadataFromHtml: screenedUrlResponseBackend.has_metadata_from_html,
        urlScreeningDataShape: mapToScreenedUrlData(screenedUrlResponseBackend.urlScreeningDataShape),
      }
    : {
        isDuplicate: screenedUrlResponseBackend.is_duplicate,
        id: screenedUrlResponseBackend.id,
        hasMetadataFromHtml: screenedUrlResponseBackend.has_metadata_from_html,
      };
