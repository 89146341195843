import React, { ReactNode } from 'react';

import { useRecoilValue } from 'recoil';
import styled from 'styled-components/macro';

import { useGoToRoute } from '../stateAndApi/history';
import { filterState, useSetFilters } from '../stateAndApi/searchStates';
import { RoutePaths } from '../utils/urls';

const StyledEmployeeButton = styled.button`
  padding: 0;
  background: none;
  border: none;
  font: inherit;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &:empty {
    display: none;
  }
`;

type Props = {
  value: string;
  label: string;
  className?: string;
  children?: ReactNode;
};

export const EmployeeButton = ({ value, label, className, children }: Props) => {
  const activeFilters = useRecoilValue(filterState).map((af) => af.value);
  const setFilters = useSetFilters();
  const goToRoute = useGoToRoute();

  const onClick = () => {
    if (!activeFilters.includes(value)) {
      setFilters({ action: 'ActivateFilter', filter: { key: 'Employee', value } });
    }
    goToRoute(RoutePaths.LANDING);
    window.scrollTo(0, 0);
  };

  return (
    <StyledEmployeeButton className={className} onClick={onClick}>
      {children ?? label}
    </StyledEmployeeButton>
  );
};
