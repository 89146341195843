import React from 'react';

import { useRecoilValue } from 'recoil';

import { employeeRecordState } from '../../../stateAndApi/employeeState';

import { MultiSelect } from './MultiSelect';

interface ContributorsProps {
  onChange: (contributors: string[]) => void;
  value: string[];
  focus?: boolean;
}

export const ContributorsInput = ({ value, onChange, focus }: ContributorsProps) => {
  const allEmployees = useRecoilValue(employeeRecordState);
  return (
    <MultiSelect
      focus={focus}
      label="Bidragsytere"
      onChange={onChange}
      options={allEmployees.employees.map((e) => e.name)}
      placeholder="Velg bidragsytere..."
      selected={value}
      withEmployeeImage
    />
  );
};
