import React from 'react';

import { Separator } from 'shared/Separator';
import { Contributors } from 'shared/contributor/Contributors';
import { Post } from 'types/postTypes';

import { CardHeader } from './CardHeader';
import { FormattedDate } from './FormattedDate';

type HeaderProps = {
  post: Post;
};

export const Header = ({ post }: HeaderProps) => {
  return (
    <CardHeader>
      <Contributors post={post} />
      {(post.contributorEmployees.length > 0 || post.isExternal) && <Separator />}
      <FormattedDate date={post.published} />
    </CardHeader>
  );
};
