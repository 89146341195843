import React from 'react';

import styled from 'styled-components/macro';
import { mobileBreakpoint, tabletBreakpoint } from 'utils/constants';

type Props = {
  typeOfDetailPage: string;
  title: string;
  description: string | null;
};
export const GenericTopTexts = ({ typeOfDetailPage, title, description }: Props) => {
  return (
    <>
      <TopTextContainer>{typeOfDetailPage}</TopTextContainer>
      <StyledTitle>{title}</StyledTitle>
      <Description>{description}</Description>
    </>
  );
};

const TopTextContainer = styled.div`
  font-size: var(--24px-rem);
  @media (max-width: ${tabletBreakpoint}) {
    font-size: var(--18px-rem);
  }
`;

export const StyledTitle = styled.h1`
  font-size: var(--40px-rem);
  margin: 0;

  @media (max-width: ${mobileBreakpoint}) {
    font-size: 1.5rem;
  }
`;

export const StyledSubTitle = styled.h2`
  margin: 0;

  @media (max-width: ${mobileBreakpoint}) {
    font-size: 1.5rem;
  }
`;

const Description = styled.p`
  white-space: pre-wrap;
  font-size: 1.25rem;
  margin: 0;
  @media (max-width: ${mobileBreakpoint}) {
    font-size: 1rem;
  }
`;
