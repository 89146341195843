import React, { CSSProperties, ReactNode } from 'react';

import styled from 'styled-components/macro';

import { tabletBreakpoint } from '../../../utils/constants';
import { CardSize, CardSizeStyling } from '../cardSizes';

const SIZES: CardSizeStyling = {
  small: {
    // Small cards have left-aligned text
    '--gap-cardtextcontainer': 'var(--2)',
    '--align-items-cardtextcontainer': 'flex-start',
    '--text-align-cardtextcontainer': 'left',
  },
  medium: {
    '--gap-cardtextcontainer': 'var(--4)',
    '--align-items-cardtextcontainer': 'center',
    '--text-align-cardtextcontainer': 'center',
  },
  large: {
    '--gap-cardtextcontainer': 'var(--4)',
    '--align-items-cardtextcontainer': 'center',
    '--text-align-cardtextcontainer': 'center',
  },
};

const Container = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: var(--gap-cardtextcontainer);
  text-align: var(--text-align-cardtextcontainer);

  @media (max-width: ${tabletBreakpoint}) {
    gap: var(--2);
    text-align: left;
  }
`;

interface Props {
  cardSize: CardSize;
  className?: string;
  children: ReactNode;
}

export const CardTextContainer = ({ cardSize, className, children }: Props) => {
  const styles = SIZES[cardSize] as CSSProperties;

  return (
    <Container style={styles} className={className}>
      {children}
    </Container>
  );
};
