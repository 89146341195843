import React from 'react';

import { FilterType } from 'stateAndApi/searchStates';
import styled from 'styled-components/macro';
import { tabletBreakpoint } from 'utils/constants';

import { FilterAction, FilterOption } from './FilterOption';

interface FilterOptionGroupProps {
  header: string;
  options: FilterType[];
  activeFilters: FilterType[];
  onChange: (option: FilterType, action: FilterAction) => void;
}

export function FilterOptionGroup({ header, options, onChange, activeFilters }: FilterOptionGroupProps) {
  return (
    <FilterContainer>
      <FilterOptionGroupHeader>{header}</FilterOptionGroupHeader>
      <FilterOptionSourceContainer>
        {options.map((option) => (
          <FilterOption
            key={option.key + option.value}
            option={option}
            isActive={!!activeFilters.find((activeFilters) => activeFilters.value === option.value)}
            onChange={onChange}
          />
        ))}
      </FilterOptionSourceContainer>
    </FilterContainer>
  );
}

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FilterOptionSourceContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem 1rem;
`;

const FilterOptionGroupHeader = styled.span`
  font-weight: 600;
  font-size: var(--14px-rem);
  margin-bottom: 1rem;

  @media (max-width: ${tabletBreakpoint}) {
    font-size: var(--12px-rem);
  }
`;
