export enum CardSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

/*
 *
 * CardSizeStyling is used to define the shape of an object containing css-vars for different sizes of card.
 * The CardSizeStyling type makes secures that css-variables are defined for all available sizes in CardSize. It also makes it easy to rename fields, and protects the developer from making typos.
 *
 * To change the keys in objects of type CardSizeStyling just refactor the string values for the enum CardSize.
 *
 * Use of type any for values: The more accurate type is CSSProperties. As the value contain css-variables it is not recognized without casting to the type. To save some time and space we cast the input sent to JSX, rather than casting each individual value in the CardSizeStyling object.
 * * */
export type CardSizeStyling = { [key in CardSize]: any };
