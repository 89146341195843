import { useNavigate } from 'react-router-dom';

import { atom, useSetRecoilState } from 'recoil';
import { RoutePaths } from 'utils/urls';

export const possibleRoutesToGoToFromPost = [RoutePaths.COLLECTION, RoutePaths.LANDING];
export const possibleRoutesToGoToFromEditPost = [RoutePaths.COLLECTION, RoutePaths.LANDING, RoutePaths.POST];
export const possibleRoutesToGoToFromCollection = [RoutePaths.MY_PAGE_COLLECTIONS, RoutePaths.LANDING];
export const possibleRoutesToGoToFromEditCollection = [
  RoutePaths.MY_PAGE_COLLECTIONS,
  RoutePaths.LANDING,
  RoutePaths.COLLECTION,
];
export const possibleRoutesToGoToFromCreateCollection = [
  RoutePaths.MY_PAGE_COLLECTIONS,
  RoutePaths.MY_PAGE_READING_LIST,
  RoutePaths.MY_PAGE_HISTORY,
  RoutePaths.MY_PAGE,
  RoutePaths.LANDING,
];

export const createHistoryPath = (r: string): HistoryPath => {
  if (r === RoutePaths.LANDING) {
    return { paths: [RoutePaths.LANDING], fullPath: RoutePaths.LANDING };
  }
  if (r.includes('/rediger')) {
    return { paths: [], fullPath: '' };
  }
  return {
    paths: r
      .split('/')
      .filter((p) => p !== '')
      .map((p) => `/${p}`),
    fullPath: r,
  };
};

export type HistoryPath = {
  paths: string[];
  fullPath: string;
};

export const historyState = atom<HistoryPath[]>({
  key: 'History',
  default: [createHistoryPath(window.location.pathname)],
});

export const useGoToRoute = () => {
  const navigate = useNavigate();
  const setOurOwnHistory = useSetRecoilState(historyState);

  return (r: string) => {
    setOurOwnHistory((ourOwnHistory) => [...ourOwnHistory, createHistoryPath(r)]);
    navigate(r);
  };
};
