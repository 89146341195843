import React from 'react';
import { useLocation } from 'react-router';

import { useResetRecoilState } from 'recoil';
import styled from 'styled-components/macro';
import { RoutePaths } from 'utils/urls';

import { useGoToRoute } from '../../stateAndApi/history';
import { filterState, searchState, showFilterMenu } from '../../stateAndApi/searchStates';
import { title } from '../../utils/constants';

interface SizeProps {
  size: 'sm' | 'lg';
}

const Heading = styled.h1<SizeProps>`
  margin: 0;
  font-size: ${(props) => (props.size === 'lg' ? 'var(--96px-rem)' : 'var(--40px-rem)')};

  :hover {
    cursor: pointer;
  }
`;

interface Props {
  size: 'sm' | 'lg';
}

export const Title = ({ size }: Props) => {
  const resetSearch = useResetRecoilState(searchState);
  const resetFilter = useResetRecoilState(filterState);
  const resetShowFilter = useResetRecoilState(showFilterMenu);
  const location = useLocation();
  const goToRoute = useGoToRoute();

  const onClick = () => {
    if (location.pathname !== RoutePaths.LANDING) {
      goToRoute(RoutePaths.LANDING);
    } else {
      resetSearch();
      resetFilter();
      resetShowFilter();
    }
  };
  return (
    <Heading size={size} onClick={onClick}>
      {title}
    </Heading>
  );
};
