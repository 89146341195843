import { css } from 'styled-components/macro';

export const LineClamp = (numberOfLines: number) => css`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${numberOfLines};
`;

/* If used in flexbox the direct child of the flex-box should have 'min-width: 0'
 * A set width of the element may be needed for the clamp to work. */
export const SingleLineClamp = () => css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
