import React from 'react';

import styled from 'styled-components/macro';

import { SingleLineClamp } from '../shared/LineClamp';

const LoadButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  padding: 0.5rem 1rem;
  border-radius: 32px;
  border: 1px solid black;
  font-family: var(--din-regular);
  transition: color 0.2s, background 0.2s, padding 0.2s;
  cursor: pointer;
  //box-shadow: 0 1px 8px 0 var(--overskyet-kontrast);

  &:hover {
    background: var(--primary);
    color: var(--sort);
    border: 1px solid background;
  }
`;
const LoadMoreButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
`;

const Label = styled.span`
  display: inline-block;
  margin-bottom: 2px;
  border-color: ${SingleLineClamp};
`;

const BottomText = styled.p`
  text-align: center;
  margin-top: 96px;
`;

interface Props {
  postsLoaded: number;
  postsTotal: number;
  nextPage: () => void;
}

export const LoadMoreButton = ({ postsLoaded, postsTotal, nextPage }: Props) => {
  return (
    <>
      {postsLoaded !== postsTotal ? (
        <LoadMoreButtonContainer>
          <LoadButton onClick={() => nextPage()}>
            <Label>Last inn flere innlegg</Label>
          </LoadButton>
        </LoadMoreButtonContainer>
      ) : (
        <>{postsLoaded > 15 ? <BottomText>Du har nådd bunnen av siden</BottomText> : <></>}</>
      )}
    </>
  );
};
