import React, { ReactNode } from 'react';

import styled from 'styled-components/macro';
import { fadeAnimation } from 'utils/commonAnimations';
import { mobileBreakpoint, tabletBreakpoint } from 'utils/constants';

export const Grid = styled.div`
  display: grid;
  width: 100%;
  column-gap: var(--8);
  row-gap: 5rem;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  animation: ${fadeAnimation};

  @media (max-width: ${tabletBreakpoint}) {
    column-gap: var(--2);
  }
  @media (max-width: ${mobileBreakpoint}) {
    column-gap: 0;
  }
`;

interface CardFeedProps {
  children?: ReactNode;
}

export const CardFeedGrid = ({ children }: CardFeedProps) => {
  return <Grid>{children}</Grid>;
};
