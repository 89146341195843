import React from 'react';

import { DeleteMenuItem, TrashIcon } from 'shared/moreButton/MoreButton';

import { DeleteButtonWithWarning } from './DeleteButtonWithWarning';

interface Props {
  onClickDelete: () => void;
  bodyText: string;
  warningText?: string;
}

export const DeleteMenuItemWithWarning = ({
  bodyText,
  onClickDelete,
  warningText = 'Er du sikker på at du vil fjerne dette innlegget?',
}: Props) => {
  return (
    <DeleteButtonWithWarning warningText={warningText} deleteFunction={onClickDelete}>
      <DeleteMenuItem>
        <span>{bodyText}</span>
        <TrashIcon />
      </DeleteMenuItem>
    </DeleteButtonWithWarning>
  );
};
