import { FileRequestResponse } from 'types/fileRequestTypes';
import { authenticatedDeleteV2, authenticatedFileGet, authenticatedFilePost } from 'utils/apiUtils';
import { ApiUrls } from 'utils/urls';

export const uploadFile = async (selectedFile: File): Promise<FileRequestResponse> => {
  let formData = new FormData();
  formData.append('file', selectedFile);
  return await authenticatedFilePost<FileRequestResponse>('/api/file', formData);
};

export const deleteFile = async (path: string) => {
  return await authenticatedDeleteV2('/api/file', path);
};

export const getFile = async (path: string) => {
  return await authenticatedFileGet(`${ApiUrls.GET_FILE}/${path}`);
};
