import React from 'react';

import DOMPurify from 'dompurify';
import Icon, { IconName } from 'shared/Icon';
import { InternalLink } from 'shared/InternalLink';
import styled from 'styled-components/macro';
import { SearchSuggestion } from 'types/searchTypes';
import { tabletBreakpoint } from 'utils/constants';

type Props = {
  searchSuggestions: SearchSuggestion;
  createDisplayTitle: (title: string, searchTerm: string) => string;
  query: string;
};

export const SearchDropDownContentSection = ({ searchSuggestions, createDisplayTitle, query }: Props) => {
  return (
    <>
      {searchSuggestions.content && searchSuggestions.content.length !== 0 && (
        <SuggestionContainer>
          {searchSuggestions.content.map((post) => {
            const contentUrl = mapContentTypeToContentUrl(post.type);
            const title = createDisplayTitle(post.title, query);
            const icon = post.type as IconName;
            return (
              <StyledInternalLink
                key={'suggestionLink' + post.id.toString()}
                to={contentUrl + '/' + post.id.toString()}
                draggable={false}
              >
                <ContentIcon name={icon} />
                <Suggestion dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title) }} />
              </StyledInternalLink>
            );
          })}
        </SuggestionContainer>
      )}
    </>
  );
};

const mapContentTypeToContentUrl = (contentType: string) => {
  switch (contentType) {
    case 'collection':
      return 'samling';
    case 'competence_plan':
      return 'kompetanseplan';
    // article, video, podcast, ...
    default:
      return 'innlegg';
  }
};

const SuggestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem 0;
`;

const Suggestion = styled.div`
  margin-left: 1rem;
  width: 80%;

  font-family: var(--din-light);
  font-size: var(--16px-rem);

  @media (max-width: ${tabletBreakpoint}) {
    font-size: var(--12px-rem);
    width: 90%;
    input {
      margin-right: var(--1);
    }
  }
`;

const ContentIcon = styled(Icon)`
  border: 1px solid var(--sort);
  background: var(-overskyet);
  border-radius: 0.5rem;
  opacity: 0.7;

  @media (max-width: ${tabletBreakpoint}) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const StyledInternalLink = styled(InternalLink)`
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
  word-break: break-word;

  &:hover {
    text-decoration: underline;
  }
`;
