import { useLayoutEffect } from 'react';

import { useLocalStorage } from '../shared/hooks/useLocalStorage';

export const useKeepScrollPosition = (key: string, target: any) => {
  const [scrollPositionY, setScrollPosition] = useLocalStorage(key, [key]);

  useLayoutEffect(() => {
    let ref = 0;
    if (target)
      ref = target.requestAnimationFrame(() => {
        window.scroll({ top: scrollPositionY, left: 0, behavior: 'auto' });
      });
    return () => {
      setScrollPosition(window.scrollY);
      window.cancelAnimationFrame(ref);
    };
  }, [setScrollPosition, scrollPositionY, target]);
};
