import React from 'react';

import styled from 'styled-components/macro';

import { Employee } from '../types/employeeTypes';

const EmployeeImg = styled.img`
  width: var(--3);
  height: var(--3);
  border-radius: 50%;
  background-color: black; // Makes default smiley-image from Bekk-api visible with white background
`;

interface Props {
  employee?: Employee;
  className?: string;
}

export const EmployeeImage = ({ className, employee }: Props) => {
  const imgSrc = `https://res.cloudinary.com/bekkimg/w_100,h_100,c_fill,g_face/d_default_image_departmentId${employee?.departmentId}.png/${employee?.id}`;

  return <EmployeeImg className={className} src={imgSrc} alt="Bilde av ansatt" />;
};
