import React from 'react';

import DOMPurify from 'dompurify';
import { EmployeeButton } from 'shared/EmployeeButton';
import { EmployeeImage } from 'shared/EmployeeImage';
import { ContributorContainer, EmployeeName } from 'shared/contributor/Contributor';
import { SearchSuggestion } from 'types/searchTypes';

import { DropDownSectionHeader, EmployeesContainer, SectionContainer } from './SearchDropDown';

type Props = {
  searchSuggestions: SearchSuggestion;
  createDisplayTitle: (employeeName: string, query: string) => string;
  query: string;
};

export const SearchDropDownPersonSection = ({ searchSuggestions, createDisplayTitle, query }: Props) => {
  return (
    <>
      {searchSuggestions.employees && searchSuggestions.employees.length !== 0 && (
        <SectionContainer>
          <DropDownSectionHeader> Personer </DropDownSectionHeader>
          <EmployeesContainer>
            {searchSuggestions.employees.map((employee, index) => {
              const displayName = createDisplayTitle(employee.name, query);
              return (
                <EmployeeButton value={employee.id.toString()} label={employee.name} key={employee.id + index}>
                  <ContributorContainer>
                    <EmployeeImage employee={employee} />
                    <EmployeeName dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(displayName) }}></EmployeeName>
                  </ContributorContainer>
                </EmployeeButton>
              );
            })}
          </EmployeesContainer>
        </SectionContainer>
      )}
    </>
  );
};
