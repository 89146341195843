import React, { ReactNode } from 'react';

import styled from 'styled-components/macro';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 40% auto;
  gap: var(--3);
`;

interface Props {
  children: ReactNode;
}
const CardHorizontalContentWrapper = ({ children }: Props) => {
  return <Wrapper>{children}</Wrapper>;
};

export default CardHorizontalContentWrapper;
