import React from 'react';

import Icon from 'shared/Icon';
import styled from 'styled-components/macro';

interface CardNumberOfViewsProps {
  numberOfViews?: number;
}

const CardNumberOfViews = ({ numberOfViews }: CardNumberOfViewsProps) => {
  return (
    <ViewsWrapper title="Antall bekkere som har sett innlegget">
      <Icon aria-label="Ikon av briller som representerer antall visninger" name="views" />
      <Number aria-label="Antall visninger">{numberOfViews ? numberOfViews : 0}</Number>
    </ViewsWrapper>
  );
};

export default CardNumberOfViews;

const ViewsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

const Number = styled.div`
  font-size: var(--14px-rem);
  white-space: nowrap;
  margin: 0;
  padding-top: 3px;
`;
