import React from 'react';

import Icon from 'shared/Icon';
import { useNotification } from 'shared/notification/useNotification';
import { useIsPostInReadingList } from 'stateAndApi/readingListState';
import styled from 'styled-components/macro';

import { useAddPostToReadingList, useDeletePostFromReadingList } from '../../stateAndApi/readingListApi';
import { tabletBreakpoint } from '../../utils/constants';

import { ButtonWithHover } from './ButtonWithHover';

type Props = {
  className?: string;
  postId: number;
  isInHeader?: boolean;
};

export const ReadingListButton = ({ className, postId, isInHeader = false }: Props) => {
  const isPostInReadingList = useIsPostInReadingList();
  let isInReadingList = isPostInReadingList(postId);
  const addPostToReadingList = useAddPostToReadingList();
  const deletePostFromReadingList = useDeletePostFromReadingList();
  const { notifyInfo, notifyError } = useNotification();

  const buttonText = isInReadingList ? 'Fjern fra leseliste' : 'Legg i leseliste';

  const onClick = async () => {
    try {
      if (isInReadingList) {
        await deletePostFromReadingList(postId);
        notifyInfo('Info', 'Innlegget er fjernet fra leselisten');
      } else {
        await addPostToReadingList(postId);
        notifyInfo('📹📚🤓☕️✨', 'Innlegget er lagt i leselisten');
      }
    } catch (error) {
      notifyError('Obs!', 'Innlegget kunne ikke legges i leselisten.');
    }
  };

  return isInHeader ? (
    <HeaderButton
      className={className}
      title={buttonText}
      backgroundColor={isInReadingList ? 'var(--primary)' : 'var(--hvit)'}
      hoverColor={isInReadingList ? 'var(--hvit)' : 'var(--primary)'}
      onClick={onClick}
    >
      <CoffeeIcon />
    </HeaderButton>
  ) : (
    <Button className={className} title={buttonText} isInList={isInReadingList} onClick={onClick}>
      <CoffeeIcon />
    </Button>
  );
};

const Button = styled.button<{ isInList: boolean }>`
  width: 2.5rem;
  height: 2.5rem;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  position: relative;
  isolation: isolate;

  &:hover:after,
  &:focus-visible:after {
    height: ${({ isInList }) => (isInList ? '0' : '20px')}; // this height is weird to fit the icon height
  }

  &:after {
    position: absolute;
    content: '';
    width: 15px; // this width is weird to fit the icon width
    height: ${({ isInList }) => (isInList ? '20px' : '0')}; // this height is weird to fit the icon height
    bottom: 7px;
    left: 12px;
    background: var(--primary);
    transition: 0.1s;
    z-index: -1;
  }
`;

const HeaderButton = styled(ButtonWithHover)`
  width: var(--6);
  height: var(--6);

  @media (max-width: ${tabletBreakpoint}) {
    width: var(--5);
    height: var(--5);
  }
`;

const CoffeeIcon = styled(Icon).attrs({ name: 'coffee' })`
  width: 100%;
  height: 100%;
`;
