interface AuthConfig {
  AUDIENCE: string;
  ISSUERDOMAIN: string;
  SCOPES: string;
  EMPLOYEESVCURL: string;
  EMPLOYEEPICTUREURL: string;
  CLIENT_ID: string;
  CLIENT_SECRET: string;
  BACKEND_CLIENT_ID: string;
  TENANT_ID: string;
  BEKK_API_CLIENT_ID: string;
}

type AtlasConfig = {
  ENVIRONMENT: string;
  BACKEND_URL: string;
  BACKEND_URL_V2: string;
  BEKK_HOST: string;
  SLACK_ERROR_HOOK_URL: string;
};

type ENV = AuthConfig & AtlasConfig;

declare global {
  interface Window {
    SERVER_DATA: ENV;
  }
}

const env: ENV =
  process.env.NODE_ENV === 'development'
    ? {
        ENVIRONMENT: process.env.NODE_ENV,
        BACKEND_URL: process.env.REACT_APP_BACKEND ?? 'http://localhost:8080',
        BACKEND_URL_V2: process.env.REACT_APP_BACKEND_URL_V2 ?? 'http://localhost:8081',
        BEKK_HOST: 'https://api.bekk.dev',
        AUDIENCE: 'QHQy75S7tmnhDdBGYSnszzlhMPul0fAE',
        ISSUERDOMAIN: 'bekk-dev.eu.auth0.com',
        SCOPES: 'openid name groups groups_prefixed',
        EMPLOYEESVCURL: 'https://api.bekk.dev/employee-svc',
        EMPLOYEEPICTUREURL: 'https://res.cloudinary.com/bekkimg/w_384,h_512,c_fill/d_default_image.png/',
        SLACK_ERROR_HOOK_URL: '',
        TENANT_ID: process.env.REACT_APP_AZURE_AD_TENANT_ID ?? '',
        CLIENT_ID: process.env.REACT_APP_AZURE_AD_CLIENT_ID ?? '',
        CLIENT_SECRET: process.env.REACT_APP_AZURE_AD_CLIENT_SECRET ?? '',
        BACKEND_CLIENT_ID: process.env.REACT_APP_AZURE_AD_BACKEND_CLIENT_ID ?? '',
        BEKK_API_CLIENT_ID: process.env.REACT_APP_AZURE_AD_BEKK_API_CLIENT_ID ?? '',
      }
    : (window.SERVER_DATA as ENV);

export const HOST = env.BACKEND_URL;
export const HOST_V2 = env.BACKEND_URL_V2;
export const SLACK_ERROR_HOOK_URL = env.SLACK_ERROR_HOOK_URL;
export const ENVIRONMENT = env.ENVIRONMENT;

export function getScopes(): string[] {
  const AZURE_AD_BACKEND_CLIENT_ID = process.env.REACT_APP_AZURE_AD_BACKEND_CLIENT_ID;
  return [`api://${AZURE_AD_BACKEND_CLIENT_ID}/User.Authenticate`];
}
