import React from 'react';

import { Container, Input, InputFieldProps, Label } from './inputField';

type Props = InputFieldProps & {
  value?: string;
  placeholder?: string;
  onChange: (value: string) => void;
};

const InputText = ({ className, label, value, onChange, onBlur, disabled, placeholder }: Props) => (
  <Container className={className}>
    {label && <Label>{label}</Label>}
    <Input
      type="text"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onBlur={onBlur}
      disabled={disabled}
      placeholder={placeholder}
    />
  </Container>
);

export default InputText;
