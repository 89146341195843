import React from 'react';

import Icon from 'shared/Icon';
import styled from 'styled-components/macro';
import { tabletBreakpoint } from 'utils/constants';

interface PostSearchProps {
  inputValue: string;
  onSearchValueChange(value: string): void;
}
const PostSearchDetailPage = ({ inputValue, onSearchValueChange }: PostSearchProps) => {
  const placeholder = 'Søk i Atlas';

  const onSearchValueChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearchValueChange(event.target.value);
  };

  return (
    <SearchContainer>
      <SearchIcon name="search" />
      <Input value={inputValue} onChange={onSearchValueChangeHandler} placeholder={placeholder} />
    </SearchContainer>
  );
};

export default PostSearchDetailPage;

const SearchContainer = styled.div`
  display: flex;
  gap: 1rem;
  min-width: 60%;
  border: none;
  border-bottom: 2px solid black;
  align-items: center;
`;

const Input = styled.input`
  border: none;
  width: 100%;
  font-size: var(--18px-rem);
  font-family: var(--din-light);

  ::placeholder {
    color: var(--morgendis);
  }

  :active,
  :focus {
    outline: none;
  }
`;

const SearchIcon = styled(Icon)`
  width: 2rem;
  height: 2rem;

  @media (max-width: ${tabletBreakpoint}) {
    width: 2rem;
    height: 2rem;
  }
`;
