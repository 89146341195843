import {
  CompetenceNudgeBody,
  CompetencePlanBackend,
  CompetencePlanMetadata,
  CompetencePlanPersonalBackend,
  CompetencePlanPreview,
} from 'types/competencePlanTypes';
import { ApiUrls } from 'utils/urls';

import {
  IdentifierResponse,
  authenticatedGetV2,
  authenticatedV2DeleteWithoutResponse,
  authenticatedV2PatchWithoutResponse,
  authenticatedV2PostWithResponse,
  authenticatedV2PostWithoutResponse,
  authenticatedV2PutWithoutResponse,
} from '../../utils/apiUtils';

export const getCompetencePlan = async (competencePlanId: string) =>
  await authenticatedGetV2<CompetencePlanBackend>(`${ApiUrls.COMPETENCE_PATH}/${competencePlanId}`);

export const getCompetencePlanPersonal = async (competencePlanId: string) =>
  await authenticatedGetV2<CompetencePlanPersonalBackend>(`${ApiUrls.COMPETENCE_PATH_PERSONAL}/${competencePlanId}`);

export const getCompetencePlanPreviews = async () =>
  await authenticatedGetV2<CompetencePlanPreview[]>(`${ApiUrls.COMPETENCE_PATH_PREVIEW}`);

export const getMyCompetencePlanPreviews = async () =>
  await authenticatedGetV2<CompetencePlanPreview[]>(`${ApiUrls.COMPETENCE_PATH_PREVIEW_MY}`);

export const deleteCompetencePlan = async (competencePlanId: number) => {
  return await authenticatedV2DeleteWithoutResponse(`${ApiUrls.COMPETENCE_PATH}/${competencePlanId}`, {});
};

export const removeCompetencePostFromCompetencePlan = async (competencePlanId: number, postId: number) => {
  return await authenticatedV2DeleteWithoutResponse(
    `${ApiUrls.COMPETENCE_PATH}/${competencePlanId}/remove/${postId}`,
    {}
  );
};

export const updateCheckMark = async (competencePlanId: number, postId: number) =>
  await authenticatedV2PatchWithoutResponse(`${ApiUrls.COMPETENCE_PATH}/${competencePlanId}/post/${postId}`, {});

export const addPostToCompetencePlan = async (competencePlanId: number, postId: number) =>
  await authenticatedV2PostWithoutResponse(`${ApiUrls.COMPETENCE_PATH}/${competencePlanId}/add/${postId}`, {});

export const editCompetencePlan = async (updatedCompetencePlan: CompetencePlanMetadata) => {
  await authenticatedV2PutWithoutResponse(
    `${ApiUrls.COMPETENCE_PATH}/${updatedCompetencePlan.id}`,
    updatedCompetencePlan
  );
};

export const createCompetencePlan = async (competencePlan: CompetencePlanMetadata): Promise<IdentifierResponse> => {
  return await authenticatedV2PostWithResponse<CompetencePlanMetadata, IdentifierResponse>(
    ApiUrls.COMPETENCE_PATH,
    competencePlan
  );
};

export const signUpToCompetencePlan = async (competencePlanId: number) => {
  await authenticatedV2PostWithoutResponse(`${ApiUrls.COMPETENCE_SIGNUP}/${competencePlanId}`, {});
};

export const subscribeToNudge = async (competencePlanId: number) => {
  return await authenticatedV2PostWithoutResponse<CompetenceNudgeBody>(ApiUrls.COMPETENCE_PLAN_NUDGE, {
    competence_plan_id: competencePlanId,
    schedule_type: 'Tuesday',
  });
};

export const unsubscribeToNudge = async (competencePlanId: number) => {
  return await authenticatedV2DeleteWithoutResponse<{}>(
    `${ApiUrls.COMPETENCE_PLAN_NUDGE}/remove/${competencePlanId}`,
    {}
  );
};
