import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { CollectionPostSortDialog, CollectionSortMode } from 'collection/detailCollection/CollectionPostSort';
import { CollectionSearchField } from 'collection/detailCollection/CollectionSearch';
import { sanitizeTitleForUrlUsage } from 'collection/utils/utils';
import { GenericDetailHeader } from 'genericDetail/GenericDetailHeader';
import { GenericDetailPage } from 'genericDetail/GenericDetailPage';
import { GenericMetaData } from 'genericDetail/GenericMetaData';
import { GenericPostsContainer } from 'genericDetail/GenericPostsContainer';
import { GenericTopTexts } from 'genericDetail/GenericTopTexts';
import { mapFromMetadataToPost } from 'post/utils/postUtils';
import { useRecoilValue } from 'recoil';
import { EditButton } from 'shared/button/EditButton';
import { PostCardWithDeleteFromCollection } from 'shared/card/PostCard';
import { CollectionContributors } from 'shared/contributor/CollectionContributors';
import { ContributorWithDepartment } from 'shared/contributor/ContributorWithExtraText';
import { useSingleEmployee } from 'stateAndApi/employeeApi';
import { employeeRecordState } from 'stateAndApi/employeeState';
import { possibleRoutesToGoToFromCollection, useGoToRoute } from 'stateAndApi/history';
import styled from 'styled-components/macro';
import { CollectionBackend, CollectionMetadata, CollectionPost } from 'types/collectionTypes';
import { Employee } from 'types/employeeTypes';
import { mobileBreakpoint, mobileBreakpointValue } from 'utils/constants';
import { RoutePaths } from 'utils/urls';
import { useWindowSize } from 'utils/useWindowSize';

import { ScrollButton } from '../../post/detailPages/content/postHeaderButtons/ScrollButton';
import { Post } from '../../types/postTypes';
import { AddContentToCollection } from '../addContentToCollection/AddContentToCollection';

interface Props {
  collection: CollectionBackend;
  setCollection: (collection: CollectionBackend) => void;
  deletePostFromCollection: (postId: number) => void;
}

const DetailCollection = ({ collection, setCollection, deletePostFromCollection }: Props) => {
  const employee = useSingleEmployee(collection.metadata.author_id);
  const addToCollectionRef = useRef<HTMLDivElement>(null);

  const { metadata, posts } = collection;
  const { description, title } = metadata;

  return (
    <GenericDetailPage
      Helmet={
        <Helmet>
          <title>Atlas - {title} </title>
        </Helmet>
      }
    >
      <CollectionHeader
        possibleRoutesToGoBackTo={possibleRoutesToGoToFromCollection}
        addToCollectionRef={addToCollectionRef}
        collection={metadata}
      />
      <CollectionTopText title={title} description={description} />
      {employee && <CollectionMetaData employee={employee} posts={posts} />}
      <CollectionPosts collection={collection} deletePostFromCollection={deletePostFromCollection} />
      <AddContentToCollection collection={collection} setCollection={setCollection} ref={addToCollectionRef} />
    </GenericDetailPage>
  );
};

const CollectionHeader = ({
  possibleRoutesToGoBackTo,
  addToCollectionRef,
  collection,
}: {
  possibleRoutesToGoBackTo: string[];
  addToCollectionRef: React.RefObject<HTMLDivElement>;
  collection: CollectionMetadata;
}) => {
  const goToRoute = useGoToRoute();

  return (
    <GenericDetailHeader fallbackRoute={RoutePaths.LANDING} possibleRoutesToGoBackTo={possibleRoutesToGoBackTo}>
      <ScrollButton
        tooltip="Legg til innlegg"
        onClick={() => addToCollectionRef.current?.scrollIntoView({ behavior: 'smooth' })}
      />
      <EditButton
        tooltip="Rediger samling"
        onClick={() =>
          goToRoute(`${RoutePaths.COLLECTION}/${collection.id}/${sanitizeTitleForUrlUsage(collection.title)}/rediger`)
        }
      />
    </GenericDetailHeader>
  );
};

const CollectionTopText = ({ title, description }: { title: string; description: string | null }) => {
  return <GenericTopTexts typeOfDetailPage={'Samlinger'} title={title} description={description} />;
};

const CollectionMetaData = ({ employee, posts }: { employee: Employee; posts: CollectionPost[] }) => {
  return (
    <GenericMetaData>
      <ContributorWithDepartment employee={employee} />

      <CollectionContributorsAndNumberOfPostsContainer>
        <NumberOfPostsInfo>{posts.length} innlegg</NumberOfPostsInfo>
        <CollectionContributors
          addedByEmployeeIds={posts.map((post) => post.addedBy)}
          withText={true}
          isDetailPage={true}
        />
      </CollectionContributorsAndNumberOfPostsContainer>
    </GenericMetaData>
  );
};

const CollectionSearchAndSort = ({
  setSearch,
  sortWord,
  setSortWord,
}: {
  setSortWord: (e: CollectionSortMode) => void;
  setSearch: (e: string) => void;
  sortWord: string;
}) => {
  return (
    <CollectionSearchAndSortContainer>
      <CollectionSearchField setSearch={setSearch} />
      <CollectionPostSortDialog sortWord={sortWord} setSortWord={setSortWord} />
    </CollectionSearchAndSortContainer>
  );
};

const CollectionPosts = ({
  collection,
  deletePostFromCollection,
}: {
  collection: CollectionBackend;
  deletePostFromCollection: (postId: number) => void;
}) => {
  const employeeRecord = useRecoilValue(employeeRecordState);
  const [search, setSearch] = useState('');
  const [sortWord, setSortWord] = useState<CollectionSortMode>('newlyAdded');
  const [vertical] = useWindowSize();

  const sortCollectionPosts = (sortingWord: CollectionSortMode, posts: Post[]) => {
    const copyPosts = [...posts];
    switch (sortingWord) {
      case 'newlyAdded':
        return copyPosts;
      case 'uploadDate':
        return copyPosts.sort((a, b) => {
          const aPublished = !!a.published ? a.published : '';
          const bPublished = !!b.published ? b.published : '';
          return aPublished.localeCompare(bPublished);
        });
      case 'title':
        return copyPosts.sort((a, b) => a.title.localeCompare(b.title));
      case 'consumptionTime':
        return copyPosts.sort((a, b) => {
          const getConsumptionTime = (post: Post) => post.consumptionTime ?? 0;
          return getConsumptionTime(a) - getConsumptionTime(b);
        });
      default:
        return copyPosts;
    }
  };

  const searchCollectionPosts = (posts: CollectionPost[], search: string): Post[] => {
    if (posts.length < 1) return [];

    const query = search.toLowerCase();
    if (query === '') return posts.map((p) => mapFromMetadataToPost(p.post, employeeRecord));

    const filteredPosts = posts.filter((p) => {
      if (p.post.title.toLowerCase().includes(query)) {
        return true;
      } else if (p.post.description.toLowerCase().includes(query)) {
        return true;
      } else if (p.post.tags.some((tag) => tag.toLowerCase().includes(query))) {
        return true;
      } else if (
        p.post.contributors
          .map(employeeRecord.getEmployee)
          .some((employee) => (employee as Employee).name.toLowerCase().includes(query))
      ) {
        return true;
      }
      return false;
    });
    return filteredPosts.map((p) => mapFromMetadataToPost(p.post, employeeRecord));
  };

  return (
    <GenericPostsContainer
      numberOfPosts={collection.posts.length}
      topComponent={<CollectionSearchAndSort setSortWord={setSortWord} setSearch={setSearch} sortWord={sortWord} />}
    >
      {/* TODO: her gjøres det unødvendig mange kall */}
      {searchCollectionPosts(collection.posts, search).length > 0 ? (
        sortCollectionPosts(sortWord, searchCollectionPosts(collection.posts, search)).map((post) => (
          <PostCardWithDeleteFromCollection
            key={post.id}
            post={post}
            collection={collection}
            deletePostFromCollection={deletePostFromCollection}
            vertical={vertical < mobileBreakpointValue}
          />
        ))
      ) : (
        <NoResults>{`Fant ingen resultater for "${search}"`}</NoResults>
      )}
    </GenericPostsContainer>
  );
};

const NumberOfPostsInfo = styled.span`
  font-family: var(--din-regular);
  font-size: var(--16px-rem);
  font-weight: bold;
`;

const CollectionSearchAndSortContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin: 0 0 1.5rem;

  @media (max-width: ${mobileBreakpoint}) {
    margin: 1rem 0 1.5rem;
  }
`;

const NoResults = styled.p`
  text-align: center;
`;

const CollectionContributorsAndNumberOfPostsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  width: 100%;
`;

export default DetailCollection;
