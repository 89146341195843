import { css } from 'styled-components/macro';

//Alle farger som heter lysere er 60% lysere enn orginalfarge

export const bekkColors = css`
  --sort: #0e0e0e;
  --hvit: #ffffff;
  --overskyet: #e7e7e7;
  --overskyet-kontrast: #cecece;
  --overskyet-lysere: #f5f5f5;
  --soloppgang: #ffb88d;
  --soloppgang-kontrast: #ff8034;
  --soloppgang-lysere: #ffe3d1;
  --skyfritt: #b1e8ff;
  --skyfritt-kontrast: #43cbff;
  --skyfritt-lysere: #e0f6ff;
  --solnedgang: #ff9999;
  --solnedgang-kontrast: #ff5b5b;
  --solnedgang-lysere: #ffd6d6;
  --kveld: #e5b1ff;
  --kveld-kontrast: #8e24c9;
  --kveld-lysere: #f5e0ff;
  --natt: #6d7abb;
  --natt-kontrast: #162365;
  --natt-lysere: #c5cae4;
  --regn: #bccedd;
  --regn-kontrast: #7e9cb9;
  --regn-lysere: #e4ebf1;
  --sol: #fff2ad;
  --sol-kontrast: #fff02b;
  --sol-lysere: #fffade;
  --grønn: #a1f5e3;
  --grønn-kontrast: #16dbc4;
  --grønn-lysere: #d9fbf4;
  --morgendis: #707070;
`;

const colors = {
  sort: '#0e0e0e',
  hvit: '#ffffff',
  overskyet: '#e7e7e7',
  overskyetKontrast: '#cecece',
  overskyetLysere: '#f5f5f5',
  soloppgang: '#ffb88d',
  soloppgangKontrast: '#ff8034',
  soloppgangLysere: '#ffe3d1',
  skyfritt: '#b1e8ff',
  skyfrittKontrast: '#43cbff',
  skyfrittLysere: '#e0f6ff',
  solnedgang: '#ff9999',
  solnedgangKontrast: '#ff5b5b',
  solnedgangLysere: '#ffd6d6',
  kveld: '#e5b1ff',
  kveldKontrast: '#8e24c9',
  kveldLysere: '#f5e0ff',
  natt: '#6d7abb',
  nattKontrast: '#162365',
  nattLysere: '#c5cae4',
  regn: '#bccedd',
  regnKontrast: '#7e9cb9',
  regnLysere: '#e4ebf1',
  sol: '#fff2ad',
  solKontrast: '#fff02b',
  solLysere: '#fffade',
  grønn: '#a1f5e3',
  grønnKontrast: '#16dbc4',
  grønnLysere: '#d9fbf4',
};

export type Theme = {
  from: string;
  to: string;
  primary: string;
  contrast: string;
  lighterPrimary: string;
  previousPrimary: string;
  previousContrast: string;
  previousLighterPrimary: string;
};

export const themes: Theme[] = [
  {
    from: '00:00',
    to: '05:59',
    primary: colors.natt,
    contrast: colors.nattKontrast,
    lighterPrimary: colors.nattLysere,
    previousPrimary: colors.kveld,
    previousContrast: colors.kveldKontrast,
    previousLighterPrimary: colors.kveldLysere,
  },
  {
    from: '06:00',
    to: '11:59',
    primary: colors.soloppgang,
    contrast: colors.soloppgangKontrast,
    lighterPrimary: colors.soloppgangLysere,
    previousPrimary: colors.natt,
    previousContrast: colors.nattKontrast,
    previousLighterPrimary: colors.nattLysere,
  },
  {
    from: '12:00',
    to: '17:59',
    primary: colors.skyfritt,
    contrast: colors.skyfrittKontrast,
    lighterPrimary: colors.skyfrittLysere,
    previousPrimary: colors.soloppgang,
    previousContrast: colors.soloppgangKontrast,
    previousLighterPrimary: colors.soloppgangLysere,
  },
  {
    from: '18:00',
    to: '21:59',
    primary: colors.solnedgang,
    contrast: colors.solnedgangKontrast,
    lighterPrimary: colors.solnedgangLysere,
    previousPrimary: colors.skyfritt,
    previousContrast: colors.skyfrittKontrast,
    previousLighterPrimary: colors.skyfrittLysere,
  },
  {
    from: '22:00',
    to: '23:59',
    primary: colors.kveld,
    contrast: colors.kveldKontrast,
    lighterPrimary: colors.kveldLysere,
    previousPrimary: colors.solnedgang,
    previousContrast: colors.solnedgangKontrast,
    previousLighterPrimary: colors.solnedgangLysere,
  },
];
