import React, { CSSProperties, ReactNode } from 'react';

import styled from 'styled-components/macro';

import { CardSize, CardSizeStyling } from '../cardSizes';

const SIZES: CardSizeStyling = {
  small: {
    '--transition-cardfooter': '0',
  },
  medium: {
    '--transition-cardfooter': 'var(--2)',
  },
  large: {
    '--transition-cardfooter': 'var(--2)',
  },
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  font-size: var(--16px-rem);
`;

interface Props {
  cardSize: CardSize;
  className?: string;
  children: ReactNode;
}

export const CardFooter = ({ cardSize, className, children }: Props) => {
  const styles = SIZES[cardSize] as CSSProperties;

  return (
    <Container style={styles} className={className}>
      {children}
    </Container>
  );
};
