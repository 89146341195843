import React, { useState } from 'react';

import styled from 'styled-components/macro';

import { tabletBreakpoint } from '../../utils/constants';
import { Image } from '../ImageComponent';

import InputText from './InputText';

const ErrorMessage = styled.div`
  width: 100%;
  font-size: var(--18px-rem);
  aspect-ratio: 16/9;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--solnedgang-kontrast);
  box-shadow: 0 0 0 2px var(--solnedgang) inset;

  @media (max-width: ${tabletBreakpoint}) {
    height: 190px;
  }
`;

interface Props {
  value: string | null;
  showPreviewDefault?: boolean;
  onChange(thumbnailUrl: string): void;
  setImgHasError(hasError: boolean): void;
}

export const ImageInput = ({ value, showPreviewDefault, onChange, setImgHasError }: Props) => {
  const [showPreview, setShowPreview] = useState(showPreviewDefault && !!value);

  const onError = () => {
    setShowPreview(false);
    setImgHasError(true);
  };

  const doOnChange = (url: string) => {
    onChange(url);
    setImgHasError(false);
    if (!url) {
      setShowPreview(false);
    } else {
      setShowPreview(true);
    }
  };

  const errorText = !value ? null : <ErrorMessage>Bilde er ikke tilgjengelig.</ErrorMessage>;

  return (
    <>
      <InputText onChange={doOnChange} label="Bilde" value={value || ''} placeholder="www.example.com" />
      {showPreview && value ? <Image src={value} onError={onError} alt="Bilde hentet" /> : errorText}
    </>
  );
};
