import { createContext } from 'react';

type NotificationTypes = 'INFO' | 'WARNING' | 'ERROR';

interface NotificationValue {
  type: NotificationTypes;
  title: string;
  message: string;
  undoText?: string;
  deletedCollectionId?: number;
}

export type NotificationContextValue = NotificationValue | undefined;

interface NotificationContextTypeParameter {
  setNotification: (notification: NotificationContextValue) => any;
  notification: NotificationContextValue;
}

const NotificationContext = createContext<NotificationContextTypeParameter>({
  setNotification: () => {},
  notification: undefined,
});

export default NotificationContext;
