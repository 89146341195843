import React, { FormEvent, useState } from 'react';

import styled from 'styled-components/macro';
import { tabletBreakpoint } from 'utils/constants';

import { TextFieldLabel } from '../../../post/editPost/UrlInput';
import Button from '../../button/Button';
import InputText from '../InputText';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--1);
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  gap: var(--1);
`;

const EditContainer = styled.div`
  margin-bottom: 0;
  display: flex;
  gap: var(--1);
  @media (max-width: ${tabletBreakpoint}) {
    flex-direction: column;
    align-items: end;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const Image = styled.img`
  width: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;

  @media (max-width: ${tabletBreakpoint}) {
    opacity: 1;
    filter: blur(3px) brightness(50%);
  }

  ${ImageContainer}:hover & {
    opacity: 1;
    filter: blur(3px) brightness(50%);
  }
`;

const ImageText = styled.div`
  opacity: 0;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--din-regular);
  font-size: var(--36px-rem);
  color: #fff;
  cursor: pointer;

  @media (max-width: ${tabletBreakpoint}) {
    opacity: 1;
  }

  ${ImageContainer}:hover & {
    opacity: 1;
  }
`;

const Input = styled(InputText)`
  input {
    height: 100%;
  }
`;

const StyledButton = styled(Button)`
  @media (max-width: ${tabletBreakpoint}) {
    width: fit-content;
  }
`;

const ErrorMessage = styled.div`
  width: 100%;
  font-size: var(--18px-rem);
  aspect-ratio: 16/9;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--solnedgang-kontrast);
  box-shadow: 0 0 0 2px var(--solnedgang) inset;

  @media (max-width: ${tabletBreakpoint}) {
    height: 190px;
  }
`;

interface Props {
  resetThumbnail: (event: FormEvent) => void;
  editedThumbnail: string;
  setEditedThumbnail: (newThumbnail: string) => void;
  showThumbnail: boolean;
  setShowThumbnail: (b: boolean) => void;
}

export const ImageSelect = ({
  resetThumbnail,
  editedThumbnail,
  setEditedThumbnail,
  showThumbnail,
  setShowThumbnail,
}: Props) => {
  const [showEditForm, setShowEditForm] = useState(false);

  const startEdit = (event: FormEvent) => {
    event.preventDefault();
    setShowEditForm(!showEditForm);
  };

  const onImageError = () => {
    setShowThumbnail(false);
    setShowEditForm(true);
  };

  const resetImage = async (event: FormEvent) => {
    resetThumbnail(event);
    setShowThumbnail(true);
    setShowEditForm(false);
  };

  return (
    <Container>
      <TextFieldLabel>Bilde</TextFieldLabel>

      <SubContainer>
        {showThumbnail ? (
          <ImageContainer onClick={startEdit}>
            <Image src={editedThumbnail} alt="" onError={onImageError} />
            <ImageText>{showEditForm ? 'Ferdig endret' : 'Endre bilde'}</ImageText>
          </ImageContainer>
        ) : (
          <ErrorMessage>Bilde er ikke tilgjengelig.</ErrorMessage>
        )}
      </SubContainer>

      {showEditForm && (
        <EditContainer>
          <Input
            value={editedThumbnail}
            onChange={(s) => {
              setEditedThumbnail(s);
              setShowThumbnail(true);
            }}
          />
          {showEditForm && (
            <StyledButton variant="white" size="medium" onClick={resetImage}>
              Tilbakestill
            </StyledButton>
          )}
        </EditContainer>
      )}
    </Container>
  );
};
