import React from 'react';

import { useRecoilValue } from 'recoil';
import styled from 'styled-components/macro';
import { tabletBreakpoint } from 'utils/constants';

import Icon from '../../../shared/Icon';
import { employeeRecordState } from '../../../stateAndApi/employeeState';
import { FilterType, getActiveFiltersAndSearchWord, useSetFilters } from '../../../stateAndApi/searchStates';
import { EmployeeRecord } from '../../../types/employeeTypes';

import { getFilterLabel } from './filterLabels';

interface Props {
  filterType: FilterType;
}

const FilterDropDown = ({ filterType }: Props) => {
  const setFilters = useSetFilters();
  const { activeFilters } = useRecoilValue(getActiveFiltersAndSearchWord);
  const employeeRecord = useRecoilValue<EmployeeRecord>(employeeRecordState);
  const iconName = filterTypeIconName(filterType);

  const handleClick = (f: FilterType, event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setFilters({ action: 'ActivateFilter', filter: f });
  };

  const isActiveFilter = activeFilters.some((filter) => filterType.value === filter.value);

  return (
    <Container>
      <StyledButton onClick={(event) => handleClick(filterType, event)} disabled={isActiveFilter}>
        {iconName && <FilterTypeIcon name={iconName} />}
        <FilterTypeText isDisabled={isActiveFilter}>{getFilterLabel(filterType, employeeRecord)}</FilterTypeText>
      </StyledButton>
    </Container>
  );
};

export default FilterDropDown;

const Container = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;

  @media (max-width: ${tabletBreakpoint}) {
    gap: 0.2rem;
  }
`;

const FilterTypeText = styled.div<{ isDisabled: boolean }>`
  line-height: initial;
  color: rgba(0, 0, 0, 0.7);
  opacity: ${(props) => (props.isDisabled ? `70%` : 'none')};
  white-space: nowrap;

  @media (max-width: ${tabletBreakpoint}) {
    font-size: 12px;
  }
`;

const FilterTypeIcon = styled(Icon)`
  stroke: rgba(25, 25, 25, 0.5);
  width: 1.5rem;

  @media (max-width: ${tabletBreakpoint}) {
    width: 0.8rem;
  }
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background: none;
  gap: 0.1rem;
  padding: 0 10px 0 5px;
  background: var(--hvit);
  border-radius: 8px;

  &:hover,
  &:focus-visible {
    cursor: pointer;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
  }

  &:disabled {
    pointer-events: none;
    box-shadow: none;
    background: rgba(0, 0, 0, 0.2);
  }

  @media (max-width: ${tabletBreakpoint}) {
  }
`;

const filterTypeIconName = (filterType: FilterType) => {
  if (filterType.key === 'Tag') return 'tag';
  if (filterType.key === 'Media') {
    if (filterType.value === 'other') return 'squares';
    else return filterType.value;
  }
  if (filterType.key === 'External') {
    if (filterType.value === 'external') return 'globe';
    else return 'bekk';
  }
  if (filterType.key === 'Employee') return 'globe';
};
