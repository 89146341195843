import React, { CSSProperties } from 'react';

import styled from 'styled-components/macro';

import { mobileBreakpoint } from '../../../utils/constants';
import { LineClamp } from '../../LineClamp';
import { CardSize, CardSizeStyling } from '../cardSizes';

const SIZES: CardSizeStyling = {
  small: {
    '--font-size-carddescription': 'var(--18px-rem)',
  },
  medium: {
    '--font-size-carddescription': 'var(--20px-rem)',
  },
  large: {
    '--font-size-carddescription': 'var(--24px-rem)',
  },
};

const Description = styled.p<{ lineClamp: number }>`
  ${(props) => LineClamp(props.lineClamp)};
  text-align: left;

  margin: 0;
  font-size: var(--font-size-carddescription);

  @media (max-width: ${mobileBreakpoint}) {
    font-size: var(--20px-rem);
  }
`;

interface Props {
  hidden?: boolean;
  cardSize: CardSize;
  className?: string;
  lineClamp?: number;
  children: string | null;
}

export const CardDescription = ({ hidden = false, cardSize, className, lineClamp = 3, children }: Props) => {
  const styles = SIZES[cardSize] as CSSProperties;

  if (hidden || !children) {
    return null;
  }

  return (
    <Description style={styles} className={className} lineClamp={lineClamp}>
      {children}
    </Description>
  );
};
