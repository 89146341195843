import React, { ReactNode } from 'react';

import styled from 'styled-components/macro';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: var(--3);
  align-items: center;
  font-size: var(--14px-rem);
  margin-bottom: 0.2rem;
`;

interface Props {
  className?: string;
  children: ReactNode;
}

export const CardHeader = ({ className, children }: Props) => (
  <HeaderContainer className={className}>{children}</HeaderContainer>
);
