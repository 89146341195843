import React, { FormEvent, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { Form, FormInnerOuterContainer, FormOuterContainer } from 'shared/FormComponents';
import { HeaderContainer } from 'shared/HeaderContainer';
import { BackButton } from 'shared/button/BackButton';
import Button from 'shared/button/Button';
import { DeleteButtonWithWarning } from 'shared/button/DeleteButtonWithWarning';
import { ImageInput } from 'shared/input/ImageInput';
import InputText from 'shared/input/InputText';
import { SummaryInput } from 'shared/input/SummaryInput';
import { useNotification } from 'shared/notification/useNotification';
import { useGoToRoute } from 'stateAndApi/history';
import styled from 'styled-components/macro';
import { CompetencePlanMetadata } from 'types/competencePlanTypes';
import { mobileBreakpoint } from 'utils/constants';
import { preventDefaultBehaviourEnter } from 'utils/eventListeners';
import { RoutePaths } from 'utils/urls';
import { Warning } from 'utils/utils';

import { deleteCompetencePlan, editCompetencePlan } from './utils/competenceApi';
import { validateCompetencePlan } from './utils/validate';

interface Props {
  competencePlanMetadata: CompetencePlanMetadata;
  setCompetencePlanMetadata: (competencePlanMetadata: CompetencePlanMetadata) => void;
}

const EditCompetencePlan = ({ competencePlanMetadata, setCompetencePlanMetadata }: Props) => {
  const [imgHasError, setImgHasError] = useState(false);

  const { notifyInfo, notifyError, clearNotification } = useNotification();
  const goToRoute = useGoToRoute();
  const { title, description, thumbnail_url, id } = competencePlanMetadata;

  const handleCancelEditing = () => {
    clearNotification();
    goToRoute(`${RoutePaths.COMPETENCE_PLAN}/${id}`);
  };

  const handleSuccessfulEdit = () => {
    clearNotification();
    goToRoute(`${RoutePaths.COMPETENCE_PLAN}/${id}`);
  };

  const onClickSaveChanges = async (event: FormEvent) => {
    event.preventDefault();

    try {
      validateCompetencePlan(title, imgHasError);
      await editCompetencePlan(competencePlanMetadata);
      handleSuccessfulEdit();
    } catch (e) {
      if (e instanceof Warning) {
        notifyError('Oisann', e.message);
      } else {
        notifyError('Error', e instanceof Error ? e.message : 'Klarte ikke endre kompetanseplanen');
      }
    }
  };

  const deleteThisCompetencePlan = async () => {
    try {
      await deleteCompetencePlan(id!);
      goToRoute(RoutePaths.MY_PAGE_COMPETENCE_PLAN);

      //TODO: Legge til UndoButton for å kunne angre sletting slik som i Collection
      notifyInfo('Info', 'Kompetanseplanen ble slettet', 7000);
    } catch (e) {
      notifyError('Error', e instanceof Error ? e.message : 'Klarte ikke å slette kompetanseplan');
    }
  };

  return (
    <FormOuterContainer>
      <Helmet>
        <title>Atlas - {title} </title>
      </Helmet>
      <FormInnerOuterContainer>
        <BackButton fallbackRoute={`${RoutePaths.COMPETENCE_PLAN}/${id}`} />
        <HeaderContainer>
          <h1>Rediger kompetanseplanen</h1>
        </HeaderContainer>
        <Form onKeyDown={preventDefaultBehaviourEnter}>
          <InputFieldsContainer>
            <InputText
              label="Tittel (obligatorisk)"
              value={title}
              onChange={(title) => setCompetencePlanMetadata({ ...competencePlanMetadata, title: title })}
            />
            <SummaryInput
              label="Beskrivelse"
              value={description}
              onChange={(description) =>
                setCompetencePlanMetadata({ ...competencePlanMetadata, description: description })
              }
            />
            <ImageInput
              value={thumbnail_url}
              onChange={(thumbnailUrl) =>
                setCompetencePlanMetadata({ ...competencePlanMetadata, thumbnail_url: thumbnailUrl })
              }
              setImgHasError={setImgHasError}
              showPreviewDefault
            />
          </InputFieldsContainer>
          <BottomButtonsContainer>
            <DeleteButtonWithWarning
              warningText={`Er du sikker på at du vil slette kompetanseplanen med tittel: ${competencePlanMetadata.title} ?`}
              deleteFunction={deleteThisCompetencePlan}
            />

            <EditAndSubmitButtonContainer>
              <Button onClick={handleCancelEditing} variant="white">
                Avbryt
              </Button>
              <Button onClick={onClickSaveChanges} variant="primary">
                <SaveButtonText>
                  Lagre<span> endringer</span>
                </SaveButtonText>
              </Button>
            </EditAndSubmitButtonContainer>
          </BottomButtonsContainer>
        </Form>
      </FormInnerOuterContainer>
    </FormOuterContainer>
  );
};

export default EditCompetencePlan;

const InputFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--4);
`;

const BottomButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-height: var(--48px-rem);
  margin-top: var(--3);
`;

const SaveButtonText = styled.span`
  font-size: var(--18px-rem);
  display: block;
  @media (max-width: ${mobileBreakpoint}) {
    span {
      display: none;
    }
  }
`;

const EditAndSubmitButtonContainer = styled.div`
  display: flex;
  gap: var(--2);
  height: 100%;
  max-height: var(--48px-rem);
`;
