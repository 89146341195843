import React from 'react';

import MediaTime from 'shared/MediaTime';
import styled from 'styled-components/macro';

import { ImageComponent } from '../../ImageComponent';

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const Image = styled(ImageComponent)`
  aspect-ratio: 16/9;
  object-fit: cover;
`;

const MediaTypeContainer = styled.div`
  display: flex;
  background-color: black; //#80808080;
  position: absolute;
  border-radius: 5px;
  padding: 0 0.3rem 0 0.3rem;
  margin: 0.5rem;
  color: white;
  font-family: var(--din-regular);
  bottom: 0;
  right: 0;
`;

type Props = {
  className?: string;
  src: string;
  alt: string;
  consumptionTime: number | null;
};

export const CardImage = ({ className, src, alt, consumptionTime }: Props) => (
  <Container className={className}>
    <MediaTypeContainer>
      {consumptionTime ? <MediaTime consumptionTime={consumptionTime} shortForm={true} /> : null}
    </MediaTypeContainer>
    <Image src={src} alt={alt} />
  </Container>
);
