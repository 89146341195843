import React from 'react';

import { Tab } from 'landing/Landing';
import { GenericHeader } from 'landing/header/GenericHeader';
import styled from 'styled-components/macro';
import { contentWidth } from 'utils/constants';

import { Search } from './Search';

type Props = {
  tab: Tab;
};

export const LandingHeader = ({ tab }: Props) => {
  return (
    <GenericHeader>
      {tab === Tab.Kompetanseplan && (
        <StyledDiv>
          <p>
            <b>Velkommen til Kompetanseplaner 🎉 🌱 </b>
          </p>
          Her finner du et knippe kompetanseplaner som bekkere har laget for fellesskapet 🥳 Kompetanseplaner er ganske
          nytt her i Atlas, derfor er det ikke laget så mange enda. Ønsker du å sette sammen en kompetanseplan?
          Fantastisk 🤩 Gå til Min side og Mine kompetanseplaner for å lage en.
        </StyledDiv>
      )}
      <Search tab={tab} />
    </GenericHeader>
  );
};

const StyledDiv = styled.div`
  max-width: ${contentWidth};
  margin-bottom: 1rem;

  @media (max-width: 1360px) {
    width: 80vw;
  }
`;
