import React, { useState } from 'react';
import Select, { ActionMeta, OnChangeValue } from 'react-select';

import { useRecoilValue } from 'recoil';
import styled from 'styled-components/macro';
import { contentWidth, tabletBreakpoint } from 'utils/constants';

import { TextFieldLabel } from '../../../post/editPost/UrlInput';
import { employeeRecordState } from '../../../stateAndApi/employeeState';
import { EmployeeImage } from '../../EmployeeImage';
import Icon from '../../Icon';

interface MultiSelectProps<T> {
  onChange: (options: T[]) => void;
  options: T[];
  label: string;
  selected?: T[];
  placeholder?: string;
  withEmployeeImage?: boolean;
  focus?: boolean;
  vertical?: boolean;
}

interface MultiSelectObject<T> {
  label: string;
  value: T;
}

export const customStyleReactSelect = {
  control: (style: any) => ({
    ...style,
    background: 'var(--overskyet)',
    fontFamily: 'var(--din-light)',
    height: '40px',
    borderRadius: '0',
  }),
  menu: (style: any) => ({
    ...style,
    borderRadius: '0',
  }),
  dropdownIndicator: (style: any) => ({
    ...style,
    color: 'var(--sort)',
  }),
  indicatorSeparator: (style: any) => ({
    ...style,
    backgroundColor: 'var(--sort)',
  }),
  clearIndicator: (style: any) => ({
    ...style,
    color: 'var(--sort)',
  }),
  option: (style: any, state: any) => ({
    ...style,
    backgroundColor: state.isFocused ? 'var(--primary)' : 'transparent',
  }),
  container: (style: any) => ({
    ...style,
    width: '100%',
  }),
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 'var(--3)';

  :first-child {
    margin-top: 0;
  }

  @media (max-width: ${contentWidth}) {
    display: block;
  }
`;

const SelectedOptions = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 1rem;
  margin-left: var(--05);
  gap: var(--05);

  width: 100%;

  :empty {
    margin: 0;
  }

  @media (max-width: ${tabletBreakpoint}) {
    margin-left: 0;
  }
`;

const SelectedOption = styled.div`
  height: var(--5);
  padding: var(--05) var(--1-05) var(--2px);
  display: flex;
  align-items: center;
  gap: var(--1);
  justify-content: space-between;
  text-align: center;
  flex-direction: row;

  border: var(--1px) solid var(--sort);
  border-radius: 100vmax;

  font-size: var(--16px-rem);

  @media (max-width: ${tabletBreakpoint}) {
    margin-left: 0;
  }
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: var(--hvit);
  border-style: none;
  font-family: var(--din-light);
  font-size: var(--16px-rem);
`;

const CrossIcon = styled(Icon).attrs({ name: 'cross' })`
  width: 1rem;
  height: 1rem;
  transition: transform 0.2s;
  cursor: pointer;

  &:hover {
    transform: rotate(90deg);
  }
`;

export function MultiSelect<T extends string = string>({
  onChange,
  options,
  label,
  selected = [],
  placeholder = 'Velg...',
  withEmployeeImage = false,
  focus = false,
}: MultiSelectProps<T>) {
  const employeeRecord = useRecoilValue(employeeRecordState);
  const selectedMapping = selected.map((option) => {
    return { label: option, value: option };
  });

  const [notSelectedOptions, setNotSelectedOptions] = useState<MultiSelectObject<T>[]>(
    options
      .filter((x) => !selected.includes(x))
      .map((option) => {
        return { label: option, value: option };
      })
  );

  const sortAlphabetically = (a: MultiSelectObject<T>, b: MultiSelectObject<T>) => {
    const aName = a.value;
    const bName = b.value;
    if (aName < bName) return -1;
    if (aName > bName) return 1;
    return 0;
  };

  const handleChange = (
    _: OnChangeValue<MultiSelectObject<T>, boolean>,
    actionMeta: ActionMeta<MultiSelectObject<T>>
  ) => {
    if (actionMeta.action === 'select-option' && actionMeta.option) {
      onChange([...selected, actionMeta.option.value]);
      setNotSelectedOptions(notSelectedOptions.filter((x) => x !== actionMeta.option));
    }
  };

  const onDelete = (removedOption: MultiSelectObject<T>) => {
    onChange(selected?.filter((x) => x !== removedOption.value));
    setNotSelectedOptions([...notSelectedOptions, removedOption].sort((a, b) => sortAlphabetically(a, b)));
  };

  return (
    <Container>
      <div>
        <TextFieldLabel>{label}</TextFieldLabel>
        <Select
          autoFocus={focus}
          onChange={handleChange}
          name="tags"
          isMulti
          options={notSelectedOptions}
          styles={customStyleReactSelect}
          value={[]}
          placeholder={placeholder}
        />
      </div>
      <SelectedOptions>
        {selectedMapping.map((option) => (
          <SelectedOption key={option.label}>
            {withEmployeeImage && <EmployeeImage employee={employeeRecord.getEmployeeByName(option.label)} />}
            {option.label}
            <StyledButton type="button" onClick={() => onDelete(option)}>
              <CrossIcon />
            </StyledButton>
          </SelectedOption>
        ))}
      </SelectedOptions>
    </Container>
  );
}
