import React from 'react';

import styled from 'styled-components/macro';

import Icon from '../Icon';

const Button = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border: none;
  background: transparent;
  padding: 0.25rem;
  border-radius: 50%;
  cursor: pointer;
  stroke: var(--sort);
  transition: stroke 0.15s, background 0.15s;

  &:hover {
    background: var(--sort);
    stroke: var(--hvit);
  }
`;

const CrossIcon = styled(Icon).attrs({ name: 'cross' })`
  stroke: inherit;
  width: 100%;
  height: 100%;
`;

interface Props {
  className?: string;
  onClick?: () => void;
  buttonProps?: any;
  buttonRef?: any;
}
export const ExitButton = ({ className, onClick, buttonProps, buttonRef }: Props) => {
  return (
    <Button className={className} onClick={onClick} {...buttonProps} ref={buttonRef}>
      <CrossIcon />
    </Button>
  );
};
