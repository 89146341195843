import React from 'react';

import { useRecoilValue } from 'recoil';
import { FilterType, FilterTypeExternal, FilterTypeMedia, filterState, useSetFilters } from 'stateAndApi/searchStates';
import styled from 'styled-components/macro';
import { mediaTypes } from 'types/mediaTypes';
import { tabletBreakpoint } from 'utils/constants';

import { FilterAction } from './FilterOption';
import { FilterOptionGroup } from './FilterOptionGroup';

export const FilterMenu = () => {
  const activeFilters = useRecoilValue(filterState);
  const setFilters = useSetFilters();

  const externalOption: FilterTypeExternal[] = [
    { key: 'External', value: 'external' },
    { key: 'External', value: 'internal' },
  ];

  const mediaTypesArray: FilterTypeMedia[] = mediaTypes.map((e) => {
    return { key: 'Media', value: e };
  });

  const onChange = (option: FilterType, action: FilterAction) => {
    const shouldActivate = action === 'activate';
    setFilters({ action: shouldActivate ? 'ActivateFilter' : 'DeactivateFilter', filter: option });
  };

  return (
    <MenuContainer>
      <FilterOptionGroup header="Kilde" options={externalOption} onChange={onChange} activeFilters={activeFilters} />
      <FilterOptionGroup
        header="Innholdstype"
        options={mediaTypesArray}
        onChange={onChange}
        activeFilters={activeFilters}
      />
    </MenuContainer>
  );
};

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;

  @media (max-width: ${tabletBreakpoint}) {
    padding-top: var(--4);
    margin-top: 0;
  }
`;
