import React from 'react';

import { TopThree } from 'shared/feedpage/TopThree';
import { CompetencePlanPreview } from 'types/competencePlanTypes';

type Props = {
  listOfThree: CompetencePlanPreview[];
};

const TopThreeCompetencePlan = ({ listOfThree }: Props) => <TopThree threeNewest={listOfThree} />;

export default TopThreeCompetencePlan;
