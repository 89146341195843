import { Warning } from 'utils/utils';

export const validateCompetencePlan = (title: string, imgHasError: boolean) => {
  if (title.length === 0) {
    throw new Warning('Tittel kan ikke være tom!');
  }
  if (imgHasError) {
    throw new Warning('Vi finner ikke bildet! Du kan prøve en ny lenke eller la den være tom 🤷‍♂️');
  }
};
