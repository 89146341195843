import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';

import styled from 'styled-components/macro';
import { CollectionBackend } from 'types/collectionTypes';
import { tabletBreakpoint } from 'utils/constants';

import CreateNewPost from '../../createNewPost/CreateNewPost';

interface Props {
  collection: CollectionBackend;
  isModalOpen: boolean;
  closeModal: () => void;
  setCollection: (collection: CollectionBackend) => void;
}

export const AddNewPostToCollectionModal = ({ collection, isModalOpen, closeModal, setCollection }: Props) => {
  const [postAdded, setPostAdded] = useState<boolean>(false);

  useEffect(() => {
    if (postAdded) {
      setPostAdded(false);
      closeModal();
    }
  }, [postAdded, closeModal]);
  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentElement={(props, children) => <ModalStyle {...props}>{children}</ModalStyle>}
      >
        <CreateNewPost collection={collection} setPostAdded={setPostAdded} setCollection={setCollection} />
      </Modal>
    </>
  );
};

const ModalStyle = styled.div`
  display: block !important;
  height: 90% !important;
  width: auto !important;
  min-width: 60%;
  top: 50% !important;
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  transform: translate(-50%, -50%) !important;
  padding: 0 !important;

  @media (max-width: ${tabletBreakpoint}) {
    width: 90% !important;
  }
`;
