import React, { useState } from 'react';

export const useModal = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { lockScroll, unlockScroll } = useScrollLock();

  const openModal = () => {
    setIsModalOpen(true);
    lockScroll();
  };

  const closeModal = () => {
    setIsModalOpen(false);
    unlockScroll();
  };

  return {
    isModalOpen,
    openModal,
    closeModal,
  };
};

const useScrollLock = () => {
  const lockScroll = React.useCallback(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  const unlockScroll = React.useCallback(() => {
    document.body.style.overflow = '';
  }, []);

  return {
    lockScroll,
    unlockScroll,
  };
};
