import axios from 'axios';
import { SLACK_ERROR_HOOK_URL } from 'utils/config';

interface DetailedError extends Error {
  code: string;
  error: string;
  statusCode: number;
}

const postErrorToSlack = (error: DetailedError, url: string) => {
  if (SLACK_ERROR_HOOK_URL === '') {
    console.log('SLACK_ERROR_HOOK_URL is not defined');
    return;
  }
  axios.post(
    SLACK_ERROR_HOOK_URL,
    JSON.stringify({
      text: error.message,
      blocks: [
        {
          type: 'header',
          text: {
            type: 'plain_text',
            text: `:no_entry: ${error.statusCode} - ${error.error}`,
            emoji: true,
          },
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `*request-url:* ${url}\n*message:* ${error.message}`,
          },
        },
      ],
    })
  );
};

export const reportError = async (error: DetailedError, url: string) => {
  if (process.env.NODE_ENV === 'development') {
    console.error(error);
  } else if (process.env.NODE_ENV === 'production') {
    postErrorToSlack(error, url);
  }
};
