import React from 'react';

import { SubContainer } from 'landing/landingFeed/block/BlockContainer';
import styled from 'styled-components/macro';

import { formatDate } from '../../../utils/utils';

const DateSpan = styled.span`
  color: var(--sort);
  opacity: 0.7;
  white-space: nowrap;
  margin-left: auto;

  ${SubContainer} & {
    color: var(--sort);
    opacity: 1;
  }
`;

interface FormatDateProps {
  date: string | null;
  className?: string;
}

export const FormattedDate = ({ date, className }: FormatDateProps) => {
  const dateFormatted = date ? formatDate(date) : '';
  return <DateSpan className={className}>{dateFormatted}</DateSpan>;
};
