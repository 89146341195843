import React from 'react';

import { AddPostToSelectionFooter } from 'shared/addPostsToSelections/SearchResultCard';
import styled from 'styled-components/macro';
import { CollectionBackend } from 'types/collectionTypes';
import { CompetencePlanPersonalBackend } from 'types/competencePlanTypes';
import { Post } from 'types/postTypes';

import { CardContentFlexbox } from './body/CardContentFlexbox';
import { CardContentLinkWrapper } from './body/CardContentLinkWrapper';
import { CardDescription } from './body/CardDescription';
import CardHorizontalContentWrapper from './body/CardHorizontalContentWrapper';
import { CardImage } from './body/CardImage';
import { CardTextContainer } from './body/CardTextContainer';
import { CardTitle } from './body/CardTitle';
import { FooterKompetanseplan, FooterSearchResult, FooterWithDeleteFromCollection } from './footer/Footer';
import { Header } from './header/Header';

import { CardSize } from './cardSizes';

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SpaceBetweenContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export type CompetencePlanTypeForPostCard = {
  competencePlanPersonal: CompetencePlanPersonalBackend;
  competencePlanId: number;
  setCompetencePlanPersonal: (k: CompetencePlanPersonalBackend) => void;
  removePostFromCompetencePlan: (id: number) => void;
  setShouldUpdatePersonal: (k: boolean) => void;
};

type PostCardProps = {
  post: Post;
  cardSize?: CardSize;
};

type PostCardSearchResultsProps = PostCardProps & {
  footerForAddingPost: AddPostToSelectionFooter;
  isSpeedRead?: boolean;
};

export const PostCardSearchResults = ({
  post,
  cardSize = CardSize.SMALL,
  footerForAddingPost,
}: PostCardSearchResultsProps) => {
  return (
    <PostCardDefault
      post={post}
      cardSize={cardSize}
      footer={<FooterSearchResult footerForAddingPost={footerForAddingPost} post={post} cardSize={cardSize} />}
    />
  );
};

type PostCardKompetanseplanProps = PostCardProps & {
  vertical: boolean;
  competencePlanProps: CompetencePlanTypeForPostCard;
};

export const PostCardKompetanseplan = ({
  post,
  cardSize = CardSize.SMALL,
  vertical,
  competencePlanProps,
}: PostCardKompetanseplanProps) => {
  return (
    <PostCardDefault
      post={post}
      vertical={vertical}
      cardSize={cardSize}
      footer={<FooterKompetanseplan competencePlanProps={competencePlanProps} post={post} cardSize={cardSize} />}
    />
  );
};

type PostCardWithDeleteFromCollectionProps = PostCardProps & {
  collection?: CollectionBackend;
  deletePostFromCollection?: (id: number) => void;
  vertical: boolean;
  isSpeedRead?: boolean;
};

export const PostCardWithDeleteFromCollection = ({
  post,
  cardSize = CardSize.SMALL,
  collection,
  deletePostFromCollection,
  isSpeedRead = false,
  vertical,
}: PostCardWithDeleteFromCollectionProps) => {
  return (
    <PostCardDefault
      vertical={vertical}
      post={post}
      cardSize={cardSize}
      isSpeedRead={isSpeedRead}
      footer={
        <FooterWithDeleteFromCollection
          deletePostFromCollection={deletePostFromCollection}
          collection={collection}
          post={post}
          cardSize={cardSize}
        />
      }
    />
  );
};

type CardDefaultProps = PostCardProps & {
  vertical?: boolean;
  isSpeedRead?: boolean;
  footer: React.ReactNode;
};

const PostCardDefault = ({
  post,
  footer,
  cardSize = CardSize.SMALL,
  vertical = true,
  isSpeedRead = false,
}: CardDefaultProps) => {
  const cardImage = (
    <CardImage
      src={post.thumbnailUrl}
      alt={`Bildet for innlegg med tittel: ${post.title}}`}
      consumptionTime={post.consumptionTime}
    />
  );

  return (
    <>
      {vertical ? (
        <>
          <Header post={post} />
          <SpaceBetweenContainer>
            <CardContentLinkWrapper post={post} isSpeedRead={isSpeedRead}>
              <CardContentFlexbox>
                {cardImage}
                <CardTextContainer cardSize={cardSize}>
                  <CardTitle cardSize={cardSize}>{post.title}</CardTitle>
                  <CardDescription cardSize={cardSize}>{post.description}</CardDescription>
                </CardTextContainer>
              </CardContentFlexbox>
            </CardContentLinkWrapper>
            {footer}
          </SpaceBetweenContainer>
        </>
      ) : (
        <>
          <CardHorizontalContentWrapper>
            <CardContentLinkWrapper post={post} isSpeedRead={isSpeedRead}>
              {cardImage}
            </CardContentLinkWrapper>
            <FlexWrapper>
              <CardContentLinkWrapper post={post} isSpeedRead={isSpeedRead}>
                <Header post={post} />
                <CardTextContainer cardSize={cardSize}>
                  <CardTitle cardSize={cardSize}>{post.title}</CardTitle>
                  <CardDescription lineClamp={2} cardSize={cardSize}>
                    {post.description}
                  </CardDescription>
                </CardTextContainer>
              </CardContentLinkWrapper>
              {footer}
            </FlexWrapper>
          </CardHorizontalContentWrapper>
        </>
      )}
    </>
  );
};
