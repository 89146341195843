import React, { useEffect, useState } from 'react';

import { useRecoilValue } from 'recoil';
import { employeeRecordState } from 'stateAndApi/employeeState';
import styled from 'styled-components/macro';

import { Employee } from '../../types/employeeTypes';

import { ContributorImages } from './ContributorImages';
import { ContributorNames } from './ContributorNames';

const Container = styled.div<{ isDetailPage: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isDetailPage ? 'column' : 'row')};
  justify-content: flex-start;
  align-items: ${(props) => (props.isDetailPage ? 'none' : 'center')};
  gap: ${(props) => (props.isDetailPage ? '0' : '1rem')};
`;

const Text = styled.p`
  font-size: var(--12px-rem);
  line-height: 0;
`;

const TextContainer = styled(Container)<{ isDetailPage: boolean }>`
  flex-direction: row;
  align-items: center;
`;

interface ContributorToCollectionProps {
  addedByEmployeeIds: number[];
  withText: boolean;
  isDetailPage: boolean;
}

export const CollectionContributors = ({
  addedByEmployeeIds,
  withText,
  isDetailPage,
}: ContributorToCollectionProps) => {
  const employeeRecord = useRecoilValue(employeeRecordState);

  const [contributors, setContributors] = useState<Employee[]>([]);
  const contributorsToCollectionWithoutDuplicates = [...new Set(contributors)];

  useEffect(() => {
    addedByEmployeeIds.forEach((addedBy: any) => {
      const getEmployee = employeeRecord.getEmployee(addedBy);
      if (getEmployee !== undefined) setContributors((contributors) => [...contributors, getEmployee]);
    });
  }, [addedByEmployeeIds, employeeRecord]);

  return (
    <Container isDetailPage={isDetailPage}>
      {isDetailPage && <Text> Bekkere som har lagt til innlegg i samlingen</Text>}
      <TextContainer isDetailPage={isDetailPage}>
        <ContributorImages employees={contributorsToCollectionWithoutDuplicates} maxNumberOfBubbles={3} sizeUp={true} />
        {withText && <ContributorNames employees={contributorsToCollectionWithoutDuplicates} maxNumberOfNames={3} />}
      </TextContainer>
    </Container>
  );
};
