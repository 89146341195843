import React from 'react';

import { Chip } from 'createNewPost/form/Chip';
import { MediaType, mediaTypes, norwegianMediaTypes } from 'types/mediaTypes';

interface ContentPickerProps {
  value: MediaType;
  onChange: (typeOfContent: MediaType) => void;
}

export const ContentPicker = ({ onChange, value }: ContentPickerProps) => {
  return (
    <div>
      {/*Filter out slack_thread mediatype as sharing slack threads from the webapp is not supported*/}
      {mediaTypes
        .filter((x) => x !== 'slack_thread' && x !== 'collection')
        .map((mediaType) => (
          <Chip
            key={mediaType}
            text={norwegianMediaTypes[mediaType]}
            onClick={() => onChange(mediaType)}
            active={mediaType === value}
          />
        ))}
    </div>
  );
};
