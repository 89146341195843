import React, { ReactNode } from 'react';

import { StyledSubTitle } from 'genericDetail/GenericTopTexts';
import styled from 'styled-components/macro';
import { fadeAnimation } from 'utils/commonAnimations';
import { mobileBreakpoint, tabletBreakpoint } from 'utils/constants';

export const Container = styled.div<{ backgroundColor: string }>`
  display: flex;
  flex-direction: column;
  animation: ${fadeAnimation};
  border-radius: 10px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 1rem;
  @media (max-width: ${tabletBreakpoint}) {
    column-gap: var(--2);
    width: 100%;
  }
  @media (max-width: ${mobileBreakpoint}) {
    column-gap: 0;
    margin-left: 0;
  }
`;

interface SpeedReadOrRecommendedProps {
  title: string;
  backgroundColor?: string;
  width?: string;
  children?: ReactNode;
}

export const SpeedReadOrRecommended = ({ title, backgroundColor = 'none', children }: SpeedReadOrRecommendedProps) => {
  return (
    <Container backgroundColor={backgroundColor}>
      <StyledSubTitle>{title}</StyledSubTitle>
      {children}
    </Container>
  );
};
