import { mapToEmployee } from 'post/utils/postApi';

import {
  CollectionBackend,
  CollectionDisplay,
  CollectionDisplayBackend,
  CollectionMetadata,
  CollectionMetadataLight,
} from '../../types/collectionTypes';
import { EmployeeRecord } from '../../types/employeeTypes';
import {
  IdentifierResponse,
  authenticatedGetV2,
  authenticatedV2DeleteWithoutResponse,
  authenticatedV2PostWithResponse,
  authenticatedV2PostWithoutResponse,
  authenticatedV2PutWithoutResponse,
} from '../../utils/apiUtils';
import { ApiUrls } from '../../utils/urls';

export const getCollections = async (): Promise<CollectionMetadata[]> => {
  return await authenticatedGetV2<CollectionMetadata[]>(`${ApiUrls.COLLECTION}/all`);
};

export const getCollection = async (id: number): Promise<CollectionBackend> => {
  return await authenticatedGetV2<CollectionBackend>(`${ApiUrls.COLLECTION}/${id}`);
};

export const getCollectionPreview = async (id: number): Promise<CollectionBackend> => {
  const limit = '5';
  const params = new URLSearchParams({ limit });
  return await authenticatedGetV2<CollectionBackend>(`${ApiUrls.COLLECTION}/${id}/preview?${params}`);
};

export const getCollectionsByAuthorId = async (authorId: number): Promise<CollectionBackend[]> => {
  const params = new URLSearchParams({ authorId: authorId.toString() });
  return await authenticatedGetV2<CollectionBackend[]>(`${ApiUrls.COLLECTION}?${params}`);
};

export const getCollectionsLight = async (): Promise<CollectionMetadataLight[]> => {
  return await authenticatedGetV2<CollectionMetadataLight[]>(`${ApiUrls.COLLECTION}/light/all`);
};

export const createCollection = async (newCollection: CollectionMetadata): Promise<IdentifierResponse> => {
  return await authenticatedV2PostWithResponse<CollectionMetadata, IdentifierResponse>(
    ApiUrls.COLLECTION,
    newCollection
  );
};

export const deleteCollection = async (collectionId: number): Promise<void> =>
  await authenticatedV2DeleteWithoutResponse(`${ApiUrls.COLLECTION}/${collectionId}`, {});

export const restoreCollection = async (id: number) => {
  await authenticatedV2PutWithoutResponse(`${ApiUrls.COLLECTION}/${id}/restore`, {});
};

export const addPostToCollection = async (collection_id: number, post_id: number) => {
  await authenticatedV2PostWithoutResponse(`${ApiUrls.COLLECTION}/${collection_id}/add/${post_id}`, {});
};

export const removePostFromCollection = async (collection_id: number, post_id: number) => {
  await authenticatedV2DeleteWithoutResponse(`${ApiUrls.COLLECTION}/${collection_id}/remove/${post_id}`, {});
};

export const editCollection = async (updatedCollection: CollectionMetadata) => {
  authenticatedV2PutWithoutResponse(`${ApiUrls.COLLECTION}/${updatedCollection.id}`, updatedCollection);
};

export const mapToFrontendCollectionDisplay = (
  collection: CollectionDisplayBackend,
  employeeRecord: EmployeeRecord
): CollectionDisplay => ({
  id: collection.id,
  publisherEmployee: mapToEmployee(collection.author_id, employeeRecord),
  title: collection.title,
  description: collection.description,
  thumbnailUrl: collection.thumbnail_url,
  numberOfPosts: collection.number_of_posts,
  type: 'collectionDisplay',
});
