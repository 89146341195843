import React, { FormEvent } from 'react';

import styled from 'styled-components/macro';
import { tabletBreakpoint } from 'utils/constants';

const Container = styled.button<{ active: boolean }>`
  font-size: var(--16px-rem);
  font-family: var(--din-regular);
  box-sizing: border-box;
  border-radius: 100vmax;
  color: ${(props) => (props.active ? 'white' : 'var(--sort)')};
  padding: var(--1) var(--2);
  display: inline-block;
  margin-right: var(--2);
  transition: 0.3s;
  border: none;
  cursor: pointer;
  background: ${(props) => (props.active ? 'var(--sort)' : 'none')};

  @media (max-width: ${tabletBreakpoint}) {
    margin-right: var(--1px);
  }

  &:hover {
    background: ${(props) => (props.active ? 'var(--sort)' : 'var(--overskyet)')};
    color: ${(props) => (props.active ? 'white' : 'var(--sort)')};
  }
`;

interface Props {
  text: string;
  icon?: string;
  className?: string;
  onClick?: () => void;
  active?: boolean;
}

export const Chip = (props: Props) => {
  const handleClick = (event: FormEvent) => {
    event.preventDefault();
    if (typeof props.onClick === 'function') {
      props.onClick();
    }
  };
  return (
    <Container onClick={handleClick} className={props.className} active={Boolean(props.active)}>
      {props.text}
    </Container>
  );
};
