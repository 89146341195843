import React from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';
import Icon from 'shared/Icon';
import { getActiveFiltersAndSearchWord, searchState } from 'stateAndApi/searchStates';
import styled from 'styled-components/macro';
import { contentWidth, tabletBreakpoint } from 'utils/constants';

import ActiveFilterButton from './ActiveFilterButton';

export const ActiveFiltersAndSearchWord = () => {
  const { activeFilters } = useRecoilValue(getActiveFiltersAndSearchWord);
  const [searchTerm, setSearchTerm] = useRecoilState<string>(searchState);

  return (
    <>
      {searchTerm && (
        <SearchContainer>
          <SearchHeader>Søkeord</SearchHeader>
          <ButtonContainer>
            <SearchWord>{searchTerm}</SearchWord>
            <StyledButtonDelete onClick={() => setSearchTerm('')}>
              <DeleteIcon name="cross" />
            </StyledButtonDelete>
          </ButtonContainer>
        </SearchContainer>
      )}

      {activeFilters.length !== 0 && (
        <Container>
          <div>Aktive filtre</div>
          <FilterContainer>
            {activeFilters.map((f, index) => (
              <ActiveFilterButton key={index} filterType={f} />
            ))}
          </FilterContainer>
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  font-size: var(--20px-rem);
  width: ${contentWidth};
  display: flex;
  flex-direction: column;
  margin: var(--3) auto var(--neg-2);
  padding: var(--2) var(--4) 0;
  gap: var(--2);

  @media (max-width: ${tabletBreakpoint}) {
    font-size: var(--18px-rem);
    margin-top: var(--1);
    padding: var(--2) var(--4);
    width: 100%;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const SearchWord = styled.div`
  line-height: initial;
  color: rgba(0, 0, 0, 0.7);
`;

const SearchContainer = styled(Container)`
  flex-direction: row;
`;

const SearchHeader = styled.div`
  align-self: center;
`;

const ButtonContainer = styled.div`
  width: fit-content;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  border-radius: 100vw;
  padding: 0 var(--1);
  font-size: var(--16px-rem);
  white-space: nowrap;
  outline: 1px solid rgba(0, 0, 0, 0.4);

  @media (max-width: ${tabletBreakpoint}) {
    gap: 0.2rem;
    font-size: var(--14px-rem);
    padding: 0 10px;
  }
`;

const StyledButtonDelete = styled.button`
  display: flex;
  border: none;
  background: none;
  padding: 0;
  &:hover,
  &:focus-visible {
    cursor: pointer;
  }
`;

const DeleteIcon = styled(Icon)`
  stroke: rgba(25, 25, 25, 0.5);
  width: 1.2rem;
  &:hover {
    stroke: black;
    stroke-width: var(--1px);
  }

  @media (max-width: ${tabletBreakpoint}) {
    width: 1rem;
  }
`;
