import React, { ReactNode, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { Tab, TabContext } from 'landing/Landing';
import { AddButton } from 'landing/header/AddButton';
import { MyPageButton } from 'landing/header/MyPageButton';
import { Title } from 'landing/header/Title';
import styled from 'styled-components/macro';
import { fadeAnimation } from 'utils/commonAnimations';
import { RoutePaths } from 'utils/urls';

import { Tabs } from './Tabs';
import { ViewMyCollectionButton } from './ViewMyCollectionButton';
import { ViewMyCompetencePlansButton } from './ViewMyCompetencePlansButtons';

interface HeaderProps {
  children?: ReactNode;
}

export const LogoAndOptionsHeader = () => {
  return (
    <Header>
      <Title size="sm" />
      <ButtonGroup>
        <AddButton />
        <ViewMyCollectionButton />
        <ViewMyCompetencePlansButton />
        <MyPageButton />
      </ButtonGroup>
    </Header>
  );
};

const getColor = (activeTab: Tab): string => {
  switch (activeTab) {
    case Tab.Feed:
      return 'var(--lighter-primary)';
    case Tab.Kompetanseplan:
      return 'var(--previous-ligther-primary)';
  }
};

export const GenericHeader = ({ children }: HeaderProps) => {
  const location = useLocation();

  const { activeTab } = useContext(TabContext);
  return (
    <OuterContainer>
      <Container>
        <LogoAndOptionsHeader />
        {location.pathname === RoutePaths.LANDING ? <Tabs /> : null}
        <Body color={getColor(activeTab)}>{children}</Body>
      </Container>
    </OuterContainer>
  );
};

const Header = styled.div`
  background-color: var(--primary);
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: var(--5) var(--4);
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: var(--1);
`;

const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  animation: ${fadeAnimation};
`;

const Body = styled.div<{ color: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ color }) => color};
  padding: 2rem 0.5rem 2rem;
`;
