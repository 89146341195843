import React, { useState } from 'react';

import Icon from 'shared/Icon';
import styled, { keyframes } from 'styled-components/macro';

const ScrollButton = styled.button`
  width: 3rem;
  height: 3rem;
  border: 1px solid var(--sort);
  border-radius: 25px;
  background: var(--primary);
  cursor: pointer;
  :hover,
  :focus-visible {
    transform: translateY(-8px);
  }
`;

const ArrowIcon = styled(Icon).attrs({ name: 'arrow-long' })`
  width: 100%;
  height: 100%;
  transform: rotate(90deg);
`;
const fadeIn = keyframes`
from {
  opacity: 0;
}
50% {
  opacity: 0.1;
}
to {
  opacity: 1;
}
`;

const ScrollToTopContainer = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  top: 1rem;
  z-index: 1;
  animation: ${fadeIn} 5s ease-out;
`;
export const ScrollToTopButton = () => {
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  const toggleShowScrollToTop = () => {
    const scrollTop = document.documentElement.scrollTop;
    scrollTop > 1000 ? setShowScrollToTop(true) : setShowScrollToTop(false);
  };

  window.addEventListener('scroll', toggleShowScrollToTop);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  };

  return (
    <ScrollToTopContainer>
      {showScrollToTop ? (
        <ScrollButton onClick={scrollToTop}>
          <ArrowIcon />
        </ScrollButton>
      ) : null}
    </ScrollToTopContainer>
  );
};
