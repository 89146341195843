import React from 'react';
import { createPortal } from 'react-dom';

type PortalProps = {
  children: React.ReactElement;
  shouldRenderInPortal: boolean;
};

export function Portal({ children, shouldRenderInPortal }: PortalProps) {
  if (shouldRenderInPortal) {
    return createPortal(children, document.body);
  }
  return children;
}
