export enum MixpanelEvent {
  VISIT_ATLAS = 'Visit Atlas',
  OPEN_POST_IN_WEBAPP = 'Open post in webapp',
  REDIRECT_TO_EXTERNAL_POST_FROM_FEED = 'Redirect to external post from feed',
  READ_SPEEDREAD = 'Read speedread',
  NAVIGATE_TO_PAGE = 'Navigate to page event',

  ADD_POST_TO_COLLECTION_FROM_COLLECTION = 'Add post to collection from collection',
  ADD_POST_TO_COLLECTION_FROM_CARD = 'Add post to collection from card',
  ADD_POST_TO_COLLECTION_FROM_DETAIL_PAGE = 'Add post to collection from detail page',

  SEARCH_INITIATED_IN_LANDING = 'Search initiated in landing',
}

export type MixpanelRequestBody = {
  id: number;
  department?: string;
  event: MixpanelEvent;
};

export interface MixpanelNavigateToPageBody {
  path: string;
  department: string;
}

export interface MixpanelInitiateSearchBody extends MixpanelRequestBody {
  searchTerm: string;
}
