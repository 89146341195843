export enum RoutePaths {
  LANDING = '/',
  POST = '/innlegg',
  UNAUTHORIZED = '/unauthorized',
  NEW = '/del',
  MY_PAGE = '/min-side',
  MY_PAGE_ACHIEVEMENTS = '/min-side#bragder',
  MY_PAGE_COLLECTIONS = '/min-side#samlinger',
  MY_PAGE_COMPETENCE_PLAN = '/min-side#kompetanseplaner',
  MY_PAGE_HISTORY = '/min-side#logg',
  MY_PAGE_READING_LIST = '/min-side#leseliste',
  REDIRECT = '/redirect',
  QR = '/qr',
  FEATURED_POST = '/påfyll',
  COLLECTION = '/samling',
  NEW_COLLECTION = '/ny-samling',
  COMPETENCE_PLAN = '/kompetanseplan',
  NEW_COMPETENCE_PLAN = '/ny-kompetanseplan',
}

export enum ApiUrls {
  COUNT_POSTS = '/api/countPosts',
  COLLECTION = '/api/collection',
  COMPETENCE_PATH = '/api/kompetanseplan',
  COMPETENCE_PATH_PERSONAL = '/api/kompetanseplan/personal',
  COMPETENCE_PATH_PREVIEW = '/api/kompetanseplan/preview',
  COMPETENCE_PATH_PREVIEW_MY = '/api/kompetanseplan/preview/my',
  COMPETENCE_SIGNUP = '/api/kompetanseplan/signup',
  COMPETENCE_PLANS_BY_USER = '/api/kompetanseplan/user',
  COMPETENCE_PLAN_NUDGE = '/api/kompetanseplan/nudge',
  DELETE_POST = '/api/posts',
  EDIT_POST = '/api/posts',
  EMPLOYEES = '/api/employees',
  GET_ACHIEVEMENTS = '/api/achievements',
  GET_FILE = '/api/file',
  GET_TAGS = '/api/tags',
  GET_POSTS_TAGS = '/api/getTagsForPost',
  GET_POST = '/api/post',
  GET_POSTS = '/api/posts',
  GET_POST_PAGE = '/api/paginatedPosts',
  GET_POSTS_BY_IDS = '/api/postsByIds',
  GET_POSTS_BY_TAG = '/api/postsByTag',
  GET_RECOMMENDED_POSTS = '/api/recommender-system-posts',
  POST_RECOMMENDER_PROFILE = '/api/post-recommender-profile',
  GET_SPEED_READ = '/api/speed-read',
  GET_POSTS_FROM_HISTORY = '/api/postsFromHistory',
  GET_POSTS_BY_PODCAST_SERIES_ID = '/api/postsByPodcastSeries',
  GET_MOST_RECENT_POSTS = '/api/mostRecentPosts',
  GET_PODCASTS_SERIES = '/api/podcasts/series',
  HISTORY = '/api/history',
  MIXPANEL = '/api/mixpanel',
  MIXPANEL_REDIRECT_SLACK = '/api/mixpanel-redirect',
  MIXPANEL_TRACK_PAGE = '/api/mixpanel-track-page',
  MIXPANEL_TRACK_SEARCH = '/api/mixpanel-track-search',
  POST_FEEDBACK = '/api/feedback',
  POST_POST = '/api/post',
  POST_TAG = '/api/tags',
  READING_LIST = '/api/reading-list',
  SEARCH = '/api/search',
  SEARCH_COMPETENCE_PLANS = '/api/search/competencePlans',
  SEARCH_SUGGESTION_CONTENT = '/api/search/suggestion',
  SCREEN_URL = '/api/urlScreening',
}
