import { CreatePost } from 'types/postTypes';

export const allFieldsDefaultValues: CreatePost = {
  title: '',
  description: '',
  thumbnailUrl: '',
  published: new Date().toISOString(),
  publisherId: null,
  contributors: [],
  tags: [],
  isExternal: true,
  url: '',
  mediaType: 'article',
  consumptionTime: 0,
  collectionIds: [],
  isShareToBekkNo: false,
};

export const defaultValuesWithCollectionId = (collectionId: number): CreatePost => {
  return {
    title: '',
    description: '',
    thumbnailUrl: '',
    published: new Date().toISOString(),
    publisherId: null,
    contributors: [],
    tags: [],
    isExternal: true,
    url: '',
    mediaType: 'article',
    consumptionTime: 0,
    collectionIds: [collectionId],
    isShareToBekkNo: false,
  } as CreatePost;
};

export type FormAction<T = any> = {
  field: keyof CreatePost | 'urlScreeningDataShape';
  value: T;
};

export const formReducer = (state: CreatePost, { field, value }: FormAction): CreatePost => {
  if (field === 'urlScreeningDataShape') {
    return {
      ...state,
      url: value.url,
      thumbnailUrl: value.thumbnailUrl,
      title: value.title,
      description: value.description,
      mediaType: value.mediaType,
      consumptionTime: value.consumptionTime,
    };
  } else {
    return { ...state, [field]: value };
  }
};
