import React from 'react';

import Icon from 'shared/Icon';
import { ButtonWithHover } from 'shared/button/ButtonWithHover';
import { useGoToRoute } from 'stateAndApi/history';
import styled from 'styled-components/macro';
import { RoutePaths } from 'utils/urls';

const ViewMyCollectionIcon = styled(Icon).attrs({ name: 'collection' })`
  width: 100%;
  height: 100%;
`;

export const ViewMyCollectionButton = () => {
  const goToRoute = useGoToRoute();

  return (
    <ButtonWithHover
      title="Mine samlinger"
      hoverColor="var(--contrast)"
      onClick={() => goToRoute(RoutePaths.MY_PAGE_COLLECTIONS)}
    >
      <ViewMyCollectionIcon />
    </ButtonWithHover>
  );
};
