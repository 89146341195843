import React, { ReactNode, RefObject } from 'react';

import { TooltipTriggerProps } from '@react-types/tooltip';
import styled, { css } from 'styled-components/macro';

type StyledButtonProps = {
  title?: string;
  hoverColor: string;
  strokeColor?: string;
  backgroundColor?: string;
  disabled?: boolean;
  className?: string;
};

type ButtonProps = StyledButtonProps & {
  buttonRef?: RefObject<HTMLButtonElement>;
  tooltipTriggerProps?: TooltipTriggerProps;
  onClick?: any;
  type?: 'submit' | 'reset' | 'button' | undefined;
  children?: ReactNode;
};

const StyledButton = styled.button<StyledButtonProps>`
  border: 1px solid var(--sort);
  width: var(--40px-rem);
  height: var(--40px-rem);
  background: ${(props) => props.backgroundColor ?? 'none'};
  opacity: ${({ disabled }) => (disabled ? '50%' : '100%')};
  display: flex;
  justify-content: center;
  padding: 0;
  position: relative;
  overflow: hidden;
  isolation: isolate;
  cursor: pointer;

  &:hover:after,
  &:focus-visible:after {
    top: 0;
  }

  &:hover,
  &:focus-visible {
    svg {
      stroke: ${({ strokeColor }) => strokeColor};
    }
  }

  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 100%;
    background: ${({ hoverColor }) => hoverColor};
    transition: 0.1s;
    z-index: -1;
  }

  ${(props) =>
    props.disabled &&
    css`
      background: var(--overskyet);
      cursor: unset;
    `};
`;

export const ButtonWithHover = ({
  title,
  hoverColor,
  strokeColor = 'var(--sort)',
  backgroundColor,
  disabled,
  buttonRef,
  tooltipTriggerProps,
  onClick,
  type,
  className,
  children,
}: ButtonProps) => {
  return (
    <StyledButton
      title={title}
      hoverColor={hoverColor}
      strokeColor={strokeColor}
      backgroundColor={backgroundColor}
      disabled={disabled}
      ref={buttonRef}
      {...tooltipTriggerProps}
      onClick={onClick}
      type={type}
      className={className}
    >
      {children}
    </StyledButton>
  );
};
