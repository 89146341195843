import React, { useCallback, useEffect, useState } from 'react';

import { StyledSubTitle } from 'genericDetail/GenericTopTexts';
import { PostCardWithHotkeys } from 'landing/PostCardWithHotkeys';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { Loader } from 'shared/Loader';
import { FeedContainer } from 'shared/feed/Feed';
import { SpeedReadOrRecommended } from 'shared/feed/SpeedReadOrRecommended';
import styled from 'styled-components/macro';
import { ENVIRONMENT } from 'utils/config';
import { contentWidth, mobileBreakpoint, tabletBreakpoint } from 'utils/constants';

import {
  mapToFrontendPost as _mapToFrontendPost,
  mapToFrontendPosts as _mapToFrontendPosts,
  countPosts,
  getPostsForFeed,
  getRecommendedPostsForFeed,
  getSpeedRead,
} from '../../post/utils/postApi';
import { CardFeedGrid } from '../../shared/feed/CardFeedGrid';
import Carousel from '../../shared/feed/Carousel';
import { useLoggedInEmployee } from '../../stateAndApi/employeeApi';
import { employeeRecordState } from '../../stateAndApi/employeeState';
import { EmployeeRecord } from '../../types/employeeTypes';
import { Post, PostBackend } from '../../types/postTypes';
import { LoadMoreButton } from '../LoadMoreButton';

import { CollectionBlock } from './block/CollectionBlock';
import { TagBlock } from './block/TagBlock';

const FeedContainerTop = styled.div<{ gridColumns: '65% 35%' | '100%' }>`
  max-width: ${contentWidth};
  width: 100%;
  margin: 0 auto;
  padding: var(--3) var(--4) 0;
  display: grid;
  grid-template-columns: ${(props) => props.gridColumns};

  @media (max-width: ${tabletBreakpoint}) {
    grid-template-columns: 100%;
    padding: var(--2);
    margin-bottom: var(--10);
    flex-direction: column;
  }

  @media (max-width: ${mobileBreakpoint}) {
    flex-direction: column-reverse;
  }
`;

const totalPostsAmountState = atom<number>({
  key: 'LandingFeed/totalPostsAmountState',
  default: 0,
});

// Object with field as pageIndex and value as posts (per page index) e.g.:
// { '0': [...], '1': [...] }
const pageIndexFeedState = atom<Record<number, Post[]>>({
  key: 'LandingFeed/pageIndexFeedState',
  default: {},
});

const pageIndexState = atom<number>({
  key: 'LandingFeed/pageIndexState',
  default: 0,
});

const POSTS_PER_PAGE = 60;
const POSTS_IN_CAROUSEL = 10;

const highlightedCollectionId =
  {
    production: 163,
    test: 1,
    development: 1,
  }[ENVIRONMENT] || 0;

export const LandingFeed = () => {
  const [totalPostsAmount, setTotalPostsAmount] = useRecoilState(totalPostsAmountState);
  const employee = useLoggedInEmployee();
  const [pageIndexFeed, setPageIndexFeed] = useRecoilState(pageIndexFeedState);
  const [recommendedPosts, setRecommendedPosts] = useState<Post[]>([]);
  const [speedRead, setSpeedRead] = useState<Post>();
  const [pageIndex, setPageIndex] = useRecoilState(pageIndexState);
  const employeeRecord = useRecoilValue<EmployeeRecord>(employeeRecordState);
  const mapToFrontendPosts = useCallback(
    (posts: PostBackend[]) => _mapToFrontendPosts(posts, employeeRecord),
    [employeeRecord]
  );
  const mapToFrontendPost = useCallback(
    (post: PostBackend) => _mapToFrontendPost(post, employeeRecord),
    [employeeRecord]
  );

  const posts = Object.values(pageIndexFeed).flat();
  const departmentRecommendedPosts = Object.values(recommendedPosts).flat();
  const postsLoaded = posts.length;
  const firstSlicedPosts = posts.splice(0, 6);

  useEffect(() => {
    countPosts().then(setTotalPostsAmount);
  }, [setTotalPostsAmount]);

  useEffect(() => {
    getPostsForFeed(POSTS_PER_PAGE, POSTS_PER_PAGE * pageIndex)
      .then(mapToFrontendPosts)
      .then((posts) => {
        setPageIndexFeed((s) => ({
          ...s,
          [pageIndex]: posts,
        }));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex]);

  useEffect(() => {
    getRecommendedPostsForFeed(employee.department, POSTS_IN_CAROUSEL)
      .then(mapToFrontendPosts)
      .then((recommendedPosts) => {
        setRecommendedPosts(recommendedPosts);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex]);

  useEffect(() => {
    getSpeedRead()
      .then(mapToFrontendPost)
      .then((speedReadPost) => {
        setSpeedRead(speedReadPost);
      });
  }, [mapToFrontendPost]);

  return (
    <>
      <FeedContainerTop gridColumns="65% 35%">
        <SpeedReadOrRecommended title="Anbefalte">
          {departmentRecommendedPosts.length > 0 ? (
            <Carousel
              options={{
                slidesToScroll: 2,
                align: 'start',
                dragFree: true,
                breakpoints: { [`(max-width: ${mobileBreakpoint})`]: { slidesToScroll: 1, dragFree: false } },
              }}
              slides={departmentRecommendedPosts.map((post) => (
                <PostCardWithHotkeys key={post.id} post={post} />
              ))}
            />
          ) : (
            <Loader />
          )}
        </SpeedReadOrRecommended>
        <SpeedReadOrRecommended title="Ukas SpeedRead" backgroundColor="var(--lighter-primary)">
          {speedRead ? (
            <PostCardWithHotkeys key={speedRead.id} post={speedRead} isSpeedRead />
          ) : (
            <StyledSubTitle>
              Opps, her har det skjedd en feil! Her skulle det vært en kjempe kul artikkel til deg, se om du finner en
              selv i mellomtiden til vi finner ut hva som er feil🤓
            </StyledSubTitle>
          )}
        </SpeedReadOrRecommended>
      </FeedContainerTop>
      <FeedContainer>
        <StyledSubTitle>Nyeste</StyledSubTitle>
        <CardFeedGrid>
          {firstSlicedPosts.map((post) => (
            <PostCardWithHotkeys key={post.id} post={post} />
          ))}
        </CardFeedGrid>
      </FeedContainer>
      <CollectionBlock collectionId={highlightedCollectionId} />
      <TagBlock tag={employee.department} />
      <FeedContainer>
        <CardFeedGrid>
          {posts.map((post) => (
            <PostCardWithHotkeys key={post.id} post={post} />
          ))}
        </CardFeedGrid>
        <LoadMoreButton
          postsLoaded={postsLoaded}
          postsTotal={totalPostsAmount}
          nextPage={() => setPageIndex((s) => s + 1)}
        />
      </FeedContainer>
    </>
  );
};
