import React, { ReactElement, useState } from 'react';

import Notification from './Notification';
import NotificationContext, { NotificationContextValue } from './notificationContext';

export const NotificationProvider = ({ children }: { children: ReactElement }) => {
  const [notification, setNotification] = useState<NotificationContextValue>(undefined);
  return (
    <NotificationContext.Provider value={{ notification, setNotification }}>
      {children}
      {notification && (
        <Notification
          isOpen
          type={notification.type}
          title={notification.title}
          message={notification.message}
          onClose={() => setNotification(undefined)}
          undoText={notification.undoText}
          deletedCollectionId={notification.deletedCollectionId}
        />
      )}
    </NotificationContext.Provider>
  );
};
