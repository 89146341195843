import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { BlockContainer } from 'landing/landingFeed/block/BlockContainer';
import { BlockFeed } from 'landing/landingFeed/block/BlockFeed';
import { mapFromMetadataToPost } from 'post/utils/postUtils';
import { useRecoilValue } from 'recoil';
import Button from 'shared/button/Button';
import styled from 'styled-components/macro';
import { Post } from 'types/postTypes';
import { RoutePaths } from 'utils/urls';

import { getCollectionPreview } from '../../../collection/utils/collectionApi';
import { employeeRecordState } from '../../../stateAndApi/employeeState';
import { CollectionBackend } from '../../../types/collectionTypes';

const CenterContent = styled.div`
  align-self: center;
`;

interface Props {
  collectionId: number;
}

/** Rendered only if the collection has more than 1 posts. */
export const CollectionBlock = ({ collectionId }: Props) => {
  const [collection, setCollection] = useState<CollectionBackend>();
  const [mainPost, setMainPost] = useState<Post>();
  const [postsThatsNotTheMainPost, setPostsThatsNotTheMainPost] = useState<Post[]>([]);

  const employeeRecord = useRecoilValue(employeeRecordState);

  useEffect(() => {
    getCollectionPreview(collectionId).then((collection) => {
      setCollection(collection);
    });
  }, [collectionId]);

  useEffect(() => {
    if (collection && collection.posts.length > 0) {
      setMainPost(mapFromMetadataToPost(collection.posts[0].post, employeeRecord));
      setPostsThatsNotTheMainPost(collection.posts.slice(1).map((p) => mapFromMetadataToPost(p.post, employeeRecord)));
    }
  }, [collection, employeeRecord]);

  if (!collection || collection.posts.length < 1 || !mainPost || !postsThatsNotTheMainPost) {
    return null;
  }

  return (
    <BlockContainer title={collection.metadata.title}>
      <BlockFeed mainPost={mainPost} posts={postsThatsNotTheMainPost} />
      <CenterContent>
        <Button as={Link} to={`${RoutePaths.COLLECTION}/${collectionId}`}>
          {`Se mer fra ${collection.metadata.title}`}
        </Button>
      </CenterContent>
    </BlockContainer>
  );
};
