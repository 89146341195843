import DOMPurify from 'dompurify';
import he from 'he';
import { CompetencePlanPreview } from 'types/competencePlanTypes';
import { EmployeeRecord } from 'types/employeeTypes';
import { IdentifierResponse } from 'utils/apiUtils';

import {
  FilterType,
  FilterTypeEmployee,
  FilterTypeExternal,
  FilterTypeMedia,
  FilterTypeTag,
} from '../../stateAndApi/searchStates';
import { Post, PostBackend, PostMetadata } from '../../types/postTypes';
import { Warning } from '../../utils/utils';

import { mapToEmployee, mapToEmployees } from './postApi';

export type UrlExceptionType = 'valid' | 'invalid' | 'duplicate';

export interface UrlException {
  message: UrlExceptionType;
}

export const handleUrlException = (urlException: UrlException) => {
  if (urlException.message === 'duplicate')
    return new Warning('Dette må være et bra innlegg 🎉 Det ser ut som en annen bekker allerede har delt det');
  if (urlException.message === 'invalid')
    return new Error('URLen er ikke gyldig! Er du sikker på at den er skrevet riktig?');
};

export const isUrlException = (
  urlExceptionResponse: IdentifierResponse | UrlException
): urlExceptionResponse is UrlException => {
  return (urlExceptionResponse as UrlException).message !== undefined;
};

export const cleanPost = (post: PostBackend): PostBackend => {
  const cleanTitle = he.decode(post.title);
  const cleanDescription = he.decode(DOMPurify.sanitize(post.description, { ALLOWED_TAGS: [] }));
  const mediumRegex = /fit\/t\/(\d\d\d|\d\d\d\d)\/(\d\d\d|\d\d\d)\//;
  const unsplashRegex = /\?w=(\d\d\d|\d\d\d\d)&h=(\d\d\d|\d\d\d\d)&fit=crop&crop=edges/;
  const fixMediumThumbnail = post.thumbnail_url.replace(mediumRegex, 'max/1200/');
  const fixUnsplashThumbnail = fixMediumThumbnail.replace(unsplashRegex, '');
  return { ...post, thumbnail_url: fixUnsplashThumbnail, title: cleanTitle, description: cleanDescription };
};

export const mapFromMetadataToPostBackend = (metadata: PostMetadata): PostBackend => {
  return {
    description: metadata.description,
    id: metadata.id ? metadata.id : -1,
    is_external: metadata.is_external,
    media_type: metadata.media_type,
    publisher_id: metadata.publisher_id,
    thumbnail_url: metadata.thumbnail_url,
    title: metadata.title,
    url: metadata.url,
    tags: metadata.tags,
    consumption_time: metadata.consumption_time,
    contributors: metadata.contributors,
    published: metadata.published,
    created: metadata.created!,
    total_views: metadata.total_views,
    is_share_to_bekk_no: metadata.is_share_to_bekk_no,
    type: 'postPreview',
  };
};

export const mapFromMetadataToPost = (metadata: PostMetadata, employeeRecord: EmployeeRecord): Post => ({
  id: metadata.id ? metadata.id : -1,
  title: metadata.title,
  description: metadata.description,
  thumbnailUrl: metadata.thumbnail_url,
  created: metadata.created!,
  published: metadata.published,
  mediaType: metadata.media_type,
  publisherEmployee: mapToEmployee(metadata.publisher_id !== 0 ? metadata.publisher_id : null, employeeRecord),
  isExternal: metadata.is_external,
  consumptionTime: metadata.consumption_time,
  contributorEmployees: mapToEmployees(
    metadata.contributors.filter((x) => x !== 0),
    employeeRecord
  ),
  tags: metadata.tags,
  url: metadata.url,
  totalViews: metadata.total_views,
  isShareToBekkNo: metadata.is_share_to_bekk_no,
  type: 'post',
});

export const filterPosts = (feed: Post[], filters: FilterType[]): Post[] => {
  if (filters.length === 0) {
    return feed;
  }

  const filtersMedia = filters.filter((f): f is FilterTypeMedia => f.key === 'Media');
  const filtersExternal = filters.filter((f): f is FilterTypeExternal => f.key === 'External');
  const filtersTags = filters.filter((f): f is FilterTypeTag => f.key === 'Tag');
  const filtersEmployees = filters.filter((f): f is FilterTypeEmployee => f.key === 'Employee');

  const feedFilteredOnMedia = filterOnMedia(feed, filtersMedia);
  const feedFilteredOnExternal = filterOnExternal(feedFilteredOnMedia, filtersExternal);
  const feedFilterOnEmployees = filterOnEmployees(feedFilteredOnExternal, filtersEmployees);

  return filterOnTags(feedFilterOnEmployees, filtersTags);
};

const filterOnMedia = (feed: Post[], mediaFilters: FilterTypeMedia[]): Post[] => {
  if (mediaFilters.length === 0) {
    return feed;
  }

  const tagsValues = mediaFilters.map((t) => t.value);

  return feed.filter((post) => tagsValues.includes(post.mediaType));
};

const filterOnExternal = (feed: Post[], externalFilters: FilterTypeExternal[]): Post[] => {
  if (externalFilters.length === 0 || externalFilters.length === 2) {
    return feed;
  }

  const tagsValues = externalFilters.map((t) => t.value);

  return feed.filter((post) => {
    if (tagsValues.includes('external') && post.isExternal) {
      return true;
    }
    return tagsValues.includes('internal') && !post.isExternal;
  });
};

const filterOnTags = (feed: Post[], tagFilters: FilterTypeTag[]): Post[] => {
  if (tagFilters.length === 0) {
    return feed;
  }

  const tagFilter = tagFilters.map((t) => t.value);

  return feed.filter((post) => {
    if (post.tags.length === 0) return false;
    return tagFilter.every((tag) => post.tags.includes(tag));
  });
};

const filterOnEmployees = (feed: Post[], employeeFilters: FilterTypeEmployee[]): Post[] => {
  if (employeeFilters.length === 0) {
    return feed;
  }

  const employeeIds = employeeFilters.map((t) => t.value);
  return feed.filter((post) => {
    const contributorsAndPublisher = [...post.contributorEmployees];
    if (!!post.publisherEmployee) {
      contributorsAndPublisher.push(post.publisherEmployee);
    }
    return employeeIds.every((employeeId) =>
      contributorsAndPublisher.some((contributor) => contributor.id.toString() === employeeId)
    );
  });
};

export const isPostBackend = (tbdValue: any): tbdValue is PostBackend => {
  return (tbdValue as PostBackend).type === 'postPreview';
};

export const isCompetencePlanBackend = (tbdValue: any): tbdValue is CompetencePlanPreview => {
  return tbdValue.type === 'competencePlanPreview';
};

export const isPost = (tbdValue: any): tbdValue is Post => {
  return (tbdValue as Post).type === 'post';
};
