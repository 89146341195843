import React, { createRef } from 'react';

import { CardContainerWithHotkeys } from 'shared/card/CardContainerWithHotkeys';
import { PostCardWithDeleteFromCollection } from 'shared/card/PostCard';
import { CardSize } from 'shared/card/cardSizes';
import { useElementOnScreen } from 'shared/hooks/useElementOnScreen';
import { Post } from 'types/postTypes';

interface CardProps {
  post: Post;
  cardSize?: CardSize;
  isSpeedRead?: boolean;
}

export const PostCardWithHotkeys = ({ post, cardSize = CardSize.SMALL, isSpeedRead }: CardProps) => {
  const cardRef = createRef<HTMLDivElement>();
  const [containerRef, isVisible] = useElementOnScreen(
    {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    },
    cardRef
  );

  return (
    <CardContainerWithHotkeys post={post} ref={containerRef} visible={isVisible} cardSize={cardSize}>
      <PostCardWithDeleteFromCollection post={post} cardSize={cardSize} isSpeedRead={isSpeedRead} vertical={true} />
    </CardContainerWithHotkeys>
  );
};
