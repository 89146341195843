import React from 'react';

import Icon from 'shared/Icon';
import styled from 'styled-components/macro';
import { tabletBreakpoint } from 'utils/constants';

const CollectionSearchContainer = styled.div`
  display: flex;
  width: 16rem;
  border: 1px solid var(--overskyet-kontrast);
  align-items: center;

  :hover {
    box-shadow: 1px 0.9px 1px var(--overskyet);
  }
`;

const Input = styled.input.attrs({ type: 'text' })`
  background: inherit;
  border: none;
  width: 100%;
  font-size: var(--16px-rem);
  font-family: var(--din-light);
  padding-top: 0.25rem;

  @media (max-width: ${tabletBreakpoint}) {
    font-size: var(--14px-rem);
  }

  ::placeholder {
    color: var(--morgendis);
    padding-top: 0;
  }
  :active,
  :focus {
    outline: none;
  }
`;

const SearchIcon = styled(Icon)`
  width: 2rem;
  height: 2rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  stroke: var(--morgendis);
`;

interface CollectionSearchProps {
  setSearch: (search: string) => void;
}

export const CollectionSearchField = ({ setSearch }: CollectionSearchProps) => (
  <CollectionSearchContainer>
    <SearchIcon name="search" />
    <Input onChange={(event) => setSearch(event.target.value)} placeholder="Søk etter innlegg i samling" />
  </CollectionSearchContainer>
);
