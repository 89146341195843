import React from 'react';

import Icon, { IconName } from 'shared/Icon';
import { UndoButton } from 'shared/notification/UndoButton';
import styled, { css } from 'styled-components/macro';

type NotificationType = 'INFO' | 'WARNING' | 'ERROR';

type NotificationProps = {
  className?: string;
  type: NotificationType;
  title: string;
  message: string;
  isOpen?: boolean;
  onClose?: () => void;
  undoText?: string;
  deletedCollectionId?: number;
};

const Container = styled.div<{ background: string; isOpen: boolean }>`
  position: fixed;
  bottom: 0;
  left: 50%;
  z-index: 100;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: auto;
  padding-left: 5%;

  background: ${(props) => props.background};
  color: var(--sort);
  font-family: var(--din-light);
  min-width: calc(100% - 1.5rem);

  opacity: 0;
  transform: translate3d(-50%, 150%, 0);

  @media (max-width: 25em) {
    flex-direction: column;
  }

  ${(props) =>
    props.isOpen
      ? css`
          animation: notificationSlideIn 0.4s normal forwards;
          opacity: 1;

          @keyframes notificationSlideIn {
            0% {
              transform: translate3d(-50%, 100%, 0);
              opacity: 0;
            }

            50% {
              transform: translate3d(-50%, -10%, 0);
              opacity: 1;
            }

            100% {
              transform: translate3d(-50%, 0%, 0);
            }
          }
        `
      : css`
          animation: notificationSlideOut 0.4s ease-out normal forwards;
          opacity: 1;

          @keyframes notificationSlideOut {
            0% {
              transform: translate3d(-50%, 0%, 0);
            }

            20% {
              transform: translate3d(-50%, -10%, 0);
              opacity: 1;
            }

            100% {
              transform: translate3d(-50%, 150%, 0);
              opacity: 0;
            }
          }
        `};
`;

const Content = styled.div`
  width: 100%;
  margin: 0.8em 0;
  padding-left: 2rem;
`;

const Emoji = styled(Icon)<{ background: string }>`
  width: 100px;
  height: 100px;
  padding: 0.5rem;
  overflow: unset;

  stroke: var(--hvit);
  background: ${(props) => props.background && props.background};

  * {
    vector-effect: unset;
  }

  @media (max-width: 25em) {
    display: none;
  }
`;

const Title = styled.span`
  display: block;
  font-size: 1.2em;
  margin-block-start: 0.87em;
  margin-block-end: 0.2em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-family: var(--din-medium);
`;

const Message = styled.span`
  display: flex;
  flex-direction: row;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 4rem;
  gap: var(--05);
`;

const UndoContainer = styled.div`
  margin-top: -3px;
`;

const CloseButton = styled.button`
  cursor: pointer;
  background: var(--natt);
  color: var(--hvit);
  border: none;
  padding: 0.8em;
  font: inherit;
`;

type TypeStyle = {
  name: IconName;
  background: string;
  emojiBackground: string;
};

const typeStyles: Record<NotificationType, TypeStyle> = {
  INFO: {
    name: 'emoji-smile',
    background: 'var(--grønn)',
    emojiBackground: 'var(--grønn-kontrast)',
  },
  WARNING: {
    name: 'emoji-neutral',
    background: 'var(--soloppgang)',
    emojiBackground: 'var(--soloppgang-kontrast)',
  },
  ERROR: {
    name: 'emoji-frown',
    background: 'var(--solnedgang)',
    emojiBackground: 'var(--solnedgang-kontrast)',
  },
};

const Notification = ({
  className,
  type,
  title,
  message,
  onClose,
  isOpen = false,
  undoText,
  deletedCollectionId,
}: NotificationProps) => {
  return (
    <Container className={className} isOpen={isOpen} background={typeStyles[type].background}>
      <Emoji name={typeStyles[type].name} background={typeStyles[type].emojiBackground} />
      <Content>
        <Title>{title}</Title>
        <Message>
          {message}
          <UndoContainer>
            {undoText && deletedCollectionId && <UndoButton text={undoText} collectionId={deletedCollectionId} />}
          </UndoContainer>
        </Message>
      </Content>
      {onClose && (
        <div>
          <CloseButton onClick={onClose}>Lukk</CloseButton>
        </div>
      )}
    </Container>
  );
};

export default Notification;
