import React, { createContext, useEffect, useState } from 'react';

import { useKeepScrollPosition } from 'landing/useKeepScrollPosition';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ScrollToTopButton } from 'shared/button/ScrollToTopButton';
import { anyFiltersIsActive, searchState } from 'stateAndApi/searchStates';
import styled from 'styled-components/macro';

import CompetencePlanFeed from './competencePlanFeed/CompetencePlanFeed';
import { LandingHeader } from './header/LandingHeader';
import { LandingFeed } from './landingFeed/LandingFeed';
import { SearchFeed } from './searchFeed/SearchFeed';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export enum Tab {
  Feed = 'Feed',
  Kompetanseplan = 'Kompetanseplan',
}

type TabContextType = {
  activeTab: Tab;
  setActiveTab: React.Dispatch<React.SetStateAction<Tab>>;
};

export const TabContext = createContext<TabContextType>({
  activeTab: Tab.Feed,
  setActiveTab: () => {},
});

const getContentByTab = (tab: Tab) => {
  switch (tab) {
    case Tab.Feed:
      return <LandingFeed />;
    case Tab.Kompetanseplan:
      return <CompetencePlanFeed />;
  }
};

export const Landing = () => {
  const [activeTab, setActiveTab] = useState(Tab.Feed);
  const isThereAnyActiveFilters = useRecoilValue(anyFiltersIsActive);
  useKeepScrollPosition('scroll_landing', window);

  // Only need the setter
  const setSearchTerm = useRecoilState<string>(searchState)[1];

  useEffect(() => {
    setSearchTerm('');
    /* eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [activeTab]);

  return (
    <>
      <ScrollToTopButton />
      <TabContext.Provider value={{ activeTab, setActiveTab }}>
        <Container>
          <LandingHeader tab={activeTab} />
          {isThereAnyActiveFilters ? <SearchFeed tab={activeTab} /> : getContentByTab(activeTab)}
        </Container>
      </TabContext.Provider>
    </>
  );
};
