import React from 'react';

import { PostCardWithHotkeys } from 'landing/PostCardWithHotkeys';
import styled from 'styled-components/macro';
import { Post } from 'types/postTypes';
import { tabletBreakpoint } from 'utils/constants';

import { CardSize } from '../../../shared/card/cardSizes';

const FeedContainer = styled.div`
  display: flex;
  gap: var(--4);
  margin-top: var(--4);
  margin-bottom: var(--8);

  @media (max-width: ${tabletBreakpoint}) {
    display: flex;
    margin-top: var(--4);
    margin-bottom: var(--6);
    gap: var(--7);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

const MainPostContainer = styled.div`
  flex: 1;
`;

const MiniFeed = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 0.8;
  gap: var(--3) var(--4);
  justify-content: space-between;

  @media (max-width: ${tabletBreakpoint}) {
    display: block;
    width: 100%;
  }
`;

const SmallBlockCardContainer = styled.div`
  width: 45%;

  @media (max-width: ${tabletBreakpoint}) {
    width: 100%;

    &:nth-child(n + 2) {
      display: none;
    }
  }
`;

interface Props {
  posts: Post[];
  mainPost: Post;
}

export const BlockFeed = ({ posts, mainPost }: Props) => (
  <FeedContainer>
    <MainPostContainer>
      <PostCardWithHotkeys post={mainPost} cardSize={CardSize.LARGE} />
    </MainPostContainer>
    <MiniFeed>
      {posts.map((post) => (
        <SmallBlockCardContainer key={post.id}>
          <PostCardWithHotkeys post={post} key={post.id} />
        </SmallBlockCardContainer>
      ))}
    </MiniFeed>
  </FeedContainer>
);
