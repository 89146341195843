import { useContext } from 'react';

import NotificationContext from './notificationContext';

export const useNotification = () => {
  const { setNotification } = useContext(NotificationContext);

  const notifyInfo = (
    title: string,
    message: string,
    duration?: number,
    undoText?: string,
    deletedCollectionId?: number
  ) => {
    setNotification({ type: 'INFO', title, message, undoText, deletedCollectionId });
    if (duration) {
      setTimeout(clearNotification, duration);
      return;
    }
    setTimeout(clearNotification, 2700);
  };

  const notifyWarning = (title: string, message: string) => setNotification({ type: 'WARNING', title, message });
  const notifyError = (title: string, message: string) => setNotification({ type: 'ERROR', title, message });
  const clearNotification = () => setNotification(undefined);

  return { notifyInfo, notifyWarning, notifyError, clearNotification };
};
