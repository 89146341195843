import React, { RefObject, useRef, useState } from 'react';
import Modal from 'react-modal';

import { useTooltipTrigger } from '@react-aria/tooltip';
import { TooltipTriggerState, useTooltipTriggerState } from '@react-stately/tooltip';
import { TooltipTriggerProps } from '@react-types/tooltip';
import Icon from 'shared/Icon';
import { Tooltip } from 'shared/Tooltip';
import Button from 'shared/button/Button';
import { ExitButton } from 'shared/button/ExitButton';
import styled from 'styled-components/macro';
import { mobileBreakpoint, tabletBreakpoint } from 'utils/constants';

import { ButtonWithHover } from './ButtonWithHover';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: var(--1);
  gap: var(--3);

  @media (max-width: ${tabletBreakpoint}) {
    padding: 0;
  }
`;

const Label = styled.label`
  display: block;
  text-align: left;
  font-size: var(--20px-rem);
  font-family: var(--din-medium);
`;

const Description = styled.p`
  font-size: var(--22px-rem);
  font-family: var(--din-medium);
  margin: 0;
`;

const ExitModalButton = styled(ExitButton)`
  position: absolute;
  top: var(--2);
  right: var(--2);
`;

const ModalStyle = styled.div`
  display: block !important;
  height: auto !important;
  width: auto !important;
  top: 50% !important;
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  transform: translate(-50%, -50%) !important;

  @media (max-width: ${tabletBreakpoint}) {
    width: 90% !important;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: var(--2);
`;

const DeleteIcon = styled(Icon).attrs({ name: 'delete' })`
  height: 100%;
`;

const DeleteButtonContainer = styled.span`
  position: relative;
`;

const StyledButtonWithHover = styled(ButtonWithHover)`
  border: none;
  @media (max-width: ${mobileBreakpoint}) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

type Props = {
  warningText: string;
  className?: string;
  tooltip?: string;
  deleteFunction: () => void;
  children?: React.ReactNode;
  ref?: React.ReactNode;
};

export const DeleteButtonWithWarning = ({
  warningText,
  className,
  deleteFunction,
  children,
  tooltip = 'Slett',
}: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const props: TooltipTriggerProps = {
    isDisabled: false,
    delay: 400,
  };
  const state: TooltipTriggerState = useTooltipTriggerState(props);
  const buttonRef: RefObject<HTMLButtonElement> = useRef<HTMLButtonElement>(null);
  const { triggerProps, tooltipProps } = useTooltipTrigger(props, state, buttonRef);

  function openModal() {
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
  }
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentElement={(props, children) => <ModalStyle {...props}>{children}</ModalStyle>}
      >
        <Container id="delete-button-container">
          <ExitModalButton onClick={closeModal} />
          <Label>Advarsel</Label>

          <Description>{warningText}</Description>
          <ButtonContainer>
            <Button
              onClick={() => {
                deleteFunction();
                closeModal();
              }}
              variant="white"
            >
              Ja
            </Button>
            <Button onClick={closeModal} variant="primary">
              Nei
            </Button>
          </ButtonContainer>
        </Container>
      </Modal>
      {children ? (
        <div className={className} onClick={openModal}>
          {children}
        </div>
      ) : (
        <DeleteButtonContainer>
          <StyledButtonWithHover
            className={className}
            onClick={openModal}
            strokeColor="var(--hvit)"
            hoverColor="var(--sort)"
            type="button"
            tooltipTriggerProps={triggerProps}
            buttonRef={buttonRef}
          >
            <DeleteIcon />
          </StyledButtonWithHover>

          {state.isOpen && (
            <Tooltip state={state} props={tooltipProps} buttonWidth={48}>
              {tooltip}
            </Tooltip>
          )}
        </DeleteButtonContainer>
      )}
    </>
  );
};
