import React, { CSSProperties, ReactNode } from 'react';

import styled from 'styled-components/macro';

import { mobileBreakpoint, tabletBreakpoint } from '../../utils/constants';

import { CardSize, CardSizeStyling } from './cardSizes';

export const SIZES: CardSizeStyling = {
  small: {
    '--grid-column-desktop-cardcontainer': 'span 2',
    '--grid-column-tablet-cardcontainer': 'span 3',
    '--opacity-cardcontainer': '0',
    '--transform-cardcontainer': 'translateY(100px)',
    '--transition-cardcontainer': '0.8s ease',
  },
  medium: {
    '--grid-column-desktop-cardcontainer': 'span 3',
    '--grid-column-tablet-cardcontainer': 'span 3',
    '--opacity-cardcontainer': '0',
    '--transform-cardcontainer': 'translateY(100px)',
    '--transition-cardcontainer': '0.8s ease',
  },
  large: {
    // Large cards are not animated
    '--grid-column-desktop-cardcontainer': 'span 6',
    '--grid-column-tablet-cardcontainer': 'span 6',
    '--opacity-cardcontainer': '1',
    '--transform-cardcontainer': 'none',
    '--transition-cardcontainer': 'none',
  },
};

export const Container = styled.div<{ visible?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--1);

  text-decoration: none;
  color: inherit;
  word-break: break-word;

  grid-column: var(--grid-column-desktop-cardcontainer);

  opacity: ${({ visible }) => (visible ? 1 : 'var(--opacity-cardcontainer)')};
  transform: ${({ visible }) => (visible ? 'none' : 'var(--transform-cardcontainer)')};
  transition: var(--transition-cardcontainer);

  @media (max-width: ${tabletBreakpoint}) {
    grid-column: var(--grid-column-tablet-cardcontainer);
  }

  @media (max-width: ${mobileBreakpoint}) {
    grid-column: span 6;
  }
`;

interface Props {
  visible?: boolean;
  cardSize: CardSize;
  children: ReactNode;
}

export const CardContainer = React.forwardRef(
  ({ visible, cardSize, children }: Props, ref: React.Ref<HTMLDivElement>) => {
    const styles = SIZES[cardSize] as CSSProperties;

    return (
      <Container visible={visible} style={styles} ref={ref}>
        {children}
      </Container>
    );
  }
);
