import React, { useEffect, useRef } from 'react';

import styled from 'styled-components/macro';

import InputText from '../../shared/input/InputText';

interface InputProps {
  onChange: (s: string) => void;
  value: string;
  label: string;
  validateInput?: (s: string) => boolean;
}

export const TextFieldContainer = styled.div`
  width: 100%;
  text-align: left;

  :first-child {
    margin-top: 0;
  }

  .textFieldInvalid input {
    background-color: #ffcccc;
  }
`;

export const TextFieldLabel = styled.label`
  color: var(--morgendis);
  font-size: var(--14px-rem);
  display: block;
  margin-top: var(--1);
  margin-bottom: var(--05);
  font-family: var(--din-light);
`;

interface UrlInputProps extends InputProps {
  urlIsValidated: undefined | boolean;
}

export const UrlInput = ({ onChange, label, value, urlIsValidated }: UrlInputProps) => {
  const urlInputRef = useRef<any>();
  useEffect(() => {
    if (!urlIsValidated) {
      urlInputRef.current &&
        urlInputRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest',
        });
    }
  }, [urlIsValidated, urlInputRef]);

  return (
    <TextFieldContainer ref={!urlIsValidated ? urlInputRef : null}>
      <TextFieldLabel>{label}</TextFieldLabel>
      <div className={urlIsValidated === false ? 'textFieldInvalid' : ''}>
        <InputText value={value !== null && value !== undefined ? value : ''} onChange={onChange} />
      </div>
    </TextFieldContainer>
  );
};
