import React, { useEffect, useState } from 'react';

import { getCompetencePlanPreviews } from 'competencePlan/utils/competenceApi';
import { CardFeedGrid } from 'shared/feed/CardFeedGrid';
import { FeedContainer } from 'shared/feed/Feed';
import { getFeedComponentToRender } from 'shared/feedpage/utils';
import { CompetencePlanPreview } from 'types/competencePlanTypes';

import TopThreeCompetencePlan from './TopThreeCompetencePlanFeed';

const CompetencyPlanFeed = () => {
  const [competencePlanPreviews, setCompetencePlanPreviews] = useState<CompetencePlanPreview[]>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getCompetencePlanPreviews().then((competencePlans) => {
      setCompetencePlanPreviews(competencePlans);
      setIsLoading(false);
    });
  }, []);

  if (isLoading || !competencePlanPreviews) {
    return null;
  }

  return (
    <>
      <TopThreeCompetencePlan listOfThree={competencePlanPreviews.slice(0, 3)} />
      <FeedContainer>
        <CardFeedGrid>{competencePlanPreviews.slice(3).map(getFeedComponentToRender)}</CardFeedGrid>
      </FeedContainer>
    </>
  );
};

export default CompetencyPlanFeed;
