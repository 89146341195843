import React from 'react';

import styled from 'styled-components/macro';

export const formatConsumptionTime = (seconds: number | undefined, shortForm: boolean) => {
  if (!seconds) return null;

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.ceil((seconds - hours * 3600) / 60);

  return shortForm ? formatConsumptionTimeShortForm(hours, minutes) : formatConsumptionTimeLongForm(hours, minutes);
};

const formatConsumptionTimeLongForm = (hours: number, minutes: number) => {
  const minuteSuffix = minutes === 1 ? 'minutt' : 'minutter';

  if (hours > 0) {
    const hourSuffix = hours === 1 ? 'time' : 'timer';
    return `${hours} ${hourSuffix} og ${minutes} ${minuteSuffix}`;
  } else {
    return `${minutes} ${minuteSuffix}`;
  }
};

const formatConsumptionTimeShortForm = (hours: number, minutes: number) => {
  if (hours > 0) {
    return `${hours} t og ${minutes} min`;
  } else {
    return `${minutes} min`;
  }
};

export const MediaTimeSpan = styled.span`
  padding: 0.1rem;
  white-space: nowrap;
`;

interface Props {
  consumptionTime: number | null;
  shortForm?: boolean;
}

export const MediaTime = ({ consumptionTime, shortForm = false }: Props) => {
  if (!consumptionTime) return null;
  const formattedConsumptionTime = formatConsumptionTime(consumptionTime, shortForm);

  return <MediaTimeSpan>{formattedConsumptionTime}</MediaTimeSpan>;
};

export default MediaTime;
