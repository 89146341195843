/* eslint-disable no-inline-styles/no-inline-styles */
import styled from 'styled-components/macro';
import { contentWidth, tabletBreakpoint } from 'utils/constants';

export const FeedContainer = styled.div`
  max-width: ${contentWidth};
  width: 100%;
  margin: 0 auto;
  padding: var(--3) var(--4) var(--6);

  @media (max-width: ${tabletBreakpoint}) {
    padding: var(--2);
    margin-bottom: var(--10);
  }
`;
