import React, { useContext } from 'react';

import { Tab, TabContext } from 'landing/Landing';
import styled from 'styled-components/macro';
import { SearchSuggestion } from 'types/searchTypes';
import { mobileBreakpoint, tabletBreakpoint } from 'utils/constants';

import { SearchDropDownContentSection } from './SearchDropDownContentSection';
import { SearchDropDownPersonSection } from './SearchDropDownPersonSection';
import { SearchDropDownTagSection } from './SearchDropDownTagSection';

interface SearchDropDownProps {
  searchSuggestions: SearchSuggestion;
  query: string;
  showDropDown: boolean;
}

export const SearchDropDown = ({ searchSuggestions, query, showDropDown }: SearchDropDownProps) => {
  const { activeTab } = useContext(TabContext);
  return (
    <DropDownContainer showDropDown={showDropDown} tabIndex={1} color={getTabColor(activeTab)}>
      <ContentContainer>
        <SearchDropDownContentSection
          searchSuggestions={searchSuggestions}
          query={query}
          createDisplayTitle={createDisplayTitle}
        />
        <SearchDropDownTagSection searchSuggestions={searchSuggestions} />
        <SearchDropDownPersonSection
          searchSuggestions={searchSuggestions}
          createDisplayTitle={createDisplayTitle}
          query={query}
        />
      </ContentContainer>
    </DropDownContainer>
  );
};

const getTabColor = (activeTab: Tab) => {
  return activeTab === Tab.Feed ? 'var(--lighter-primary)' : 'var(--previous-ligther-primary)';
};

const createDisplayTitle = (title: string, search: string) => {
  const getSubString = (w1: string, searchWords: string[]) => {
    const w1LowerCase = w1.toLowerCase();
    const w2 = searchWords.find((word2) => w1LowerCase.toLowerCase().includes(word2.toLowerCase()))?.toLowerCase();
    if (!w2) return null;
    return [w1LowerCase.indexOf(w2), w1LowerCase.indexOf(w2) + w2.length];
  };

  const boldSearchWords = (word: string, searchWords: string[]) => {
    const idxs = getSubString(word, searchWords);
    if (!idxs) return word;
    return `${word.substring(0, idxs[0])}<b>${word.substring(idxs[0], idxs[1])}</b>${word.substring(idxs[1])}`;
  };

  const words = title.split(' ');
  const search_words = search.split(' ');
  return words.map((word) => boldSearchWords(word, search_words)).join(' ');
};

const DropDownContainer = styled.div<{ showDropDown: boolean; color: string }>`
  position: absolute;
  width: 100%;
  left: auto;
  /* Need exactly 49px/3.06rem to place the dropdown at the bottom of search input */
  top: 3.06rem;
  height: ${({ showDropDown }) => (showDropDown ? 'fit-content' : '0')};
  overflow: hidden;
  transition: height 0.3s;
  z-index: 10;
  background: ${({ color }) => color};
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: none;
  border-bottom-width: ${({ showDropDown }) => (showDropDown ? '1px' : '0')};
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;

  box-shadow: ${({ showDropDown }) => (showDropDown ? '0 2px 0px 0px rgba(0, 0, 0, 0.2)' : 'none')};

  @media (max-width: ${mobileBreakpoint}) {
    /* Need exactly 41px/2.56rem to place the dropdown at the bottom of search input */
    top: 2.56rem;
  }
`;

const ContentContainer = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SuggestionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  @media (max-width: ${tabletBreakpoint}) {
    font-size: var(--12px-rem);
    gap: 0.5rem;
  }
`;

export const DropDownSectionHeader = styled.span`
  font-weight: 600;
  font-size: var(--14px-rem);
  margin-bottom: 1rem;
`;

export const EmployeesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
