import React from 'react';

import { EmployeeButton } from 'shared/EmployeeButton';
import { EmployeeImage } from 'shared/EmployeeImage';
import styled from 'styled-components/macro';
import { Employee } from 'types/employeeTypes';

type Props = {
  employee: Employee;
};

export const ContributorWithDepartment = ({ employee }: Props) => {
  return (
    <ContributorContainer>
      <ContributorImage employee={employee} />
      <NameAndExtraTextContainer>
        <EmployeeButton value={employee.id.toString()} label={employee.name} />
        <Department>{employee.department}</Department>
      </NameAndExtraTextContainer>
    </ContributorContainer>
  );
};

const ContributorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--2);
  width: 100%;
`;

const ContributorImage = styled(EmployeeImage)`
  width: var(--6);
  height: var(--6);
`;

const Department = styled.span`
  font-size: var(--12px-rem);
  font-family: var(--din-regular);
`;

const NameAndExtraTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
