import React, { useRef, useState } from 'react';

import Icon from 'shared/Icon';
import { InternalLink } from 'shared/InternalLink';
import { Portal } from 'shared/Portal';
import { useNotification } from 'shared/notification/useNotification';
import styled from 'styled-components/macro';
import { Post } from 'types/postTypes';

import { CollectionMenuItem } from './CollectionMenuItem';

const Container = styled.div`
  position: relative;
`;

const MoreIcon = styled(Icon).attrs({ name: 'more' })`
  width: 2.5rem;
  height: 2.5rem;
`;

const Button = styled.button`
  background: none;
  border: none;
  display: flex;
  padding: 0;
  cursor: pointer;
  border-radius: 100vmax;
  transition: 0.2s;

  &:hover,
  &:focus-visible {
    background: var(--primary);
  }
`;

const Menu = styled.div<{ isOpen: boolean; top: number; left: number }>`
  background: var(--hvit);
  text-align: left;
  position: absolute;
  top: ${({ top }) => `${top}px`};
  left: ${({ left }) => `${left}px`};
  border-radius: var(--05);
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  box-shadow: 0 1px 8px 0 var(--overskyet-kontrast);
`;

const MenuItem = styled.button`
  font-family: var(--din-light);
  font-size: var(--16px-rem);
  background: none;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: var(--25);
  padding: var(--2);
  cursor: pointer;
  transition: 0.1s;

  &:first-child {
    border-radius: var(--05) var(--05) 0 0;
  }

  &:hover,
  &:focus-visible {
    background: var(--primary);
  }
`;

const MenuLinkItem = styled(InternalLink)`
  transition: 0.1s;
  padding: var(--2);
  width: var(--25);
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: var(--sort);

  &:first-child {
    border-radius: var(--05) var(--05) 0 0;
  }

  &:hover,
  &:focus-visible {
    background: var(--primary);
  }
`;

const EditIcon = styled(Icon).attrs({ name: 'edit' })`
  transition: transform 0.2s;
`;

const EditMenuItem = styled(MenuLinkItem)`
  &:hover ${EditIcon}, &:focus-visible ${EditIcon} {
    transform: rotate(-12deg);
  }
`;

export const TrashIcon = styled(Icon).attrs({ name: 'delete' })`
  & #lid {
    transform-origin: center;
    transition: transform 0.2s;
  }
`;

export const DeleteMenuItem = styled(MenuItem)`
  &:hover #lid,
  &:focus-visible #lid {
    transform: translateY(-3px) rotate(-10deg);
  }
`;

const ArrowIcon = styled(Icon).attrs({ name: 'arrow-short' })`
  transition: transform 0.2s;
`;

const DetailMenuItem = styled(MenuLinkItem)`
  &:hover ${ArrowIcon}, &:focus-visible ${ArrowIcon} {
    transform: translateX(3px);
  }
`;

const CopyIcon = styled(Icon).attrs({ name: 'copy' })`
  #arrow {
    transition: transform 0.2s;
  }
`;

const CopyMenuItem = styled(MenuItem)`
  &:hover #arrow,
  &:focus-visible #arrow {
    transform: translateY(-3px);
  }
`;

const PlusIcon = styled(Icon).attrs({ name: 'plus' })`
  transition: transform 0.2s;
`;

export const AddToCollectionMenuItem = styled(MenuItem)<{ isOpen: boolean }>`
  position: relative;
  background: ${({ isOpen }) => (isOpen ? 'var(--primary)' : 'var(--hvit)')};
  border-radius: 0 0 var(--05) var(--05);
  ${PlusIcon} {
    transform: ${({ isOpen }) => (isOpen ? 'rotate(90deg)' : 'none')};
  }

  &:hover ${PlusIcon}, &:focus-visible ${PlusIcon} {
    transform: rotate(90deg);
  }
`;

interface Props {
  route: string;
  post: Post;
  children?: React.ReactNode;
}

export const MoreButton = ({ route, post, children }: Props) => {
  /*
    "Dropdown"-menyens posisjon er dynamisk, ettersom den teleporteres med <Portal> ut av sin originale posisjon.
    Dermed trenger vi en state for å kalkulere hvor ny posisjon er.
  */
  const [buttonPosition, setButtonPosition] = useState({ top: 0, left: 0 });

  const [openMenu, setOpenMenu] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const menuContainerRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const { notifyInfo } = useNotification();

  const toggleMenu = () => {
    calculateAndSetDropdownPosition();
    setOpenMenu(!openMenu);
    if (!openMenu) {
      setOpenSubMenu(false);
    }

    function calculateAndSetDropdownPosition() {
      if (menuContainerRef.current) {
        const rect = menuContainerRef.current.getBoundingClientRect();
        setButtonPosition({
          top: rect.bottom + window.scrollY,
          left: rect.left + window.scrollX,
        });
      }
    }
  };

  /*
    Hvis man klikker på valget "Fjern innlegg" i dropdownmenyen åpnes det en modal for å bekrefte valget.
    For at klikk i modalen ikke skal registreres som isClickOutsideOfMenu = false, har det blitt lagt på id "delete-button-container",
    slik at den verdien kan brukes i betingelsen her.

    Løsningen er litt stygg, men problemet er DeleteMenuItemWithWarning brukes som ikke-navngitt children,
    så det går ikke ann å passe ned en ref i propsene.
    TODO: Finne en bedre løsning :^)
  */
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const isClickOutsideOfMenu =
        !menuRef.current?.contains(event.target as Node) && !document.getElementById('delete-button-container');
      if (isClickOutsideOfMenu) {
        setOpenMenu(false);
        setOpenSubMenu(false);
      }
    };

    document.addEventListener('mouseup', handleClickOutside);

    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, []);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(window.location.origin + route).then(() => {
      setOpenMenu(false);
      setOpenSubMenu(false);
      notifyInfo('Info', 'Kopiert til utklippstavle');
    });
  };

  return (
    <Container ref={menuContainerRef}>
      <Button title="Mer" onClick={toggleMenu}>
        <MoreIcon />
      </Button>
      <Portal shouldRenderInPortal={openMenu}>
        <Menu ref={menuRef} isOpen={openMenu} top={buttonPosition.top} left={buttonPosition.left}>
          {children}
          <EditMenuItem to={`${route}/rediger`}>
            <span>Rediger innlegg</span>
            <EditIcon />
          </EditMenuItem>
          <DetailMenuItem to={route}>
            <span>Gå til detaljeside</span>
            <ArrowIcon />
          </DetailMenuItem>
          <CopyMenuItem onClick={copyToClipboard}>
            <span>Kopier lenke</span>
            <CopyIcon />
          </CopyMenuItem>
          <CollectionMenuItem post={post} isOpen={openSubMenu} setOpenMenu={setOpenSubMenu}>
            <span>Flytt til samling</span>
            <PlusIcon />
          </CollectionMenuItem>
        </Menu>
      </Portal>
    </Container>
  );
};
