export const sanitizeTitleForUrlUsage = (title: string): string => {
  if (title === '') return '';
  return encodeURI(
    title
      .toLowerCase()
      .replaceAll(' ', '-')
      .replaceAll('?', '')
      .replaceAll('!', '')
      .replaceAll('.', '')
      .replaceAll('%', '')
      .replaceAll('/', '-')
      .replaceAll('#', ' ')
  );
};
