import React from 'react';

import Icon from 'shared/Icon';
import styled from 'styled-components/macro';

const ShareToSlackInfo = () => {
  return (
    <InfoContainer>
      <StyledIcon name="slack_icon" />
      <Text>
        Innlegget vil også bli delt i <SlackChannel>#atlas</SlackChannel> på Slack
      </Text>
    </InfoContainer>
  );
};

export default ShareToSlackInfo;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
`;

const StyledIcon = styled(Icon)`
  width: 2rem;
  height: 2rem;
  margin-bottom: 0.5rem;
`;

const Text = styled.span`
  font-size: 1rem;
  font-weight: bold;
`;

const SlackChannel = styled.span`
  background: #e7f5fa;
  border-radius: 2px;
  padding: 4px 0 4px 2px;
  color: #1566a4;
`;
