import React from 'react';

import { Container } from 'shared/card/CardContainer';
import { FeedContainer } from 'shared/feed/Feed';
import styled from 'styled-components/macro';
import { fadeAnimation } from 'utils/commonAnimations';
import { mobileBreakpoint, tabletBreakpoint } from 'utils/constants';

import { RenderTypes, getFeedComponentToRender } from './utils';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3.25fr 1fr;
  grid-template-rows: auto auto;
  column-gap: 3rem;
  row-gap: 2rem;

  animation: ${fadeAnimation};

  & > ${Container} {
    &:first-child {
      grid-column: 1;
      grid-row: span 2;
    }

    grid-column: 2;
  }

  @media (max-width: ${tabletBreakpoint}) {
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    row-gap: 5rem;

    & > ${Container} {
      &:first-child {
        grid-column: span 2;
        grid-row: unset;
      }

      grid-column: unset;

      &:last-child {
        margin-top: unset;
      }
    }
  }

  @media (max-width: ${mobileBreakpoint}) {
    grid-template-columns: unset;

    & > ${Container} {
      &:first-child {
        grid-column: 1;
      }

      grid-column: 1;

      &:last-child {
        margin-top: unset;
      }
    }
  }
`;

interface Props<T extends { id: number; type: RenderTypes }> {
  threeNewest: T[];
}

export const TopThree = <T extends { id: number; type: RenderTypes }>({ threeNewest }: Props<T>) => (
  <FeedContainer>
    <Grid>{threeNewest.map((element, i) => getFeedComponentToRender(element, i))}</Grid>
  </FeedContainer>
);
