import React, { useState } from 'react';

import styled from 'styled-components/macro';

import { getCollectionsLight } from '../collection/utils/collectionApi';
import { CollectionMetadataLight } from '../types/collectionTypes';

import { SingleLineClamp } from './LineClamp';

const ItemContainerToFixEllipsis = styled.div`
  &:first-child {
    border-radius: var(--05) var(--05) 0 0;
  }
  &:last-child {
    border-radius: 0 0 var(--05) var(--05);
  }

  &:hover,
  &:focus-visible {
    background: var(--primary);
  }
`;

const CollectionPickerItem = styled.button`
  font-family: var(--din-light);
  font-size: var(--16px-rem);
  text-align: left;
  background: none;
  outline: none;
  border: none;
  padding: var(--1-05);
  cursor: pointer;
  transition: 0.1s;
  width: 100%;
  ${SingleLineClamp}
`;

const Text = styled.span`
  padding: var(--2) var(--2) var(--1);
  font-size: var(--12px-rem);
  color: var(--morgendis);
`;

const TextWithBorder = styled(Text)`
  border-top: 1px solid var(--overskyet);
`;

// Chronologically sort everything by creation and puts the employees collections on top of the list
const sortCollections = (collections: CollectionMetadataLight[] | undefined, employeeId: number) => {
  if (!collections) return null;
  const clone = [...collections];
  clone.sort((a, b) => {
    const aCollection = a.author_id === employeeId ? 1 : 0;
    const bCollection = b.author_id === employeeId ? 1 : 0;
    if (aCollection > bCollection) {
      return -1;
    } else if (aCollection === bCollection) {
      return b.id!! - a.id!!;
    } else {
      return 1;
    }
  });
  return clone;
};

interface Props {
  myId: number;
  onClickCollectionItem(collection: CollectionMetadataLight): void;
  isOpen: boolean;
}

const CollectionPickerItems = ({ myId, onClickCollectionItem, isOpen }: Props) => {
  const [collections, setCollections] = useState<CollectionMetadataLight[]>();
  const sortedCollections = sortCollections(collections, myId);

  if (isOpen && !collections) {
    getCollectionsLight().then((collections) => setCollections(collections));
  }

  if (!sortedCollections) return null;
  const myCollections = sortedCollections.filter((c) => c.author_id === myId);
  const otherCollections = sortedCollections.filter((c) => c.author_id !== myId);

  const myCollectionItems = myCollections.map((collection) => (
    <ItemContainerToFixEllipsis key={collection.id}>
      <CollectionPickerItem onClick={() => onClickCollectionItem(collection)}>{collection.title}</CollectionPickerItem>
    </ItemContainerToFixEllipsis>
  ));

  const otherCollectionItems = otherCollections.map((collection) => (
    <ItemContainerToFixEllipsis key={collection.id}>
      <CollectionPickerItem onClick={() => onClickCollectionItem(collection)}>{collection.title}</CollectionPickerItem>
    </ItemContainerToFixEllipsis>
  ));

  return (
    <>
      {myCollections.length > 0 && <Text>Mine samlinger</Text>}
      {myCollectionItems}
      {otherCollections.length > 0 && <TextWithBorder>Samlinger</TextWithBorder>}
      {otherCollectionItems}
    </>
  );
};

export default CollectionPickerItems;
