import React from 'react';

import { getFilterLabel } from 'landing/header/filter/filterLabels';
import { useRecoilValue } from 'recoil';
import Icon from 'shared/Icon';
import { employeeRecordState } from 'stateAndApi/employeeState';
import { FilterKey, FilterType, useSetFilters } from 'stateAndApi/searchStates';
import styled from 'styled-components/macro';
import { EmployeeRecord } from 'types/employeeTypes';
import { mobileBreakpoint, tabletBreakpoint } from 'utils/constants';

interface Props {
  filterType: FilterType;
}

const ActiveFilterButton = ({ filterType }: Props) => {
  const setFilters = useSetFilters();
  const employeeRecord = useRecoilValue<EmployeeRecord>(employeeRecordState);

  const onClickRemoveFilter = (f: FilterType) => {
    setFilters({ action: 'DeactivateFilter', filter: f });
  };

  const iconName = filterTypeIconName(filterType);

  return (
    <Container backgroundColor={filterTypeBackgroundColor[filterType.key]}>
      {iconName && <FilterTypeIcon name={iconName} />}
      <FilterTypeText>{getFilterLabel(filterType, employeeRecord)}</FilterTypeText>
      <StyledButtonDelete onClick={() => onClickRemoveFilter(filterType)}>
        <DeleteIcon name="cross" />
      </StyledButtonDelete>
    </Container>
  );
};

export default ActiveFilterButton;

const Container = styled.div<{ backgroundColor: string }>`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  border: none;
  border-radius: 8px;
  background: ${(props) => `${props.backgroundColor}`};
  padding: 0 var(--1);
  font-size: var(--16px-rem);
  white-space: nowrap;

  @media (max-width: ${tabletBreakpoint}) {
    gap: 0.2rem;
    font-size: var(--14px-rem);
    padding: 0 var(--05);
  }

  @media (max-width: ${mobileBreakpoint}) {
    gap: 0.1rem;
    font-size: var(--12px-rem);
    padding: 0 var(--2px);
  }
`;

const FilterTypeText = styled.div`
  line-height: initial;
  color: rgba(0, 0, 0, 0.7);
`;

const StyledButtonDelete = styled.button`
  display: flex;
  border: none;
  background: none;
  padding: 0;
  &:hover,
  &:focus-visible {
    cursor: pointer;
  }
`;

const FilterTypeIcon = styled(Icon)`
  stroke: rgba(25, 25, 25, 0.5);
  width: 1.5rem;

  @media (max-width: ${tabletBreakpoint}) {
    width: 1.3rem;
  }
`;

const DeleteIcon = styled(Icon)`
  stroke: rgba(25, 25, 25, 0.5);
  width: 1.2rem;
  &:hover {
    stroke: black;
    stroke-width: var(--1px);
  }

  @media (max-width: ${tabletBreakpoint}) {
    width: 1rem;
  }
`;

const filterTypeBackgroundColor: Record<FilterKey, string> = {
  Media: 'rgba(255, 153, 153, 50%)',
  External: 'rgba(255, 242, 173, 50%)',
  Tag: 'rgba(161, 245, 227, 50%)',
  Employee: 'rgba(229, 177, 255, 50%)',
};

const filterTypeIconName = (filterType: FilterType) => {
  if (filterType.key === 'Tag') return 'tag';
  if (filterType.key === 'Media') {
    if (filterType.value === 'other') return 'squares';
    else return filterType.value;
  }
  if (filterType.key === 'External') {
    if (filterType.value === 'external') return 'globe';
    else return 'bekk';
  }
  if (filterType.key === 'Employee') return 'globe';
};
