import React from 'react';

import styled from 'styled-components/macro';

import { Employee } from '../../types/employeeTypes';
import { EmployeeButton } from '../EmployeeButton';
import { SingleLineClamp } from '../LineClamp';

const Names = styled.span`
  ${SingleLineClamp}
`;

interface Props {
  employees: Employee[];
  /** The maximal number of names to show before using "og n andre". Should be >= 2. */
  maxNumberOfNames?: number;
  /** A description of the contributor, added before the names. E.g. "Delt av" */
  descriptionText?: string;
}

export const ContributorNames = ({ employees, descriptionText, maxNumberOfNames = 2 }: Props) => {
  if (maxNumberOfNames < 2) {
    throw new Error('This component displays at least two contributors.');
  }

  if (employees.length < 1) {
    return null;
  }

  const commaSeparatedNames = () => {
    const tooMany = employees.length - maxNumberOfNames;
    const employeesToDisplay = employees.slice(0, maxNumberOfNames);
    return employeesToDisplay.map((contributor, index) => {
      const employeeBadge = <EmployeeButton value={contributor.id.toString()} label={contributor.name} key={index} />;
      if (index === 0) {
        return employeeBadge;
      } else if (index === employeesToDisplay.length - 1) {
        if (tooMany > 0) {
          return <span key={index}> og {tooMany + 1} andre</span>;
        }
        return (
          <span key={index}>
            <span> og </span> {employeeBadge}
          </span>
        );
      } else {
        return (
          <span key={index}>
            <span>, </span> {employeeBadge}
          </span>
        );
      }
    });
  };

  return (
    <Names>
      {descriptionText} {commaSeparatedNames()}
    </Names>
  );
};
