import React, { ReactNode } from 'react';

import styled from 'styled-components/macro';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--1);
`;

interface Props {
  children: ReactNode;
}

export const CardContentFlexbox = ({ children }: Props) => {
  return <Container>{children}</Container>;
};
