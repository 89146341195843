import React from 'react';

import { useSafeDeletePost } from 'post/utils/postApi';
import { DeleteButtonWithWarning } from 'shared/button/DeleteButtonWithWarning';
import { useNotification } from 'shared/notification/useNotification';
import { useGoToRoute } from 'stateAndApi/history';
import styled from 'styled-components/macro';
import { Post } from 'types/postTypes';
import { mobileBreakpoint } from 'utils/constants';
import { RoutePaths } from 'utils/urls';

import Button from '../../shared/button/Button';

const EditButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: var(--3);
  justify-content: space-between;
  max-height: var(--48px-rem);
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: var(--2);
  height: 100%;
  max-height: var(--48px-rem);
`;

export const SaveButtonText = styled.span`
  font-size: var(--18px-rem);
  display: block;

  @media (max-width: ${mobileBreakpoint}) {
    span {
      display: none;
    }
  }
`;
interface Props {
  post: Post;
  handleEditCancel: () => void;
  submitting: boolean;
}

export const EditButtons = ({ post, handleEditCancel, submitting }: Props) => {
  const deletePost = useSafeDeletePost();
  const { notifyError, notifyInfo } = useNotification();
  const goToRoute = useGoToRoute();

  const deleteThisPost = async () => {
    try {
      await deletePost(post.id);
      notifyInfo('Info', 'Innlegget ble slettet');
      goToRoute(RoutePaths.LANDING);
    } catch (e) {
      notifyError('Error', e instanceof Error ? e.message : 'Klarte ikke å slette innlegget');
    }
  };

  return (
    <EditButtonsContainer>
      <DeleteButtonWithWarning
        warningText="Er du sikker på at du vil slette dette innlegget?"
        deleteFunction={deleteThisPost}
      />
      <ButtonContainer>
        <Button variant="white" onClick={handleEditCancel}>
          Avbryt
        </Button>
        <Button variant="primary" type="submit" disabled={submitting}>
          <SaveButtonText>
            Lagre<span> endringer</span>
          </SaveButtonText>
        </Button>
      </ButtonContainer>
    </EditButtonsContainer>
  );
};
