import {
  CreateMyHistoryMappingBackend,
  MyHistoryMapping,
  MyHistoryMappingBackend,
} from '../../../types/myHistoryTypes';
import {
  authenticatedGetV2,
  authenticatedV2PatchWithoutResponse,
  authenticatedV2PostWithoutResponse,
} from '../../../utils/apiUtils';
import { ApiUrls } from '../../../utils/urls';

export const getMyHistoryMappings = async (): Promise<MyHistoryMapping[]> => {
  const response = await authenticatedGetV2<MyHistoryMappingBackend[]>(ApiUrls.HISTORY);
  return response.map(mapToMyHistoryMapping);
};

export const addMyHistoryMapping = async (newMapping: CreateMyHistoryMappingBackend) => {
  return await authenticatedV2PostWithoutResponse<CreateMyHistoryMappingBackend>(ApiUrls.HISTORY, newMapping);
};

export const patchMyHistoryMapping = async (
  old: CreateMyHistoryMappingBackend[],
  updated: CreateMyHistoryMappingBackend[],
  post_id: number
) => {
  return await authenticatedV2PatchWithoutResponse(`${ApiUrls.HISTORY}/${post_id}`, { old, updated });
};

const mapToMyHistoryMapping = (mapping: MyHistoryMappingBackend): MyHistoryMapping => ({
  postId: mapping.post_id,
  interactionType: mapping.interaction_type,
  dateAdded: new Date(mapping.date_added),
  contributors: mapping.contributors,
  publisherId: mapping.publisher_id,
  thumbnailUrl: mapping.thumbnail_url,
  title: mapping.title,
});
