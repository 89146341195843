import { css, keyframes } from 'styled-components/macro';

const animation = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

export const fadeAnimation = css`
  ${animation} 0.6s ease-in-out
`;
