import React from 'react';

import { addPostToCollection } from 'collection/utils/collectionApi';
import { TouchableOpacity } from 'landing/FloatingTagSelector';
import CollectionPickerItems from 'shared/CollectionPickerItems';
import { useNotification } from 'shared/notification/useNotification';
import { useLoggedInEmployee } from 'stateAndApi/employeeApi';
import { trackEventToMixpanel } from 'stateAndApi/mixpanelApi';
import styled from 'styled-components/macro';
import { CollectionMetadataLight } from 'types/collectionTypes';
import { MixpanelEvent } from 'types/mixpanelTypes';
import { Post } from 'types/postTypes';
import { getErrorMessage } from 'utils/utils';

const CollectionPicker = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  display: flex;
  flex-direction: column;
  background: var(--hvit);
  text-align: left;
  border-radius: var(--05);
  box-shadow: 0 1px 8px 0 var(--overskyet-kontrast);
  max-height: var(--30);
  overflow-y: scroll;
  width: var(--25);
`;

type Props = {
  post: Post;
  closeAndUpdate: (editedPost: Post) => void;
  isOpen: boolean;
};

export const FloatingCollectionMenu = ({ closeAndUpdate, post, isOpen }: Props) => {
  const loggedInEmployee = useLoggedInEmployee();
  const { notifyInfo, notifyWarning } = useNotification();

  const addToCollection = async (collection: CollectionMetadataLight) => {
    try {
      await addPostToCollection(collection.id!!, post.id);
      await trackEventToMixpanel(MixpanelEvent.ADD_POST_TO_COLLECTION_FROM_CARD, loggedInEmployee);
      closeAndUpdate(post);
      notifyInfo('Hurra!', 'Innlegget ble lagt til i samlingen');
    } catch (error) {
      notifyWarning('OBS', getErrorMessage(error));
    }
  };

  return (
    <>
      <TouchableOpacity onClick={() => closeAndUpdate(post)} />
      <CollectionPicker>
        {/* Legg inn søkefelt her */}
        <CollectionPickerItems myId={loggedInEmployee.id} onClickCollectionItem={addToCollection} isOpen={isOpen} />
      </CollectionPicker>
    </>
  );
};
