import React, { useCallback, useEffect } from 'react';

import { CardCountDisplay } from 'landing/CardCountDisplay';
import { Tab } from 'landing/Landing';
import { atom, selector, useRecoilState, useRecoilValue } from 'recoil';
import { FeedContainer } from 'shared/feed/Feed';
import { getFeedComponentToRender } from 'shared/feedpage/utils';

import { getPostsBySearch } from '../../post/utils/postApi';
import { CardFeedGrid } from '../../shared/feed/CardFeedGrid';
import { employeeRecordState } from '../../stateAndApi/employeeState';
import { FilterType, getActiveFiltersAndSearchWord, mapToFrontendSearchResults } from '../../stateAndApi/searchStates';
import { SearchResult } from '../../types/searchTypes';
import { LoadMoreButton } from '../LoadMoreButton';

import { ActiveFiltersAndSearchWord } from './ActiveFiltersAndSearchWord';

const totalResultSize = atom<number>({
  key: 'SearchFeed/totalResultSize',
  default: 0,
});

const pageIndexState = atom<number>({
  key: 'SearchFeed/pageIndexState',
  default: 0,
});

const paginatedSearchResultState = atom<Record<number, SearchResult[]>>({
  key: 'SearchFeed/paginatedSearchResultState',
  default: {},
});

const feedSearchResult = selector({
  key: 'SearchFeed/searchResultSelector',
  get: ({ get }) => {
    const searchResult = get(paginatedSearchResultState);
    return Object.values(searchResult).flat();
  },
});

const POSTS_PER_PAGE = 60;

type Props = {
  tab: Tab;
};

export const SearchFeed = ({ tab }: Props) => {
  const { search, activeFilters } = useRecoilValue(getActiveFiltersAndSearchWord);
  const [fullSearchResultSize, setFullSearchResultSize] = useRecoilState(totalResultSize);

  const employeeRecord = useRecoilValue(employeeRecordState);

  const [pageIndex, setPageIndex] = useRecoilState(pageIndexState);
  const [paginatedSearchResult, setPaginatedSearchResult] = useRecoilState(paginatedSearchResultState);
  const searchResultAsList = useRecoilValue(feedSearchResult);

  const getFilterOptions = useCallback(() => {
    return {
      contentFilters: findContentFilters(activeFilters),
      isExternal: findIsExternal(activeFilters),
      tagFilters: findTagFilters(activeFilters),
      employeeFilters: findEmployeeFilters(activeFilters),
    };
  }, [activeFilters]);

  useEffect(() => {
    const searchRequestBody = {
      limit: POSTS_PER_PAGE,
      offset: POSTS_PER_PAGE * pageIndex,
      filterOptions: getFilterOptions(),
    };

    getPostsBySearch(search, searchRequestBody, tab).then((res) => {
      const nextPaginationResult = mapToFrontendSearchResults(res.searchResultBackend, employeeRecord);
      setPaginatedSearchResult(() => {
        return {
          ...paginatedSearchResult,
          [pageIndex]: nextPaginationResult,
        };
      });
      setFullSearchResultSize(res.size);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, getFilterOptions, employeeRecord, pageIndex]);

  useEffect(() => {
    setPageIndex(0);
    setPaginatedSearchResult({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, getFilterOptions]);

  return (
    <>
      <ActiveFiltersAndSearchWord />
      <CardCountDisplay displayText={`Viser ${searchResultAsList.length} av ${fullSearchResultSize} innlegg`} />
      <FeedContainer>
        <CardFeedGrid>{searchResultAsList.map((post) => getFeedComponentToRender(post))}</CardFeedGrid>
        <LoadMoreButton
          postsLoaded={searchResultAsList.length}
          postsTotal={fullSearchResultSize}
          nextPage={() => setPageIndex((s) => s + 1)}
        />
      </FeedContainer>
    </>
  );
};

const findIsExternal = (filters: FilterType[]): boolean | null => {
  const isExternal = filters.filter((f) => f.key === 'External').map((f) => (f.value === 'external' ? true : false));
  return isExternal.length === 1 ? isExternal[0] : null;
};

const findContentFilters = (filters: FilterType[]): string[] =>
  filters.filter((f) => f.key === 'Media').map((f) => f.value);

const findTagFilters = (filters: FilterType[]): string[] => filters.filter((f) => f.key === 'Tag').map((f) => f.value);

const findEmployeeFilters = (filters: FilterType[]): number[] =>
  filters.filter((f) => f.key === 'Employee').map((f) => parseInt(f.value));
