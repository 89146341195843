import { FormEvent, useState } from 'react';

import { useNotification } from 'shared/notification/useNotification';
import { Warning } from 'utils/utils';

export const useSubmitForm = (
  submitFunction: () => Promise<void>,
  validateForm?: () => void,
  errorHandler?: (error: Error) => void
) => {
  const { notifyError, clearNotification, notifyWarning } = useNotification();
  const [isSubmitting, setSubmitting] = useState(false);

  const submitForm = async (event: FormEvent) => {
    event.preventDefault();
    clearNotification();
    setSubmitting(true);
    try {
      validateForm && validateForm();
      await submitFunction();
    } catch (e) {
      if (errorHandler) {
        errorHandler(e instanceof Error ? e : new Error(''));
      } else {
        if (e instanceof Warning) {
          notifyWarning('', e.message);
        } else if (e instanceof Error) {
          notifyError('OBS!', e.message);
        }
      }
    } finally {
      setSubmitting(false);
    }
  };

  return { isSubmitting, setSubmitting, submitForm };
};
