import React, { useEffect, useRef, useState } from 'react';

import { Tab } from 'landing/Landing';
import { getContentSuggestions } from 'post/utils/postApi';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import Icon from 'shared/Icon';
import { ButtonWithHover } from 'shared/button/ButtonWithHover';
import useOnClickOutside from 'shared/hooks/useOnClickOutside';
import { useLoggedInEmployee } from 'stateAndApi/employeeApi';
import { employeeRecordState } from 'stateAndApi/employeeState';
import { hotkeysEnabled } from 'stateAndApi/hotkeyState';
import { trackInitiatedSearch } from 'stateAndApi/mixpanelApi';
import { findSearchSuggestions, getActiveFiltersAndSearchWord, searchState } from 'stateAndApi/searchStates';
import styled from 'styled-components/macro';
import { MixpanelEvent } from 'types/mixpanelTypes';
import { SearchSuggestion, SearchSuggestionBackend } from 'types/searchTypes';
import { contentWidth, mobileBreakpoint, tabletBreakpoint } from 'utils/constants';

import { FilterPosts } from './filter/FilterPosts';
import { SearchDropDown } from './searchDropDown/SearchDropDown';

type Props = {
  tab: Tab;
};

export const Search = ({ tab }: Props) => {
  const setHotkeysEnabled = useSetRecoilState(hotkeysEnabled);
  const [searchTerm, setSearchTerm] = useRecoilState<string>(searchState);
  const [searchTermInput, setSearchTermInput] = useState<string>(searchTerm);
  const { activeFilters } = useRecoilValue(getActiveFiltersAndSearchWord);
  const employee = useLoggedInEmployee();
  const [showDropDown, setShowDropdown] = useState<boolean>(false);
  const [showFilterMenu, setShowFilterMenu] = useState<boolean>(false);
  const dropDownThreshold = 2;
  const [debounceSwitch, setDebounceSwitch] = useState<boolean>(false);
  const [searchSuggestions, setSearchSuggestions] = useState<SearchSuggestion>({} as SearchSuggestion);
  const outerContainerRef = useRef<HTMLDivElement>(null);
  const employeesRecordState = useRecoilValue(employeeRecordState);

  const defaultInputPlaceholder = `Hei, ${employee.firstName}! 👋 Hva vil du lære mer om?`;
  const [inputPlaceholder, setInputPlaceholder] = useState(defaultInputPlaceholder);

  useOnClickOutside(outerContainerRef, () => {
    setShowDropdown(false);
  });

  useEffect(() => {
    setSearchTermInput(searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    const updateInputPlaceHolder = () => {
      if (window.innerWidth <= 680 || activeFilters.length > 0) {
        setInputPlaceholder('Hva vil du lære mer om?');
      } else {
        setInputPlaceholder(defaultInputPlaceholder);
      }
    };
    updateInputPlaceHolder();

    window.addEventListener('resize', updateInputPlaceHolder);
    //clean up function -> returns even though the component isn´t rerendered
    return () => {
      window.removeEventListener('resize', updateInputPlaceHolder);
    };
    // defaultInputPlaceholder can't be included in the dependency list.
    // This would cause the useEffect fire on every change of the defaultInputPlaceholder. We only want the trigger when screen size changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchTermInput.length > dropDownThreshold) {
      getContentSuggestions(searchTermInput, tab).then((res: SearchSuggestionBackend) => {
        setSearchSuggestions(findSearchSuggestions(res, searchTermInput, employeesRecordState));
      });
    }
    // searchTerm in dependency list would cause the backend call to fire on every change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceSwitch]);

  useEffect(() => {
    if (searchTermInput.length <= dropDownThreshold) {
      setShowDropdown(false);
    }
  }, [searchTermInput]);

  useEffect(() => {
    function closeDropdownOnESC(event: KeyboardEvent) {
      if (event.code === 'Escape') {
        setShowDropdown(false);
      }
    }

    window.addEventListener('keydown', closeDropdownOnESC);
    return () => {
      window.removeEventListener('keydown', closeDropdownOnESC);
    };
  }, []);

  const handleKeyPress = async (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      await trackInitiatedSearch(MixpanelEvent.SEARCH_INITIATED_IN_LANDING, searchTermInput, employee);
      setSearchTerm(searchTermInput);
      toggleDropDown();
    } else if (e.key === 'Backspace' && searchTermInput.length === 0) {
      setShowDropdown(false);
    } else {
      setShowDropdown(true);
    }
  };

  function debounce<Args extends unknown[]>(fn: (...args: Args) => void, delay: number) {
    let timeoutID: number | undefined;
    return (...args: Args) => {
      clearTimeout(timeoutID);
      timeoutID = window.setTimeout(() => fn(...args), delay);
    };
  }

  // Debounce backend call for 500ms
  const updateSuggestions = debounce(() => {
    setDebounceSwitch(!debounceSwitch);
  }, 500);

  const handleInput = (input: string) => {
    if (input.trimStart().length === 0) {
      setSearchTermInput('');
    } else {
      setSearchTermInput(input);
    }
    if (input.length > dropDownThreshold) {
      updateSuggestions();
    } else {
      setSearchSuggestions({} as SearchSuggestion);
    }
  };

  const toggleFilterMenu = () => {
    setShowFilterMenu(!showFilterMenu);
  };

  const toggleDropDown = () => {
    setShowDropdown(!showDropDown);
  };

  return (
    <OuterContainer ref={outerContainerRef}>
      <SearchContainer>
        <InputContainer onKeyDown={handleKeyPress}>
          <SearchIcon name="search" />
          <StyledInputBox
            onFocus={() => setHotkeysEnabled(false)}
            onBlur={() => setHotkeysEnabled(true)}
            value={searchTermInput}
            onChange={(event) => handleInput(event.target.value)}
            placeholder={inputPlaceholder}
          />
          <Button showFilterMenu={showFilterMenu} hoverColor="var(--contrast)" onClick={toggleFilterMenu}>
            <FilterIcon />
          </Button>
        </InputContainer>
        <SearchDropDown searchSuggestions={searchSuggestions} query={searchTermInput} showDropDown={showDropDown} />
      </SearchContainer>

      <FilterPosts showFilterMenu={showFilterMenu} />
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  height: fit-content;
  width: ${contentWidth};
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 1360px) {
    width: 80vw;
  }
`;

const SearchContainer = styled.div`
  position: relative;
  border-bottom: 1px solid black;
`;

const InputContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 0.5rem;

  @media (max-width: ${tabletBreakpoint}) {
    gap: 0.5rem;
  }
`;

const StyledInputBox = styled.input`
  display: flex;
  align-self: flex-end;
  flex-grow: 2;
  background: inherit;
  color: var(--sort);
  border: none;
  padding: 0;

  font-size: var(--24px-rem);
  font-family: var(--din-light);

  ::placeholder {
    color: var(--sort);
    opacity: 1;
  }

  :active,
  :focus {
    outline: none;
  }

  @media (max-width: ${mobileBreakpoint}) {
    font-size: var(--16px-rem);
  }
`;

const SearchIcon = styled(Icon)`
  flex-grow: 0;
  width: 2.5rem;
  height: 2.5rem;

  @media (max-width: ${mobileBreakpoint}) {
    width: 2rem;
    height: 2rem;
  }
`;

const FilterIcon = styled(Icon).attrs({ name: 'filter' })`
  width: 2.5rem;
  height: 2.5rem;

  @media (max-width: ${mobileBreakpoint}) {
    width: 2rem;
    height: 2rem;
  }
`;

const Button = styled(ButtonWithHover)<{ showFilterMenu: boolean }>`
  transition: all 0.3s ease-out;
  transform: ${({ showFilterMenu }) => showFilterMenu && `rotate(90deg)`};
  flex-grow: 0;
  height: 2.5rem;
  width: 2.5rem;

  @media (max-width: ${mobileBreakpoint}) {
    width: 2rem;
    height: 2rem;
  }
`;
