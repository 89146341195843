import React, { ReactElement, ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';

import styled from 'styled-components/macro';
import { fadeAnimation } from 'utils/commonAnimations';
import { tabletBreakpoint } from 'utils/constants';

type Props = {
  Helmet: ReactElement<Helmet>;
  children: ReactNode;
};
/**
 * A component that renders a detail page with a Helmet component and child component(s).
 * @param Helmet The Helmet of the page.
 * @param children The first should be a header using the `<GenericDetailHeader/>`,
 * second should be a component using the `<GenericTopTexts/>`,
 * next should be a component using the `<GenericMetaData/>`,
 * then a component using the `<GenericPostsContainer/>`, then more items if needed.
 * @returns A JSX element representing the GenericDetailPage component.
 */
export const GenericDetailPage = ({ Helmet, children }: Props) => {
  return (
    <Container>
      {Helmet}
      {children}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  margin: var(--6) auto 504px;
  flex-direction: column;
  padding: 0 var(--2);
  max-width: 880px;
  gap: var(--3);
  animation: ${fadeAnimation};

  @media (max-width: ${tabletBreakpoint}) {
    margin: var(--4) auto 504px;
    padding: 0 var(--3);
  }
`;
