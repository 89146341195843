import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { useSetRecoilState } from 'recoil';
import { createHistoryPath, historyState } from 'stateAndApi/history';

interface Props {
  to: string;
  children?: React.ReactNode;
}

export const InternalLink = ({ children, to, ...linkProps }: Props & LinkProps) => {
  const setOurOwnHistory = useSetRecoilState(historyState);

  return (
    <Link {...linkProps} onClick={() => setOurOwnHistory((history) => [...history, createHistoryPath(to)])} to={to}>
      {children}
    </Link>
  );
};
