import React from 'react';

import { Chip } from 'createNewPost/form/Chip';
import styled from 'styled-components/macro';

export const CATEGORY_TAG = ['Teknologi', 'Data og analyse', 'Design', 'Forretningsutvikling', 'Prosess og ledelse'];

const TagPickerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
`;

const TagChip = styled(Chip)`
  border: var(--1px) solid var(--sort);
  white-space: nowrap;
  margin: 0;
`;

interface ContentPickerProps {
  categoryTags: string[];
  setCategoryTags: (tags: string[]) => void;
}

const CategoryTagPicker = ({ categoryTags, setCategoryTags }: ContentPickerProps) => {
  const onCategoryChange = (tag: string) => {
    //If it exists it means that it needs to be removed (toggled off)
    if (categoryTags.includes(tag)) {
      setCategoryTags(categoryTags.filter((catTag) => catTag !== tag));
    } else {
      //Add to list if it dosent exist.
      setCategoryTags([...categoryTags, tag]);
    }
  };

  return (
    <TagPickerContainer>
      {CATEGORY_TAG.map((catTag) => (
        <TagChip
          key={catTag}
          text={catTag}
          onClick={() => onCategoryChange(catTag)}
          active={categoryTags.includes(catTag)}
        />
      ))}
    </TagPickerContainer>
  );
};

export default CategoryTagPicker;
