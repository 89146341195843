import React, { useEffect, useRef, useState } from 'react';
import { OnChangeValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import styled, { keyframes } from 'styled-components/macro';
import { authenticatedGetV2 } from 'utils/apiUtils';

import { TextFieldLabel } from '../../../post/editPost/UrlInput';
import { Tag } from '../../../types/tagTypes';
import { ApiUrls } from '../../../utils/urls';
import Icon from '../../Icon';

import CategoryTagPicker, { CATEGORY_TAG } from './CategoryTagPicker';
import { customStyleReactSelect } from './MultiSelect';

const Label = styled.div`
  margin-top: var(-1);
`;

const fadeUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledButton = styled.button.attrs({ type: 'button' })`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: var(--hvit);
  border-style: none;
  font-family: var(--din-light);
  font-size: var(--16px-rem);
`;

const SelectedOption = styled.div`
  cursor: pointer;
  padding: var(--05) var(--2) var(--2px) var(--2);
  height: var(--5);
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  flex-direction: row;
  gap: var(--1-05);
  border: var(--1px) solid var(--contrast);
  border-radius: 100vmax;
  animation: ${fadeUp} 0.4s cubic-bezier(0.54, 0.38, 0.63, 1.49);
  font-size: var(--16px-rem);

  @media (max-width: 700px) {
    margin-left: 0;
    margin-right: 5px;
  }

  &:hover {
    text-decoration: line-through;
  }
`;

const SuggestedOption = styled(SelectedOption)`
  border: var(--1px) solid var(--sort);
  cursor: pointer;
  animation: ${fadeDown} 0.4s cubic-bezier(0.54, 0.38, 0.63, 1.49);

  :hover {
    border: var(--1px) solid var(--contrast);
    text-decoration: none;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: inherit;
`;

const SelectedOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: var(--1-05);
  align-items: flex-start;
  width: 100%;

  :empty {
    margin: 0;
  }

  @media (max-width: 700px) {
    margin-left: 0;
    margin-top: 10px;
  }
`;

const CrossIcon = styled(Icon).attrs({ name: 'cross' })`
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  transition: transform 0.2s;

  ${SelectedOption}:hover & {
    transform: rotate(90deg);
  }
`;

const PlusIcon = styled(CrossIcon)`
  transform: rotate(45deg);

  ${SelectedOption}:hover & {
    transform: rotate(135deg);
  }
`;

export type TagOptionType = {
  value: string;
  label: string;
};

type Props = {
  onChange: (tags: string[]) => void;
  existingTags: string[];
  description: string;
  title: string;
  focus?: boolean;
};

export const TagsFormField = ({ onChange, existingTags, description, title, focus }: Props) => {
  const [allTags, setAllTags] = useState<TagOptionType[]>([]);
  const [getTags, setGetTags] = useState(true);
  const [suggestedTags, setSuggestedTags] = useState<string[]>([]);
  const [categoryTags, setCategoryTags] = useState<string[]>([]);
  const tagsFormFieldRef = useRef<HTMLDivElement>();

  useEffect(() => {
    tagsFormFieldRef.current && tagsFormFieldRef.current.focus();
  }, []);

  useEffect(() => {
    const setHooks = async () => {
      const tags: TagOptionType[] = (await authenticatedGetV2<Tag[]>(ApiUrls.GET_TAGS)).map((tag) => {
        return { value: tag.id.toString(), label: tag.tag };
      });

      setAllTags(tags);
    };
    if (getTags) {
      setHooks();
      setGetTags(false);
    }
  }, [getTags]);

  useEffect(() => {
    function containsTag(str: string, tag: string) {
      let escaped = tag.replace(/([.*+?^#=!:${}()|[\]/\\])/g, '\\$&');

      escaped = escaped.replace(/^(\w)/, '\\b$1');
      escaped = escaped.replace(/(\w)$/, '$1\\b');

      return str.match(new RegExp(escaped, 'g')) != null;
    }

    const titleAndDescription = description.toLowerCase() + ' ' + title.toLowerCase();
    setSuggestedTags(
      allTags.filter((tag) => containsTag(titleAndDescription, tag.label.toLowerCase())).map((tag) => tag.label)
    );
  }, [title, description, allTags, existingTags]);

  useEffect(() => {
    // Have to remove categories from existing tag before updating with new state
    const newTags = [...existingTags.filter((tag) => !CATEGORY_TAG.includes(tag)), ...categoryTags];
    onChange(newTags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryTags]);

  const handleOnChange = (newValue: OnChangeValue<TagOptionType, boolean>) => {
    if (newValue) {
      onChange([...existingTags, (newValue as TagOptionType).label]);
    }
  };

  const handleOption = async (inputValue: string) => {
    onChange([...existingTags, inputValue]);
  };

  const onDelete = (removedOption: string) => {
    onChange(existingTags.filter((t) => t !== removedOption));
  };

  const selectableOptions = (value: string[]) => {
    return value
      ? allTags
          .filter((tag) => !CATEGORY_TAG.some((catTag) => catTag === tag.label))
          .filter((tag) => !value.some((someTag) => someTag === tag.label))
          .sort((a, b) => (a.label > b.label ? 1 : -1))
      : allTags;
  };

  return (
    <Container>
      <div>
        <Label>
          <TextFieldLabel>Fagområde (obligatorisk)</TextFieldLabel>
        </Label>
        <CategoryTagPicker categoryTags={categoryTags} setCategoryTags={setCategoryTags} />
      </div>
      <div>
        <Label>
          <TextFieldLabel>Tags</TextFieldLabel>
        </Label>
        <CreatableSelect
          autoFocus={focus}
          openMenuOnFocus={!focus}
          isClearable
          placeholder="Velg tags..."
          onCreateOption={handleOption}
          options={selectableOptions(existingTags)}
          styles={customStyleReactSelect}
          onChange={handleOnChange}
          value={[] as TagOptionType[]}
        />
      </div>
      <div>
        <Label>
          <TextFieldLabel>Valgte tags</TextFieldLabel>
        </Label>
        <SelectedOptions>
          {existingTags.filter((t) => !CATEGORY_TAG.some((catTag) => catTag === t)).length !== 0 ? (
            existingTags
              .filter((tag) => !CATEGORY_TAG.includes(tag))
              .map((option) => (
                <SelectedOption key={option} onClick={() => onDelete(option)}>
                  {option}
                  <StyledButton>
                    <CrossIcon />
                  </StyledButton>
                </SelectedOption>
              ))
          ) : (
            <span>Ingen tags er valgt</span>
          )}
        </SelectedOptions>
      </div>
      {suggestedTags.length !== 0 && (
        <div>
          <Label>
            <TextFieldLabel>Foreslåtte tags</TextFieldLabel>
          </Label>
          <SelectedOptions>
            {suggestedTags
              .filter((t) => !existingTags.some((eT) => eT === t))
              .filter((t) => !CATEGORY_TAG.some((catTag) => catTag === t))
              .map((option) => (
                <SuggestedOption key={option} onClick={() => handleOption(option)}>
                  {option}
                  <StyledButton>
                    <PlusIcon />
                  </StyledButton>
                </SuggestedOption>
              ))}
          </SelectedOptions>
        </div>
      )}
    </Container>
  );
};
