import React from 'react';

import styled from 'styled-components/macro';
import { Post } from 'types/postTypes';

import Icon from '../Icon';

const Button = styled.button`
  background: none;
  border: none;
  display: flex;
  padding: 0;
  cursor: pointer;
  border-radius: 100vmax;
  transition: 0.2s;

  &:hover :not(:disabled),
  &:focus-visible {
    background: var(--primary);
  }

  &:disabled {
    cursor: auto;
  }
`;
interface Props {
  postAlreadyInDetailPage: boolean;
  post: Post;
  handleAddPost: (post: Post) => void;
}

export const AddPostButton = ({ postAlreadyInDetailPage, post, handleAddPost }: Props) => {
  return (
    <Button
      disabled={postAlreadyInDetailPage}
      onClick={() => handleAddPost(post)}
      title={postAlreadyInDetailPage ? 'Posten er allerede lagt til' : 'Legg til'}
    >
      <Icon name={postAlreadyInDetailPage ? 'checkmark' : 'plus'} />
    </Button>
  );
};
