import React, { useEffect, useState } from 'react';

import Icon from 'shared/Icon';
import styled, { css } from 'styled-components/macro';

interface ProgressTrackerProps {
  urlScreened: boolean;
  requiredFieldsCompleted: boolean;
}

const ProgressTracker = ({ urlScreened, requiredFieldsCompleted }: ProgressTrackerProps) => {
  const [activeStep, setActiveStep] = useState<1 | 2>(1);
  const [allStepsCompleted, setAllStepsCompleted] = useState<boolean>(false);

  useEffect(() => {
    if (urlScreened) {
      setActiveStep(2);
    } else {
      setActiveStep(1);
    }
  }, [urlScreened]);

  useEffect(() => {
    if (activeStep === 2 && requiredFieldsCompleted) {
      setAllStepsCompleted(true);
    } else {
      setAllStepsCompleted(false);
    }
  }, [activeStep, requiredFieldsCompleted]);

  return (
    <ProgressTrackerOuterContainer>
      <CircleContainer>
        {activeStep === 1 && !allStepsCompleted && (
          <>
            <ActiveStep>1</ActiveStep>
            <Line active={activeStep} />
            <DisabledStep>2</DisabledStep>
          </>
        )}
        {activeStep === 2 && !allStepsCompleted && (
          <>
            <CheckedStep>
              <StyledCheckmarkIcon name="checkmark" />
            </CheckedStep>
            <Line active={activeStep} />
            <ActiveStep>2</ActiveStep>
          </>
        )}
        {allStepsCompleted && (
          <>
            <CheckedStep>
              <StyledCheckmarkIcon name="checkmark" />
            </CheckedStep>
            <Line active={activeStep} />
            <CheckedStep>
              <StyledCheckmarkIcon name="checkmark" />
            </CheckedStep>
          </>
        )}
      </CircleContainer>
      <Labels activeStep={activeStep} />
    </ProgressTrackerOuterContainer>
  );
};

export default ProgressTracker;

const ProgressTrackerOuterContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
`;

const CircleContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 4px;
`;

const StepCircle = css`
  display: flex;
  border-radius: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
`;

const ActiveStep = styled.div`
  ${StepCircle}
  border: 1px solid black;
  background: white;
  color: black;
  font-weight: bold;
  font-size: 1rem;
`;

const DisabledStep = styled.div`
  ${StepCircle}
  border: 1px solid var(--morgendis);
  background: white;
  color: var(--morgendis);
  opacity: 0.6;
  font-weight: bold;
  font-size: 1rem;
`;

const CheckedStep = styled.div`
  ${StepCircle}
  background:  var(--grønn-kontrast);
`;

const Line = styled.div<{ active: number }>`
  margin-top: 1rem;
  border-top: 3px dotted ${({ active }) => (active === 1 ? 'grey' : 'var(--grønn-kontrast)')};
  width: 5rem;
`;

const LabelContainer = styled.div`
  display: flex;
  gap: 6rem;
`;

const StyledCheckmarkIcon = styled(Icon)`
  vertical-align: bottom;
`;

const Label = styled.div<{ active: boolean }>`
  font-weight: ${({ active }) => (active ? 'bold' : '')};
  opacity: ${({ active }) => (active ? '1' : '0.8')};
  color: ${({ active }) => (active ? 'black' : 'var(--morgendis)')};
`;

interface LabelProps {
  activeStep: number;
}

const Labels = ({ activeStep }: LabelProps) => {
  return (
    <LabelContainer>
      {activeStep === 1 ? (
        <>
          <Label active={!!activeStep}>Hent info</Label>
          <Label active={!activeStep}>Rediger</Label>
        </>
      ) : (
        <>
          <Label active={!!activeStep}>Hent info</Label>
          <Label active={!!activeStep}>Rediger</Label>
        </>
      )}
    </LabelContainer>
  );
};
