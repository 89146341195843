import React, { FormEvent, useState } from 'react';

import { createCompetencePlan } from 'competencePlan/utils/competenceApi';
import { validateCompetencePlan } from 'competencePlan/utils/validate';
import { Form, FormInnerOuterContainer, FormOuterContainer } from 'shared/FormComponents';
import { HeaderContainer } from 'shared/HeaderContainer';
import { BackButton } from 'shared/button/BackButton';
import Button from 'shared/button/Button';
import { ImageInput } from 'shared/input/ImageInput';
import InputText from 'shared/input/InputText';
import { SummaryInput } from 'shared/input/SummaryInput';
import { useNotification } from 'shared/notification/useNotification';
import { useLoggedInEmployee } from 'stateAndApi/employeeApi';
import { useGoToRoute } from 'stateAndApi/history';
import styled from 'styled-components/macro';
import { CompetencePlanMetadata } from 'types/competencePlanTypes';
import { preventDefaultBehaviourEnter } from 'utils/eventListeners';
import { RoutePaths } from 'utils/urls';
import { Warning, getErrorMessage } from 'utils/utils';

const defaultCompetencePlan: CompetencePlanMetadata = {
  title: '',
  description: '',
  author_id: -1,
  thumbnail_url: null,
  number_of_posts: 0,
};

export const CreateNewCompetencePlan = () => {
  const [competencePlanState, setCompetencePlanState] = useState<CompetencePlanMetadata>(defaultCompetencePlan);
  const { notifyError, notifyWarning, clearNotification } = useNotification();
  const employee = useLoggedInEmployee();

  const [imgHasError, setImgHasError] = useState(false);

  const goToRoute = useGoToRoute();

  const goToCompetencePlan = (competenceId: number) => {
    goToRoute(`${RoutePaths.COMPETENCE_PLAN}/${competenceId}`);
  };

  const handleCancelCompetencePlan = () => {
    clearNotification();
    goToRoute(RoutePaths.MY_PAGE_COMPETENCE_PLAN);
  };

  const postCompetencePlan = async (event: FormEvent) => {
    event.preventDefault();
    try {
      validateCompetencePlan(competencePlanState.title, imgHasError);

      const { id } = await createCompetencePlan({ ...competencePlanState, author_id: employee.id });
      goToCompetencePlan(id);
    } catch (e) {
      if (e instanceof Warning) {
        notifyWarning('Oisann', e.message);
      } else {
        notifyError('Error', getErrorMessage(e));
      }
    }
  };

  return (
    <FormOuterContainer>
      <FormInnerOuterContainer>
        <BackButton fallbackRoute={RoutePaths.MY_PAGE_COMPETENCE_PLAN} />
        <HeaderContainer>
          <h1>Opprett kompetanseplan</h1>
        </HeaderContainer>
        <Form onSubmit={postCompetencePlan} onKeyDown={preventDefaultBehaviourEnter}>
          <InputFieldsContainer>
            <InputText
              label="Tittel (obligatorisk)"
              value={competencePlanState.title}
              onChange={(title) => setCompetencePlanState((competencePlan) => ({ ...competencePlan, title: title }))}
            />
            <SummaryInput
              label="Beskrivelse"
              value={competencePlanState.description}
              onChange={(description) =>
                setCompetencePlanState((competencePlan) => ({ ...competencePlan, description: description }))
              }
            />
            <ImageInput
              value={competencePlanState.thumbnail_url}
              onChange={(thumbnailUrl) =>
                setCompetencePlanState((competencePlan) => ({ ...competencePlan, thumbnailUrl: thumbnailUrl }))
              }
              setImgHasError={setImgHasError}
            />
          </InputFieldsContainer>
          <BottomButtonsContainer>
            <Button variant="white" onClick={handleCancelCompetencePlan}>
              Avbryt
            </Button>
            <Button variant="primary" type="submit">
              Opprett kompetanseplan
            </Button>
          </BottomButtonsContainer>
        </Form>
      </FormInnerOuterContainer>
    </FormOuterContainer>
  );
};

const InputFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--4);
`;

const BottomButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: var(--3);
  max-height: var(--48px-rem);
`;
