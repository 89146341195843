export const mediaTypes = [
  'collection',
  'article',
  'podcast',
  'video',
  'workshop',
  'slack_thread',
  'file',
  'other',
] as const;

export type MediaType = (typeof mediaTypes)[number];

export const norwegianMediaTypes: Record<MediaType, string> = {
  collection: 'Samlinger',
  article: 'Artikkel',
  podcast: 'Podcast',
  video: 'Video',
  workshop: 'Workshop',
  slack_thread: 'Slack-tråd',
  file: 'Fil',
  other: 'Annet',
};

export const norwegianFilterMediaTypes: Record<MediaType, string> = {
  collection: 'Samlinger',
  article: 'Artikler',
  podcast: 'Podcaster',
  video: 'Videoer',
  workshop: 'Workshops',
  slack_thread: 'Slack-tråder',
  file: 'Filer',
  other: 'Annet',
};
