import React, { CSSProperties, ReactNode, useCallback, useEffect, useState } from 'react';

import { FloatingCollectionMenu } from 'landing/FloatingCollectionMenu';
import { FloatingContributorSelector } from 'landing/FloatingContributorSelector';
import { FloatingTagSelector } from 'landing/FloatingTagSelector';
import { useUpdatePost } from 'post/utils/postApi';
import { useRecoilState } from 'recoil';
import { Container, SIZES } from 'shared/card/CardContainer';
import { hotkeysEnabled } from 'stateAndApi/hotkeyState';
import { Post } from 'types/postTypes';

import { CardSize } from './cardSizes';

interface Props {
  visible?: boolean;
  cardSize: CardSize;
  children: ReactNode;
  post: Post;
}

export const CardContainerWithHotkeys = React.forwardRef(
  ({ visible, cardSize, children, post }: Props, ref: React.Ref<HTMLDivElement>) => {
    const styles = SIZES[cardSize] as CSSProperties;
    const [isHotkeysEnabled, setIsHotkeysEnabled] = useRecoilState(hotkeysEnabled);
    const [isPostHovered, setIsPostHovered] = useState(false);
    const [openTagSelector, setOpenTagSelector] = useState(false);
    const [openContributorSelector, setOpenContributorSelector] = useState(false);
    const [openCollectionMenu, setOpenCollectionMenu] = useState(false);
    const [editedPost, setEditedPost] = useState<Post>(post);
    const updatePost = useUpdatePost();

    const closeAndUpdate = useCallback(
      async (editedPost: Post) => {
        setOpenTagSelector(false);
        setOpenContributorSelector(false);
        setOpenCollectionMenu(false);
        setTimeout(() => setIsHotkeysEnabled(true), 1);
        await updatePost({ ...editedPost });
      },
      [setOpenTagSelector, setOpenContributorSelector, setOpenCollectionMenu, setIsHotkeysEnabled, updatePost]
    );

    useEffect(() => {
      const onKeyUp = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          if (openTagSelector || openContributorSelector || openCollectionMenu) {
            closeAndUpdate(editedPost);
          }
        } else if (event.key === 't') {
          if (isHotkeysEnabled && isPostHovered) {
            setOpenTagSelector(true);
            setIsHotkeysEnabled(false);
          }
        } else if (event.key === 'b') {
          if (isHotkeysEnabled && isPostHovered) {
            setOpenContributorSelector(true);
            setIsHotkeysEnabled(false);
          }
        } else if (event.key === 's') {
          if (isHotkeysEnabled && isPostHovered) {
            setOpenCollectionMenu(true);
            setIsHotkeysEnabled(false);
          }
        }
      };
      window.addEventListener('keyup', onKeyUp);
      return () => {
        window.removeEventListener('keyup', onKeyUp);
      };
    }, [
      closeAndUpdate,
      editedPost,
      setOpenTagSelector,
      setOpenContributorSelector,
      setOpenCollectionMenu,
      setIsHotkeysEnabled,
      isHotkeysEnabled,
      isPostHovered,
      openTagSelector,
      openContributorSelector,
      openCollectionMenu,
    ]);

    return (
      <>
        <Container
          onMouseEnter={() => setIsPostHovered(true)}
          onMouseOver={() => setIsPostHovered(true)}
          onMouseLeave={() => setIsPostHovered(false)}
          visible={visible}
          style={styles}
          ref={ref}
        >
          {children}
        </Container>
        {openTagSelector && (
          <FloatingTagSelector
            editedPost={editedPost}
            setEditedPost={setEditedPost}
            closeAndUpdate={(editedPost) => closeAndUpdate(editedPost)}
          />
        )}
        {openContributorSelector && (
          <FloatingContributorSelector
            editedPost={editedPost}
            setEditedPost={setEditedPost}
            closeAndUpdate={(editedPost) => closeAndUpdate(editedPost)}
          />
        )}
        {openCollectionMenu && (
          <FloatingCollectionMenu
            post={editedPost}
            closeAndUpdate={(editedPost) => closeAndUpdate(editedPost)}
            isOpen={openCollectionMenu}
          />
        )}
      </>
    );
  }
);
