import moment from 'moment';

export const formatDateNumeric = (isoDate: string | null): string => {
  if (isoDate != null) {
    const date = new Date(isoDate);
    const dateTimeFormatted = new Intl.DateTimeFormat('en-GB', {
      year: '2-digit',
      month: 'numeric',
      day: 'numeric',
    });
    return dateTimeFormatted.format(date);
  }
  return '';
};

export const formatDate = (date: string) => {
  const published = moment(date);
  const now = moment();

  const isSameDay = now.isSame(published, 'day');
  if (isSameDay) {
    // Utvid til 'x timer siden' - må først fikse timestamp i db
    return 'I dag';
  }

  const diff = now.diff(published, 'days');
  if (diff <= 7) {
    if (diff === 0 || diff === 1) {
      return '1 dag siden';
    }
    return diff + ' dager siden';
  }

  const isSameYear = now.isSame(published, 'year');
  if (isSameYear) {
    return moment(date).format('D. MMMM');
  }

  return moment(date).format('D. MMM YYYY');
};

export function isNotUndefined<T>(value: T | undefined): value is T {
  return value !== undefined;
}

export class Warning extends Error {
  message: string;

  constructor(errorMessage: string) {
    super();
    this.message = errorMessage;
  }
}

type ErrorWithMessage = {
  message: string;
};

const isErrorWithMessage = (error: unknown): error is ErrorWithMessage =>
  typeof error === 'object' &&
  error !== null &&
  'message' in error &&
  typeof (error as Record<string, unknown>).message === 'string';

const toErrorWithMessage = (maybeError: unknown): ErrorWithMessage => {
  if (isErrorWithMessage(maybeError)) return maybeError;

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    return new Error(String(maybeError));
  }
};

export const getErrorMessage = (error: unknown) => {
  return toErrorWithMessage(error).message;
};

export const isUrl = (text: string): boolean => {
  return new RegExp(/^(https?:\/\/)?(www\.)?([a-z\d-]{2,}\.)+[a-z0-9]{2,}((\/|\?|#)[^\s]*)?$/, 'i').test(text);
};
