import React from 'react';

import styled from 'styled-components/macro';

import { Employee } from '../../types/employeeTypes';
import { EmployeeImage } from '../EmployeeImage';

const Container = styled.div`
  display: flex;

  &:not(:empty) {
    margin-right: var(--1-05);
  }

  & > *:not(:first-child) {
    margin-left: -0.5rem;
  }
`;

const EmployeeImageWithBorder = styled(EmployeeImage)<{ sizeUp: boolean }>`
  border: 1px solid white;
  width: ${(props) => (props.sizeUp ? 'var(--4)' : 'var(--3)')};
  height: ${(props) => (props.sizeUp ? 'var(--4)' : 'var(--3)')};
`;

const FillerImage = styled.div<{ displayComponent: boolean; sizeUp: boolean }>`
  display: ${(props) => (!props.displayComponent ? 'none' : 'initial')};
  width: ${(props) => (props.sizeUp ? 'var(--4)' : 'var(--3)')};
  height: ${(props) => (props.sizeUp ? 'var(--4)' : 'var(--3)')};
  background-color: var(--primary);
  border: 1px solid white;
  border-radius: 50%;
  line-height: var(--3);
  text-align: center;
  font-family: var(--din-light);
  font-size: var(--12px-rem);
  color: black;
`;

const FillerText = styled.p`
  margin: 0;
  padding: 20%;
  height: 100%;
`;

interface Props {
  employees: Employee[];
  /** The maximal number of images to show before using "+3"-bubble. Should be >= 2. */
  maxNumberOfBubbles?: number;
  sizeUp?: boolean;
}

export const ContributorImages = ({ employees, maxNumberOfBubbles = 2, sizeUp }: Props) => {
  if (maxNumberOfBubbles < 2) {
    throw new Error(
      'This component displays at least two contributors. The EmployeeImage-component could be used to show a single contributor.'
    );
  }

  if (employees.length < 1) {
    return null;
  }

  const employeeImageSlice = () => {
    if (employees.length <= maxNumberOfBubbles) {
      return employees.length;
    }
    return maxNumberOfBubbles - 1;
  };

  const employeeImagesWithBorder = employees
    .slice(0, employeeImageSlice())
    .map((employee) => <EmployeeImageWithBorder key={employee.id} employee={employee} sizeUp={!!sizeUp} />);

  return (
    <Container>
      {employeeImagesWithBorder}
      <FillerImage displayComponent={employees.length > maxNumberOfBubbles} sizeUp={!!sizeUp}>
        {sizeUp ? (
          <FillerText>+{employees.length - (maxNumberOfBubbles - 1)}</FillerText>
        ) : (
          <> +{employees.length - (maxNumberOfBubbles - 1)}</>
        )}
      </FillerImage>
    </Container>
  );
};
