import React from 'react';
import styled from 'styled-components/macro';
import { contentWidth, tabletBreakpoint } from 'utils/constants';
import { fadeAnimation } from 'utils/commonAnimations';

export const Container = styled.div`
  font-size: var(--20px-rem);
  animation: ${fadeAnimation};
  max-width: ${contentWidth};
  width: 100%;
  display: flex;
  margin: var(--4) auto 0;

  padding: var(--2) var(--4);

  @media (max-width: ${tabletBreakpoint}) {
    font-size: var(--18px-rem);
    padding: var(--2);
    margin-top: var(--2);
    width: 100%;
  }
`;

interface Props {
  displayText: string;
}

export const CardCountDisplay = ({ displayText }: Props) => {
  return <Container>{displayText}</Container>;
};
