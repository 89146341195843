import React, { useCallback, useEffect, useRef, useState } from 'react';

import { debounce } from 'lodash';
import { Loader } from 'shared/Loader';
import styled from 'styled-components/macro';
import { Post } from 'types/postTypes';
import { tabletBreakpoint } from 'utils/constants';

import PostSearchDetailPage from './PostSearchDetailPage';
import SearchFeedDetailPage from './SearchFeedDetailPage';

interface Props {
  handleAddPost: (post: Post) => void;
  existingPosts: Post[];
  type: string;
  ref: React.Ref<HTMLDivElement>;
}

//Selection is a common description for collection and competence plan
const AddPostsToSelections = React.forwardRef(
  ({ handleAddPost, existingPosts, type }: Props, ref: React.Ref<HTMLDivElement>) => {
    const [searchState, setSearchState] = useState<string>('');

    const [isLoading, setIsLoading] = useState(false);

    const setSearch = (text: string) => {
      setIsLoading(true);
      setSearchState(text);
      debouncedOnSearch(text);
    };

    const onSearch = (text: string) => {
      if (text.length > 0) {
        setSearchState(text);
      } else {
        clearSearchState();
      }
      setIsLoading(false);
    };

    const debouncedOnSearch = useRef(debounce(onSearch, 300)).current;

    const clearSearchState = useCallback(() => {
      setSearchState('');
    }, [setSearchState]);

    useEffect(() => {
      return () => {
        clearSearchState();
      };
    }, [clearSearchState]);

    return (
      <>
        <SearchAndFilterContainer ref={ref}>
          <Header>Legg til innhold i {type}</Header>
          <PostSearchDetailPage inputValue={searchState} onSearchValueChange={setSearch} />
        </SearchAndFilterContainer>
        <SearchResultContainer>
          {isLoading && <Loader height="15rem" />}
          {!isLoading && (
            <SearchFeedDetailPage
              handleAddPost={handleAddPost}
              existingPosts={existingPosts}
              clearSearchState={clearSearchState}
              searchState={searchState}
            />
          )}
        </SearchResultContainer>
      </>
    );
  }
);

export default AddPostsToSelections;

const SearchResultContainer = styled.div`
  width: 100%;
`;

const Header = styled.h2`
  font-size: 2rem;

  @media (max-width: ${tabletBreakpoint}) {
    font-size: 1.5rem;
  }
`;

const SearchAndFilterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
