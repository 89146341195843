import React from 'react';

import styled, { css } from 'styled-components/macro';

type ButtonVariant = 'black' | 'white' | 'primary' | 'contrast';
type ButtonSize = 'large' | 'medium' | 'small';
type ButtonType = 'button' | 'submit' | 'reset';

export type ButtonProps<AsType extends React.ElementType = 'button'> = {
  className?: string;
  variant?: ButtonVariant;
  size?: ButtonSize;
  as?: AsType;
  disabled?: boolean;
  type?: ButtonType;
} & React.ComponentProps<AsType>;

const ButtonOrLink = <AsType extends React.ElementType>({ as = 'button', ...restProps }: ButtonProps<AsType>) => {
  return React.createElement(as, restProps);
};

const Variant = (variant: ButtonVariant) => {
  switch (variant) {
    case 'black':
      return css`
        --text: var(--hvit);
        --hover-text: var(--sort);
        --background: var(--sort);
        --hover-background: var(--hvit);
        --border: var(--sort);
      `;
    case 'white':
      return css`
        --text: var(--sort);
        --hover-text: var(--hvit);
        --background: var(--hvit);
        --hover-background: var(--sort);
        --border: var(--sort);
        --hover-border: var(--hvit);
      `;
    case 'primary':
      return css`
        --text: var(--sort);
        --hover-text: var(--sort);
        --background: var(--primary);
        --hover-background: var(--contrast);
      `;
    case 'contrast':
      return css`
        --text: var(--sort);
        --hover-text: var(--sort);
        --background: var(--contrast);
        --hover-background: var(--hvit);
        --border: var(--sort);
      `;
  }
};

const Size = (size: ButtonSize) => {
  switch (size) {
    case 'large':
      return css`
        --padding: 0.75rem 1rem;
        --font-size: var(--18px-rem);
        --font-family: var(--din-regular);
      `;
    case 'medium':
      return css`
        --padding: 0.75rem 1rem;
        --font-size: var(--16px-rem);
        --font-family: var(--din-light);
      `;
    case 'small':
      return css`
        --padding: 0.75rem 1rem;
        --font-size: var(--14px-rem);
        --font-family: var(--din-light);
      `;
  }
};

const Container = styled(ButtonOrLink)`
  cursor: pointer;
  border: none;
  display: inline-block;
  transition: color 0.2s, background 0.2s;

  ${(props) => props.size && Size(props.size)};
  padding: var(--padding);
  font-size: var(--font-size);
  font-family: var(--font-family);
  box-shadow: 0 0 0 var(--1px) var(--border);

  ${(props) => props.variant && Variant(props.variant)};
  color: var(--text);
  background: var(--background);
  :hover,
  :focus,
  :active:focus {
    color: var(--hover-text);
    background: var(--hover-background);
    box-shadow: 0 0 0 var(--1px) var(--hover-border, var(--border));
  }

  :disabled {
    background: var(--overskyet-kontrast);
    color: var(--hvit);
  }
`;

const Button = <AsType extends React.ElementType = 'button'>({
  size = 'large',
  variant = 'black',
  type = 'button',
  ...restProps
}: ButtonProps<AsType>) => <Container size={size} variant={variant} type={type} {...restProps} />;

export default Button;
