import React, { useState } from 'react';

import { signUpToCompetencePlan, updateCheckMark } from 'competencePlan/utils/competenceApi';
import Icon, { IconName } from 'shared/Icon';
import MediaTime from 'shared/MediaTime';
import { AddPostButton } from 'shared/addPostsToSelections/AddPostButton';
import { AddPostToSelectionFooter } from 'shared/addPostsToSelections/SearchResultCard';
import { DeleteMenuItemWithWarning } from 'shared/button/DeleteMenuItemWithWarning';
import { ReadingListButton } from 'shared/button/ReadingListButton';
import { MoreButton } from 'shared/moreButton/MoreButton';
import styled from 'styled-components/macro';
import { CollectionBackend } from 'types/collectionTypes';
import { norwegianMediaTypes } from 'types/mediaTypes';
import { Post } from 'types/postTypes';
import { RoutePaths } from 'utils/urls';

import { CompetencePlanTypeForPostCard } from '../PostCard';
import { CardBadges } from '../body/CardBadges';
import CardNumberOfViews from '../body/CardNumberOfViews';
import { CardSize } from '../cardSizes';

import { CardButtonContainer } from './CardButtonContainer';
import { CardFooter } from './CardFooter';
import { CardFooterKompetanseButton } from './CardFooterKompetanseButton';

type FooterDefaultProps = {
  post: Post;
  cardSize: CardSize;
};

type FooterKompetanseplanProps = FooterDefaultProps & {
  competencePlanProps: CompetencePlanTypeForPostCard;
};

export const FooterKompetanseplan = ({ post, cardSize, competencePlanProps }: FooterKompetanseplanProps) => {
  const route = `${RoutePaths.POST}/${post.id}`;

  const {
    competencePlanPersonal,
    competencePlanId,
    setCompetencePlanPersonal,
    removePostFromCompetencePlan,
    setShouldUpdatePersonal,
  } = competencePlanProps;
  const [signedUpViaChecked, setSignedUpViaChecked] = useState(false);

  const handleOnClick = async () => {
    let checkedPosts = competencePlanPersonal.checked_posts;
    const toggleChecked = () => {
      if (checkedPosts.includes(post.id)) return checkedPosts.filter((id) => id !== post.id);
      else return [...checkedPosts, post.id];
    };
    setCompetencePlanPersonal({
      ...competencePlanPersonal,
      checked_posts: toggleChecked(),
    });
    await updateCheckMark(competencePlanId, post.id);
    if (checkedPosts.some((p) => post.id === p)) {
      checkedPosts = checkedPosts.filter((c) => c !== post.id);
    } else {
      checkedPosts.push(post.id);
      if (!competencePlanPersonal.signed_up && !signedUpViaChecked) {
        await signUpToCompetencePlan(competencePlanId);
        setSignedUpViaChecked(true);
        setShouldUpdatePersonal(true);
      }
    }
  };

  return (
    <KompetanseFooterContainer>
      <CardFooterKompetanseButton
        callback={handleOnClick}
        checked={competencePlanPersonal.checked_posts.some((c) => c === post.id)}
      />
      <MediaTime consumptionTime={post.consumptionTime} shortForm={cardSize !== CardSize.LARGE} />
      <MoreButton route={route} post={post}>
        <DeleteMenuItemWithWarning
          onClickDelete={() => removePostFromCompetencePlan(post.id)}
          bodyText="Fjern innlegg"
          warningText="Er du sikker på at du vil fjerne innlegget fra kompetanseplanen?"
        />
      </MoreButton>
    </KompetanseFooterContainer>
  );
};

type FooterWithDeleteFromCollectionProps = FooterDefaultProps & {
  collection?: CollectionBackend;
  deletePostFromCollection?: (postId: number) => void;
};

export const FooterWithDeleteFromCollection = ({
  post,
  cardSize,
  deletePostFromCollection,
  collection,
}: FooterWithDeleteFromCollectionProps) => {
  const route = `${RoutePaths.POST}/${post.id}`;

  return (
    <CommonFooter
      post={post}
      cardSize={cardSize}
      aboutMedia={
        <MediaType>
          <MediaTypeIcon name={post.mediaType as IconName} />
          {norwegianMediaTypes[post.mediaType!] ? norwegianMediaTypes[post.mediaType!].toLowerCase() : ''}
        </MediaType>
      }
      button={
        <MoreButton route={route} post={post}>
          {collection && deletePostFromCollection && (
            <DeleteMenuItemWithWarning
              onClickDelete={() => deletePostFromCollection(post.id)}
              bodyText="Slett fra samling"
              warningText="Er du sikker på at du vil fjerne dette innlegget fra samlingen?"
            />
          )}
        </MoreButton>
      }
    />
  );
};

type FooterSearchResultProps = FooterDefaultProps & {
  footerForAddingPost: AddPostToSelectionFooter;
};

export const FooterSearchResult = ({ post, cardSize, footerForAddingPost }: FooterSearchResultProps) => {
  return (
    <CommonFooter
      post={post}
      cardSize={cardSize}
      aboutMedia={<MediaTime consumptionTime={post.consumptionTime} shortForm={cardSize !== CardSize.LARGE} />}
      button={
        <AddPostButton
          postAlreadyInDetailPage={footerForAddingPost.isPostInSelection}
          post={post}
          handleAddPost={footerForAddingPost.handleAddPost}
        />
      }
    />
  );
};

type CommonFooterProps = FooterDefaultProps & {
  button: React.ReactNode;
  aboutMedia: React.ReactNode;
};

const CommonFooter = ({ post, cardSize, button, aboutMedia }: CommonFooterProps) => {
  return (
    <CardFooter cardSize={cardSize}>
      <CardBadges post={post} />
      <CardButtonContainer>
        {aboutMedia}
        <div>
          <CardNumberOfViews numberOfViews={post.totalViews} />
        </div>
        <ReadingListButton postId={post.id} />
        {button}
      </CardButtonContainer>
    </CardFooter>
  );
};

const KompetanseFooterContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
  justify-content: flex-end;
`;

const MediaType = styled.div`
  display: flex;
  align-items: end;
  margin-right: auto;
`;

const MediaTypeIcon = styled(Icon)`
  opacity: 0.8; // Så ikke iconet virker mye hvitere enn teksten
  width: 1.5rem;
  height: 1.5rem;
  stroke: black;
  margin: 2px 0;
`;
