import React from 'react';
import Modal from 'react-modal';

// import Button from 'shared/button/Button';
// import { useNotification } from 'shared/notification/useNotification';
import styled from 'styled-components/macro';
// import { authenticatedPost } from 'utils/apiUtils';
import { tabletBreakpoint } from 'utils/constants';

// import { ApiUrls } from 'utils/urls';
// import { Warning, getErrorMessage } from 'utils/utils';

// import { ExitButton } from '../button/ExitButton';

// const Form = styled.form`
//   display: flex;
//   flex-direction: column;
//   padding: var(--1);
//   gap: var(--3);

//   @media (max-width: ${tabletBreakpoint}) {
//     padding: 0;
//   }
// `;

const Label = styled.label`
  display: block;
  text-align: left;
  font-size: var(--20px-rem);
  font-family: var(--din-medium);
`;

// const Input = styled.input`
//   width: 100%;
//   margin-top: var(--1-05);
//   border: 1px solid var(--morgendis);
//   padding: var(--1) var(--1) var(--05);
//   font-size: var(--16px-rem);
//   font-family: var(--din-light);

//   :active,
//   :focus {
//     outline: none;
//     border-color: var(--sort);
//   }
// `;
// const FeedbackInput = styled.textarea`
//   width: 100%;
//   height: 7rem;
//   margin-top: var(--1-05);
//   border: 1px solid var(--morgendis);
//   padding: var(--1);
//   font-size: var(--16px-rem);
//   font-family: var(--din-light);
//   resize: none;

//   :active,
//   :focus {
//     outline: none;
//     border-color: var(--sort);
//   }
// `;

const Description = styled.p`
  font-size: var(--22px-rem);
  font-family: var(--din-medium);
  margin: 0;
`;

// const ExitModalButton = styled(ExitButton)`
//   position: absolute;
//   top: var(--2);
//   right: var(--2);
// `;

const ModalStyle = styled.div`
  display: block !important;
  height: auto !important;
  width: auto !important;
  top: 50% !important;
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  transform: translate(-50%, -50%) !important;

  @media (max-width: ${tabletBreakpoint}) {
    width: 90% !important;
  }
`;

// interface FeedbackDTO {
//   feedback: string;
//   author: string;
//   url: string;
// }

type Props = {
  modalIsOpen: boolean;
  setModalIsOpen: (isOpen: boolean) => void;
};

export const FeedbackModal: React.FC<Props> = ({ modalIsOpen, setModalIsOpen }) => {
  // const [isSubmitting, setIsSubmitting] = useState(false);
  // const { notifyError, notifyWarning, clearNotification } = useNotification();
  // const [feedback, setFeedback] = useState('');
  // const [author, setAuthor] = useState('');

  function closeModal() {
    setModalIsOpen(false);
  }

  // const validateFields = () => {
  //   if (feedback.length < 1) {
  //     throw new Warning('Tilbakemeldingsfeltet kan ikke være tomt');
  //   }
  // };

  // const resetForm = () => {
  //   setFeedback('');
  //   setAuthor('');
  // };

  // const onSubmitFeedback = async (event: FormEvent) => {
  //   event.preventDefault();
  //   setIsSubmitting(true);
  //   clearNotification();

  //   try {
  //     validateFields();
  //     await authenticatedPost<{}, FeedbackDTO>(ApiUrls.POST_FEEDBACK, {
  //       feedback,
  //       author,
  //       url: window.location.href,
  //     });
  //     resetForm();
  //     closeModal();
  //   } catch (error) {
  //     if (error instanceof Warning) {
  //       notifyWarning('Feil ved validering', error.message);
  //     } else {
  //       notifyError('OBS!', getErrorMessage(error));
  //     }
  //   }

  //   setIsSubmitting(false);
  // };

  // const onChangeFeedback = (event: ChangeEvent<HTMLTextAreaElement>) => setFeedback(event.target.value);
  // const onChangeAuthor = (event: ChangeEvent<HTMLInputElement>) => setAuthor(event.target.value);

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentElement={(props, children) => <ModalStyle {...props}>{children}</ModalStyle>}
    >
      <Label>Tilbakemelding</Label>
      <Description>Bruk slack kanalen #atlas-tilbakemeldinger for å gi tilbakemelding på Atlas.</Description>

      {/*<Form onSubmit={onSubmitFeedback}>
        <ExitModalButton onClick={closeModal} />
        <Label>
          Tilbakemelding <FeedbackInput value={feedback} onChange={onChangeFeedback} />
        </Label>
        <Label>
          Hilsen
          <Input placeholder="(Frivillig)" type="text" contentEditable value={author} onChange={onChangeAuthor} />
        </Label>
        <Description>
          Tilbakemeldinger blir lagt ut i slack kanalen #atlas-tilbakemeldinger.
          <br />
          Hvis du ikke har med en hilsen er tilbakemeldingen anonym 🕵️
        </Description>
        <Button variant="primary" type="submit" disabled={isSubmitting}>
          Send
        </Button>
      </Form>*/}
    </Modal>
  );
};
